import { Col, Row, Space } from "antd";
import { AdStat, EntityTypes, getOtimesForThrottleUnit, useAdStats } from "@app/features/adStats";
import { LiveStatsMetrics } from "../LiveStatsMetrics";
import { PerformanceStatsGraph } from "../PerformanceStatsGraph";
import { FC, useState } from "react";
import { PerformanceStatsTypeButtons } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/PerformanceStatsTypeButtons/PerformanceStatsTypeButtons";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { selectInventoryHealthCurrencyConversionMode } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import { useThrottle } from "@app/core/utils";

interface Props {
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const LiveStatsTabContent: FC<Props> = ({ handlePerformanceTypeSwitch }) => {
    const { adStat } = useAdStats(EntityTypes.Seat);
    const [isExtended, setIsExtended] = useState(false);
    const handleExtend = () => {
        setIsExtended(!isExtended);
    };
    const oTime = getOtimesForThrottleUnit(adStat);
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();
    const currencyConversionMode = useAppSelector(selectInventoryHealthCurrencyConversionMode);
    const throttledAdStat = useThrottle(adStat, {
        forceUpdateDependencies: [oTime, preferredCurrency, currencyConversionMode],
        intervalSeconds: 1,
    });

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <LiveStatsMetrics handleExtend={handleExtend} isExtended={isExtended} />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                    </Row>
                    <PerformanceStatsGraph
                        chartId="live-stats-performance-stats-inventory-health"
                        adStat={throttledAdStat as AdStat}
                        preferredCurrency={preferredCurrency}
                        currencyConversionMode={currencyConversionMode}
                        currencyConversions={currencyConversions}
                    />
                </Col>
            </Row>
        </Space>
    );
};
