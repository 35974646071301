import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Space } from "antd";
import { AdStat, EntityTypes, getOtimesForThrottleUnit, useInventoryHealthStats } from "@app/features/adStats";
import { LiveStatsMetrics } from "../LiveStatsMetrics";
import { PerformanceStatsGraph } from "../PerformanceStatsGraph";
import { PerformanceStatsTypeButtons } from "../../PerformanceStatsTypeButtons";
import { useThrottle } from "@app/core/utils";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { selectInventoryHealthCurrencyConversionMode } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";

interface Props {
    adStatsEntity: EntityTypes;
    id: number | string;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const LiveStatsTabContent: FC<Props> = ({ adStatsEntity, id, handlePerformanceTypeSwitch }) => {
    const { setEntityIds, adStatsById } = useInventoryHealthStats(adStatsEntity);
    const adStat = useMemo(() => adStatsById[id], [adStatsById, id]);
    const [isExtended, setIsExtended] = useState(false);
    const oTime = getOtimesForThrottleUnit(adStat);
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();
    const currencyConversionMode = useAppSelector(selectInventoryHealthCurrencyConversionMode);
    const throttledAdStat = useThrottle(adStat, {
        forceUpdateDependencies: [oTime, preferredCurrency, currencyConversionMode],
        intervalSeconds: 1,
    });

    useEffect(() => setEntityIds([Number(id)]), [id, setEntityIds]);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <LiveStatsMetrics handleExtend={handleExtend} isExtended={isExtended} adStat={adStat} />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                    </Row>
                    <PerformanceStatsGraph
                        adStat={throttledAdStat as AdStat}
                        preferredCurrency={preferredCurrency}
                        currencyConversionMode={currencyConversionMode}
                        currencyConversions={currencyConversions}
                    />
                </Col>
            </Row>
        </Space>
    );
};
