import { FC } from "react";
import { Form } from "antd";
import { ThirdpartyPixels } from "@app/features/inventory/components/Fields";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
export const CustomPixels: FC = () => {
    return (
        <Form.Item
            wrapperCol={COL_SIZES}
            label={CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.label}
            name={CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.name}
        >
            <ThirdpartyPixels placeholder={CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.placeholder} />
        </Form.Item>
    );
};
