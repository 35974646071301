import { LoadingState } from "@app/core/components/LoadableComponent";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import {
    AdSourceStat,
    useConnectLiveAdSourceAdStat,
    useConnectLiveAdSourceAdStatPerSecond,
} from "@app/features/seatAdSources/data";

interface UseSeatAdSourcesPerformanceLiveStats {
    loadingState: LoadingState;
    adSourceStat: AdSourceStat | undefined;
    totalAdSourceStat: AdSourceStat | undefined;
    currencyCode: string;
}

export const useSeatAdSourcesPerformanceLiveStats = (adSourceId: number): UseSeatAdSourcesPerformanceLiveStats => {
    const { loadingState: liveStatLoadingState, data: adSourceStat } =
        useConnectLiveAdSourceAdStatPerSecond(adSourceId);
    const { data: throttledTotalAdSourceStat } = useConnectLiveAdSourceAdStat(adSourceId, true);
    const currencyCode = useCurrencyConversion().currencyConfig?.code ?? "USD";
    const loadingState = liveStatLoadingState;

    return {
        loadingState,
        adSourceStat,
        totalAdSourceStat: throttledTotalAdSourceStat,
        currencyCode,
    };
};
