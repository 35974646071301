import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { RevenueTypes } from "@app/core/clients/console";
import { Metrics } from "@app/core/components/charts/DualAxesChart";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import {
    FILLS_LABEL,
    IMPRESSIONS_LABEL,
    NET_REVENUE_LABEL,
    PERFORMANCE_AD_POD_REQUESTS_LABEL,
    PERFORMANCE_BLOCKED_LABEL,
    PERFORMANCE_ERRORS_LABEL,
    PERFORMANCE_FALL_BACKS_LABEL,
    PERFORMANCE_FALL_THROUGHS_LABEL,
    PERFORMANCE_LSA_FILLS_LABEL,
    PERFORMANCE_LSA_IMPS_LABEL,
    PERFORMANCE_LSA_POD_REQUESTS_LABEL,
    PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL,
    PERFORMANCE_LSA_SLOT_REQUESTS_LABEL,
    PERFORMANCE_MOKAS_LABEL,
    PERFORMANCE_PLAYLIST_REQUESTS_LABEL,
    PERFORMANCE_POD_SLOT_REQUESTS_LABEL,
    PERFORMANCE_REQUESTS_LABEL,
    PERFORMANCE_SEAT_REJECTIONS_LABEL,
    PERFORMANCE_USER_REJECTIONS_LABEL,
} from "@app/core/components/charts/constants";
import { useAppSelector } from "@app/core/store";
import {
    AdStat,
    getTotalRevenue,
    getFills,
    getImpressions,
    getRequests,
    getPodRequests,
    getPlaylistRequests,
    getPodSlotsRequests,
    getFallThroughs,
    getErrors,
    getMokas,
    getFallbacks,
    getSeatRejections,
    getUserRejections,
    getBlackListed,
    getLsaPotentialFills,
    getLsaPodRequests,
    getLsaFills,
    getLsaSlotRequests,
    getLsaImps,
} from "@app/features/adStats";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { selectInventoryHealthCurrencyConversionMode } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import { FC, useMemo } from "react";
import moment from "moment-timezone";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface Props {
    adStat: AdStat[] | null;
    chartId?: string;
}

const HISTORY_AD_STAT_SCALE = 300;
const scale = (n: number) => n / HISTORY_AD_STAT_SCALE || 0;

export const Last24HoursPerformanceStatsGraph: FC<Props> = ({
    adStat,
    chartId = "last-24-hours-performance-stats",
}) => {
    const { isTremorUser } = useUserAccess();
    const { context } = useSeatAuthContext();
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();
    const currencyConversionMode = useAppSelector(selectInventoryHealthCurrencyConversionMode);
    const revenueType = RevenueTypes.NET_REVENUE;
    const timeZoneCode = useAppSelector(selectUserTimezone);

    const { metricOne, metricTwo } = useMemo(() => {
        const metrics: Metrics = { metricOne: [], metricTwo: [] };
        if (adStat && adStat.length > 0 && preferredCurrency && timeZoneCode) {
            const latestNTime = adStat.slice(-1)[0].ntime;
            const firstMomentWithin24Hours = moment(latestNTime).subtract(23, "hours").subtract(59, "minutes");
            adStat
                .filter((stat) => moment(stat.ntime).isAfter(firstMomentWithin24Hours))
                .forEach((stat) => {
                    const time = moment(stat.ntime).tz(timeZoneCode).format("HH:mm");
                    const fills = getFills(stat, preferredCurrency, currencyConversionMode);
                    const impressions = getImpressions(stat, preferredCurrency, currencyConversionMode);
                    const netRev = getTotalRevenue(
                        stat,
                        preferredCurrency,
                        currencyConversions,
                        currencyConversionMode,
                        revenueType
                    );
                    const requests = getRequests(stat);
                    const adPodRequests = getPodRequests(stat);
                    const playlistRequests = getPlaylistRequests(stat);
                    const podSlotRequests = getPodSlotsRequests(stat);
                    const fallThroughs = getFallThroughs(stat);
                    const errors = getErrors(stat, isTremorUser);
                    const mokas = getMokas(stat);
                    const fallbacks = getFallbacks(stat);
                    const seatRejections = getSeatRejections(stat, isTremorUser);
                    const userRejections = getUserRejections(stat);
                    const blackListed = getBlackListed(stat);

                    if (context?.lsaEnabled) {
                        const lsaPotentialFills = getLsaPotentialFills(stat);
                        const lsaPodRequests = getLsaPodRequests(stat);
                        const lsaFills = getLsaFills(stat, preferredCurrency, currencyConversionMode);
                        const lsaSlotRequests = getLsaSlotRequests(stat);
                        const lsaImps = getLsaImps(stat, preferredCurrency, currencyConversionMode);
                        metrics.metricOne.push({
                            time,
                            value1: scale(lsaPotentialFills),
                            name1: PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL,
                        });
                        metrics.metricOne.push({
                            time,
                            value1: scale(lsaFills),
                            name1: PERFORMANCE_LSA_FILLS_LABEL,
                        });
                        metrics.metricOne.push({
                            time,
                            value1: scale(lsaImps),
                            name1: PERFORMANCE_LSA_IMPS_LABEL,
                        });
                        metrics.metricOne.push({
                            time,
                            value1: scale(lsaPodRequests),
                            name1: PERFORMANCE_LSA_POD_REQUESTS_LABEL,
                        });
                        metrics.metricOne.push({
                            time,
                            value1: scale(lsaSlotRequests),
                            name1: PERFORMANCE_LSA_SLOT_REQUESTS_LABEL,
                        });
                    }

                    metrics.metricOne.push({ time, value1: scale(fills), name1: FILLS_LABEL });
                    metrics.metricOne.push({ time, value1: scale(impressions), name1: IMPRESSIONS_LABEL });
                    metrics.metricTwo.push({ time, value2: scale(netRev), name2: NET_REVENUE_LABEL });
                    metrics.metricOne.push({ time, value1: scale(requests), name1: PERFORMANCE_REQUESTS_LABEL });
                    metrics.metricOne.push({
                        time,
                        value1: scale(adPodRequests),
                        name1: PERFORMANCE_AD_POD_REQUESTS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(playlistRequests),
                        name1: PERFORMANCE_PLAYLIST_REQUESTS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(podSlotRequests),
                        name1: PERFORMANCE_POD_SLOT_REQUESTS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(fallThroughs),
                        name1: PERFORMANCE_FALL_THROUGHS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(errors),
                        name1: PERFORMANCE_ERRORS_LABEL,
                    });
                    if (isTremorUser) {
                        metrics.metricOne.push({
                            time,
                            value1: scale(mokas),
                            name1: PERFORMANCE_MOKAS_LABEL,
                        });
                    }

                    metrics.metricOne.push({
                        time,
                        value1: scale(fallbacks),
                        name1: PERFORMANCE_FALL_BACKS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(seatRejections),
                        name1: PERFORMANCE_SEAT_REJECTIONS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(userRejections),
                        name1: PERFORMANCE_USER_REJECTIONS_LABEL,
                    });
                    metrics.metricOne.push({
                        time,
                        value1: scale(blackListed),
                        name1: PERFORMANCE_BLOCKED_LABEL,
                    });
                });
        }
        return metrics;
    }, [
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType,
        timeZoneCode,
        isTremorUser,
        context,
    ]);

    if (!preferredCurrency) {
        return null;
    }

    return (
        <TogglableChart
            metricOne={metricOne}
            metricTwo={metricTwo}
            chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                <MixCountAreaCurrencyLineChart
                    chartId={chartId}
                    metricOne={filteredMetricOne}
                    metricTwo={filteredMetricTwo}
                    metricOneYAxisTitle="Events / Sec"
                    metricTwoYAxisTitle="Rev / Sec"
                    disablePoints={true}
                />
            )}
        />
    );
};
