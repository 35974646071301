import { FC } from "react";
import { Card, Typography, Col, Row } from "antd";
import { useDefaultsSection } from "./useDefaultsSection";
import {
    AdditionalTime,
    Categories,
    COPPA,
    Interstitial,
    Linearity,
    LiveStream,
    MaxBitrate,
    MaxExtended,
    MIMEs,
    MinBitrate,
    Multiplicity,
    Placement,
    PrivacyPolicy,
    Skippable,
    SkippableAfter,
    SkippableMinimum,
    SSAIType,
    StartDelay,
    SupportedAPIs,
    SupportedProtocols,
    Type,
} from "./Fields";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { TWO_COL_SIZES } from "@app/features/inventory/constants";
import { AdUnitMaxDuration, AdUnitMinDuration } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const GUTTER = 32;
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const DefaultsSection: FC = () => {
    const { isTypeShown, isStartDelayShown, isAdditionalTimeShown, isSkippableMinimumAfterShown, isInternalOnly } =
        useDefaultsSection();
    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <Col {...TWO_COL_SIZES}>
                    <Typography.Paragraph style={{ marginBottom: "1.5rem" }}>
                        The following default values will be used unless they are passed in the ad request
                    </Typography.Paragraph>
                    <MIMEs />
                    <SupportedAPIs />
                    <Linearity />
                    {isTypeShown && (
                        <Row gutter={GUTTER}>
                            <Col {...INPUTS_COL_SIZES}>
                                <Type />
                            </Col>
                            {isStartDelayShown && (
                                <Col {...INPUTS_COL_SIZES}>
                                    <StartDelay />
                                </Col>
                            )}
                        </Row>
                    )}
                    <Interstitial />
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <Placement />
                        </Col>
                    </Row>
                    <COPPA />
                    <PrivacyPolicy />
                    <Categories />
                    <SupportedProtocols />
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <AdUnitMinDuration
                                name={CHANNEL_FORM_FIELDS.MIN_DURATION.name}
                                oppositeName={CHANNEL_FORM_FIELDS.MAX_DURATION.name}
                            />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <AdUnitMaxDuration
                                name={CHANNEL_FORM_FIELDS.MAX_DURATION.name}
                                oppositeName={CHANNEL_FORM_FIELDS.MIN_DURATION.name}
                            />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxExtended />
                        </Col>
                        {isAdditionalTimeShown && (
                            <Col {...INPUTS_COL_SIZES}>
                                <AdditionalTime />
                            </Col>
                        )}
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MinBitrate />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxBitrate />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <SSAIType />
                        </Col>
                    </Row>
                    <Skippable />
                    {isSkippableMinimumAfterShown && (
                        <Row gutter={GUTTER}>
                            <Col {...INPUTS_COL_SIZES}>
                                <SkippableAfter />
                            </Col>
                            <Col {...INPUTS_COL_SIZES}>
                                <SkippableMinimum />
                            </Col>
                        </Row>
                    )}
                    <Multiplicity />
                </Col>
                {isInternalOnly && (
                    <Col {...TWO_COL_SIZES}>
                        <InternalOnly>
                            <LiveStream />
                        </InternalOnly>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
