import {
    ThirdPartyPixel,
    useGetAdUnitInheritedPixelsQuery,
    useGetBrandInheritedPixelsQuery,
    useGetPublisherInheritedPixelsQuery,
    useGetSupplyInheritedPixelsQuery,
} from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    selectInventoryDetailsDrawerType,
    InventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";
import { useMemo } from "react";

interface UsePixels {
    inheritedPixels: ThirdPartyPixel[];
}

export const usePixels = (id: number): UsePixels => {
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);

    const isPublisherChannelDrawerType: boolean =
        detailsDrawerType === InventoryDetailsDrawerType.PUBLISHER ||
        detailsDrawerType === InventoryDetailsDrawerType.CHANNEL;

    const isBrandChannelDrawerType: boolean =
        detailsDrawerType === InventoryDetailsDrawerType.BRAND ||
        detailsDrawerType === InventoryDetailsDrawerType.BRAND_CHANNEL;

    const { data: publisherPixels } = useGetPublisherInheritedPixelsQuery(
        {
            publisherId: id,
            includeMine: false,
        },
        { skip: !isPublisherChannelDrawerType }
    );
    const { data: brandPixels } = useGetBrandInheritedPixelsQuery(
        {
            brandId: id,
            includeMine: false,
        },
        { skip: !isBrandChannelDrawerType }
    );
    const { data: supplyPixels } = useGetSupplyInheritedPixelsQuery(
        {
            supplyId: id,
            includeMine: false,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.SUPPLY }
    );
    const { data: adUnitPixels } = useGetAdUnitInheritedPixelsQuery(
        {
            adUnitId: id,
            includeMine: false,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.AD_UNIT }
    );

    const inheritedPixels = useMemo(
        () => publisherPixels || brandPixels || supplyPixels || adUnitPixels || [],
        [publisherPixels, brandPixels, supplyPixels, adUnitPixels]
    );

    return {
        inheritedPixels,
    };
};
