import { Card } from "antd";
import React, { FC } from "react";
import { CustomPixels } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/CustomPixelsSection/Fields";
import { SupplyInheritedCustomPixels } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/CustomPixelsSection/Fields/CustomPixels/SupplyInheritedCustomPixels";

export const CustomPixelsSection: FC = () => (
    <Card bordered={false}>
        <CustomPixels />
        <SupplyInheritedCustomPixels />
    </Card>
);
