import { useSeatAuthContext } from "@app/core/auth";
import { selectGetCurrenciesConversionsResult, selectGetCurrenciesResult } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { CURRENCY_DEFS } from "@app/core/utils/currencies";

export const useCurrencyConversion = () => {
    const { session } = useSeatAuthContext();
    const currencies = useAppSelector(selectGetCurrenciesResult);
    const currencyConversions = useAppSelector(selectGetCurrenciesConversionsResult);
    const preferredCurrencyIdOrCode = session?.user.userPreferenceMap.preferredCurrency;
    const preferredCurrency = currencies.data?.find(
        (currency) => currency.id === preferredCurrencyIdOrCode || currency.code === preferredCurrencyIdOrCode
    );

    const currencyConfig = CURRENCY_DEFS[preferredCurrency?.code || "USD"];

    return {
        preferredCurrency,
        currencyConversions: currencyConversions?.data || [],
        currencyConfig,
    };
};
