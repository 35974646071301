import { FC, Key, ReactNode, useEffect, useState } from "react";
import { usePublishers } from "./usePublishers";
import { Badge, Tree } from "antd";
import { css } from "@emotion/css";
import {
    Brand,
    useLazyGetBrandsSupplyQuery,
    useLazyGetPublisherBrandsQuery,
    useLazyGetSupplyAdUnitsQuery,
} from "@app/core/services";
import { useHistory } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useSeatAuthContext } from "@app/core/auth";
import { useSeatTree } from "./useSeatTree";
import { INVENTORY_LIST_PAGES } from "../../constants";
import { gold6 } from "@rubicon/antd-components";
import { useSeatTreeHeight } from "../../useSeatTreeHeight";
import { GlobalIcon } from "@app/core/components";

const treeStyles = css`
    .ant-tree-indent-unit::before,
    .ant-tree-switcher-leaf-line {
        display: none;
    }

    .ant-tree-switcher {
        width: auto;
    }

    span.ant-tree-iconEle.ant-tree-icon__customize {
        display: inline-flex;
        align-items: center;
    }
`;

const updateTreeData = (list: DataNode[], key: Key, children: DataNode[]): DataNode[] =>
    list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });

interface BrandSafety
    extends Pick<
        Brand,
        | "advertiserBlockDomains"
        | "seatAdvertiserDomainFilterListDefs"
        | "buyerSeatList"
        | "creativeBlockingMode"
        | "blockedIabCategories"
    > {}

//INFO Advertiser Frequency Capping doesn't belong to Brand Safety there fore we omit it.
const brandSafetyBadge = ({
    advertiserBlockDomains,
    seatAdvertiserDomainFilterListDefs,
    buyerSeatList,
    creativeBlockingMode,
    blockedIabCategories,
}: BrandSafety) => {
    if (
        Boolean(
            advertiserBlockDomains?.length ||
                seatAdvertiserDomainFilterListDefs?.length ||
                buyerSeatList != null ||
                creativeBlockingMode != null ||
                blockedIabCategories?.length
        )
    ) {
        return <Badge color={gold6} />;
    }
    return null;
};

interface Props extends BrandSafety {
    name: string;
}

const Title: FC<Props> = ({ name, ...brandSafety }) => (
    <>
        {name} {brandSafetyBadge({ ...brandSafety })}
    </>
);

interface DataNode {
    title: string | ReactNode;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    entityType: string;
    entityId: number;
}

interface SeatTreeProps {
    onTreeSelect?: () => void;
}

export const SeatTree: FC<SeatTreeProps> = ({ onTreeSelect }) => {
    const { context } = useSeatAuthContext();
    const { expandedKeys, selectedKeys, expandKeys, foldKey } = useSeatTree();
    const { data } = usePublishers();
    const [getBrands] = useLazyGetPublisherBrandsQuery();
    const [getSupply] = useLazyGetBrandsSupplyQuery();
    const [getAdUnits] = useLazyGetSupplyAdUnitsQuery();
    const history = useHistory();
    const treeHeight = useSeatTreeHeight();
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    useEffect(() => {
        if (data && context) {
            setTreeData([
                {
                    title: context.name,
                    key: `seat-${context.id.toString()}`,
                    entityType: "Seat",
                    entityId: context.id,
                    children: data.map((publisher) => ({
                        title: (
                            <Title
                                name={publisher.name}
                                advertiserBlockDomains={publisher.advertiserBlockDomains}
                                seatAdvertiserDomainFilterListDefs={publisher.seatAdvertiserDomainFilterListDefs}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                buyerSeatList={publisher.buyerSeatList}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                creativeBlockingMode={publisher?.creativeBlockingMode}
                            ></Title>
                        ),
                        isLeaf: publisher.entityType === "Channel",
                        key: `publisher-${publisher.id.toString()}`,
                        entityType: publisher.entityType,
                        parentEntityType: publisher.seat.entityType,
                        entityId: publisher.id,
                        icon: publisher.entityType === "Channel" ? <GlobalIcon /> : null,
                    })),
                },
            ]);
        }
    }, [data, context]);

    const onLoadData = async ({ entityId, entityType }: { entityId: number; entityType: string }) => {
        if (entityType === "Publisher") {
            return getBrands({ publisherId: entityId })
                .unwrap()
                .then((brands) => {
                    setTreeData((origin) =>
                        updateTreeData(
                            origin,
                            `publisher-${entityId}`,
                            brands.map((brand) => ({
                                title: (
                                    <Title
                                        name={brand.name}
                                        advertiserBlockDomains={brand.advertiserBlockDomains}
                                        seatAdvertiserDomainFilterListDefs={brand.seatAdvertiserDomainFilterListDefs}
                                        buyerSeatList={brand.buyerSeatList}
                                        blockedIabCategories={brand.blockedIabCategories}
                                    ></Title>
                                ),
                                isLeaf: brand.entityType === "Channel",
                                key: `brand-${brand.id.toString()}`,
                                entityType: brand.entityType,
                                parentEntityType: brand.publisher.entityType,
                                entityId: brand.id,
                                icon: brand.entityType === "Channel" ? <GlobalIcon /> : null,
                            }))
                        )
                    );
                });
        } else if (entityType === "Brand") {
            return getSupply({ brandId: entityId })
                .unwrap()
                .then((supplyList) => {
                    setTreeData((origin) =>
                        updateTreeData(
                            origin,
                            `brand-${entityId}`,
                            supplyList.map((supply) => ({
                                title: (
                                    <Title
                                        name={supply.name}
                                        advertiserBlockDomains={supply.advertiserBlockDomains}
                                        seatAdvertiserDomainFilterListDefs={supply.seatAdvertiserDomainFilterListDefs}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        //@ts-ignore
                                        buyerSeatList={supply.buyerSeatList}
                                    ></Title>
                                ),
                                key: `supply-${supply.id.toString()}`,
                                entityType: "Supply",
                                entityId: supply.id,
                            }))
                        )
                    );
                });
        } else if (entityType === "Supply") {
            return getAdUnits(entityId)
                .unwrap()
                .then((adUnits) => {
                    setTreeData((origin) =>
                        updateTreeData(
                            origin,
                            `supply-${entityId}`,
                            adUnits.map((adUnit) => ({
                                title: (
                                    <Title
                                        name={adUnit.name}
                                        advertiserBlockDomains={adUnit.advertiserBlockDomains}
                                        seatAdvertiserDomainFilterListDefs={adUnit.seatAdvertiserDomainFilterListDefs}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        //@ts-ignore
                                        buyerSeatList={adUnit.buyerSeatList}
                                    ></Title>
                                ),
                                key: `adUnit-${adUnit.id.toString()}`,
                                entityType: "AdUnit",
                                entityId: adUnit.id,
                                isLeaf: true,
                            }))
                        )
                    );
                });
        } else {
            return Promise.resolve();
        }
    };

    const onSelect = (selectedKeys, e) => {
        onTreeSelect?.();
        if (!e.node || !context) {
            return;
        }
        if (e.node.entityType === "Seat") {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY(e.node.entityId, INVENTORY_LIST_PAGES.BRAND_SAFETY));
        }
        if (
            e.node.parentEntityType === "Seat" &&
            (e.node.entityType === "Publisher" || e.node.entityType === "Channel")
        ) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(context.id, e.node.entityId)
            );
        }
        if (
            e.node.parentEntityType === "Publisher" &&
            (e.node.entityType === "Brand" || e.node.entityType === "Channel")
        ) {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND(context.id, e.node.entityId));
        }
        if (e.node.entityType === "Supply") {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY(context.id, e.node.entityId));
        }
        if (e.node.entityType === "AdUnit") {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT(context.id, e.node.entityId)
            );
        }
    };

    const handleExpand = (expandedKeys, { expanded, node }) => {
        if (!expanded) {
            foldKey(node.key);
        } else {
            expandKeys([node.key]);
        }
    };

    return (
        <Tree
            height={treeHeight}
            className={treeStyles}
            showLine
            showIcon
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            onExpand={handleExpand}
            loadData={onLoadData}
            onSelect={onSelect}
            treeData={treeData}
        />
    );
};
