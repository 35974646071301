import { useMemo } from "react";
import { SeatAdSourcesType, useGetDealsPicklistQuery, useLazyGetDealByIdQuery } from "@app/core/services";
import {
    AdSourceTypes,
    AD_SOURCE_FIELDS,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
    DealTypeIds,
} from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useIsPbsDealType } from "@app/features/seatAdSources/SeatAdSourcesForm/useIsPbsDealType";
import { Form, notification } from "antd";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import moment from "moment-timezone";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useSyncedFieldsConsumer } from "@app/features/syncedFields";

const getDealTypeId = (
    isAuctionPrice: boolean,
    isPG: boolean,
    isFixedPrice: boolean,
    isPbsDealType: boolean
): number | null => {
    if (isAuctionPrice) return DealTypeIds.AUCTION_PRICE;
    if (isPG) return DealTypeIds.PROGRAMMATIC_GUARANTEED;
    if (isFixedPrice) return DealTypeIds.FIXED_PRICE;
    if (isPbsDealType) return DealTypeIds.LEGACY;
    return null;
};
export const useDealSelect = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const isPbsDealType = useIsPbsDealType();
    const { forms } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name, forms.adSourceForm);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const { setCreateMode, setIsDealFetching } = useMarketplaceInfoWidget();
    const { consumeAllSyncedFields } = useSyncedFieldsConsumer();

    const [getDealById] = useLazyGetDealByIdQuery();
    const isAuctionPrice =
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE;
    const isPG =
        adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED;
    const isFixedPrice =
        adSourceTypeName === AdSourceTypes.FIXED_PRICE || adSourceTypeName === AdSourceTypes.LINEAR_FIXED_PRICE;
    const dealType = getDealTypeId(isAuctionPrice, isPG, isFixedPrice, isPbsDealType);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string): void => {
        setSearch(value);
        debouncedSetKeyword(value);
    };

    const { data, isFetching: isLoading } = useGetDealsPicklistQuery(
        {
            seatId: Number(seatId),
            keyword,
            filterDealType: [String(dealType), String(DealTypeIds.LEGACY)],
        },
        { skip: !dealType }
    );

    const dealIdSet = new Set<number>();
    const deals = data
        ?.filter((deal) => {
            if (dealIdSet.has(deal.id)) {
                return false;
            }
            dealIdSet.add(deal.id);
            return true;
        })
        .map((deal) => ({
            ...deal,
            startTime: deal.startTime ? moment(deal.startTime) : null,
            endTime: deal.endTime ? moment(deal.endTime) : null,
        }));

    const onSelect = async ({ value }: { label: string; value: number }) => {
        setIsDealFetching(true);
        try {
            consumeAllSyncedFields();
            const dealOrigin = await getDealById(value).unwrap();
            forms.dealObjectForm.setFieldValue("dealOrigin", dealOrigin);
            setCreateMode("existingDeal");
        } catch (_: unknown) {
            notification.error({ message: "Deal fetching error" });
        }
        setIsDealFetching(false);
    };

    const demandDeals = Form.useWatch<AdSourcesForm>(AD_SOURCE_FIELDS.DEMAND.name, forms.adSourceForm);
    const demandDealIDs = Array.isArray(demandDeals) ? demandDeals.map((item) => item.deal?.id) : [];

    return {
        deals,
        isLoading,
        onSearch,
        onSelect,
        search,
        demandDealIDs,
    };
};
