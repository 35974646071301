import { FC } from "react";
import { Space, Divider } from "antd";
import { StyledButtonLink } from "@app/core/components";
import { BrandSafetyHierarchicalHelp } from "../BrandSafetyHierarchicalHelp";
import { BRAND_SAFETY_GUTTER } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

interface Props {
    expandAll: () => void;
    collapseAll: () => void;
}

export const BrandSafetyHeaderControls: FC<Props> = ({ expandAll, collapseAll }) => {
    return (
        <Space
            wrap
            align="center"
            size={BRAND_SAFETY_GUTTER}
            split={<Divider style={{ marginInline: "0px" }} type="vertical" />}
        >
            <StyledButtonLink onClick={expandAll} type="link">
                Expand All
            </StyledButtonLink>
            <StyledButtonLink onClick={collapseAll} type="link">
                Collapse All
            </StyledButtonLink>
            <BrandSafetyHierarchicalHelp />
        </Space>
    );
};
