import React from "react";
import { StyledReactLink } from "@app/core/components";
import { Breadcrumb, BreadcrumbProps, Flex, Typography } from "antd";

interface Props {
    items?: BreadcrumbProps["items"];
    sufix?: string;
    sufixLevel?: 5 | 1 | 2 | 3 | 4 | undefined;
}

export const InventoryFloorsBreadcrumb = ({ items, sufix, sufixLevel = 5 }: Props) => {
    if (!items?.length && !sufix) return null;
    return (
        <Flex wrap align="center">
            {sufix && (
                <Typography.Title style={{ marginBottom: "0px", marginRight: "8px" }} level={sufixLevel}>
                    {sufix}
                </Typography.Title>
            )}
            {!!items?.length && (
                <Breadcrumb
                    items={items}
                    itemRender={(currentRoute) =>
                        currentRoute.path ? (
                            <StyledReactLink to={currentRoute.path}>{currentRoute.title}</StyledReactLink>
                        ) : (
                            <Typography.Title style={{ marginBottom: "0px" }} level={5}>
                                {currentRoute.title}
                            </Typography.Title>
                        )
                    }
                />
            )}
        </Flex>
    );
};
