import { FC, useMemo, useState } from "react";
import { Card, Button, Skeleton } from "antd";
import {
    EntityTypes,
    getPodsAndPlaylistAdBreaks,
    getEstimatedECPM,
    getFillRate,
    getFills,
    getImpressions,
    getImpressionsUseRate,
    getPodsAndPlaylistEstimatedECPM,
    getPodsAndPlaylistFillRate,
    getPodsAndPlaylistFills,
    getPodsAndPlaylistImpressions,
    getPodsAndPlaylistImpressionsUseRate,
    getPodsAndPlaylistRevenue,
    getRequests,
    getPodsAndPlaylistSlots,
    getStandardEstimatedECPM,
    getStandardFillRate,
    getStandardFills,
    getStandardImpressions,
    getStandardImpressionsUseRate,
    getStandardRevenue,
    getTotalRevenue,
    getStandardAcceptedRequests,
    getStandardRequestsAcceptRate,
    getAllOpportunities,
    getLiveOpportunitiesAcceptRate,
    getRequestsAcceptRate,
    getOtimesForThrottleUnit,
} from "@app/features/adStats";
import { Metrics } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { useAnimatedNumber } from "../useAnimatedNumber";
import { useCurrencyConversion } from "../useCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { formatNumber } from "@rubicon/utils";
import { getLiveStatsDateLabel } from "../helpers";
import { useThrottle } from "@app/core/utils";
import { useAuthClient } from "@app/core/authClient";
import { RevenueTypes } from "@app/core/clients/console";
import { useSeatAuthContext } from "@app/core/auth";
import { selectDashboardCurrencyConversionMode, selectDashboardRevenueType } from "../reducer";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";
import { useDashboardAdStats } from "../useDashboardAdStats";

export const LiveStatsMetrics: FC = () => {
    const { session } = useAuthClient();
    const { context } = useSeatAuthContext();
    const [showBreakdown, setShowBreakdown] = useState(false);
    const { adStat, isLoading } = useDashboardAdStats(EntityTypes.Seat);
    const hasLoadedAdStat = !isLoading;
    const currencyConversionMode = useAppSelector(selectDashboardCurrencyConversionMode);
    const revenueType = useAppSelector(selectDashboardRevenueType);
    const { preferredCurrency, currencyConversions, currencyConfig } = useCurrencyConversion();
    const timeZone = session?.user?.userPreferenceMap.timezone || DEFAULT_TIME_ZONE.code;
    const oTime = getOtimesForThrottleUnit(adStat);

    const throttledAdStat = useThrottle(adStat, {
        forceUpdateDependencies: [oTime, revenueType, currencyConversionMode, preferredCurrency],
    });

    const totalGrossRev = useAnimatedNumber({
        value: getTotalRevenue(
            throttledAdStat,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            revenueType
        ),
        isPlaying: hasLoadedAdStat,
    });
    const opportunities = useAnimatedNumber({
        value: getAllOpportunities(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const sspRequests = useAnimatedNumber({
        value: getRequests(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const standardAcceptedRequests = useAnimatedNumber({
        value: getStandardAcceptedRequests(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const fills = useAnimatedNumber({
        value: getFills(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const impressions = useAnimatedNumber({
        value: getImpressions(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const podsAndPlaylistRevenue = useAnimatedNumber({
        value: getPodsAndPlaylistRevenue(
            throttledAdStat,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            revenueType
        ),
        isPlaying: hasLoadedAdStat,
    });
    const podsAndPlaylistFills = useAnimatedNumber({
        value: getPodsAndPlaylistFills(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const podsAndPlaylistImpressions = useAnimatedNumber({
        value: getPodsAndPlaylistImpressions(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const standardRevenue = useAnimatedNumber({
        value: getStandardRevenue(
            throttledAdStat,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            revenueType
        ),
        isPlaying: hasLoadedAdStat,
    });
    const standardFills = useAnimatedNumber({
        value: getStandardFills(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });

    const standardImpressions = useAnimatedNumber({
        value: getStandardImpressions(throttledAdStat, preferredCurrency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });

    const podsAndPlaylistSlots = useAnimatedNumber({
        value: getPodsAndPlaylistSlots(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const adBreaks = useAnimatedNumber({
        value: getPodsAndPlaylistAdBreaks(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const liveStatsDateLabel = useMemo(() => getLiveStatsDateLabel(timeZone), [timeZone]);

    const secondStatsCell = context?.adPodEnabled
        ? {
              title: "Opportunities",
              value: opportunities,
              loading: !hasLoadedAdStat,
              precision: 0,
              description: (
                  <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${formatNumber.asNumber(
                      sspRequests
                  )} SSP Requests -- ${getLiveOpportunitiesAcceptRate(throttledAdStat)}% Accept Rate`}</Skeleton>
              ),
          }
        : {
              title: "Requests",
              value: sspRequests,
              loading: !hasLoadedAdStat,
              precision: 0,
              description: (
                  <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${getRequestsAcceptRate(
                      throttledAdStat
                  )}% Accept Rate`}</Skeleton>
              ),
          };

    const items = [
        {
            className: css`
                border-left: 0 !important;
            `,
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} Rev`,
            prefix: currencyConfig?.symbol || "",
            value: totalGrossRev,
            loading: !hasLoadedAdStat,
            precision: 2,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${
                    currencyConfig?.symbol || ""
                }${getEstimatedECPM(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                )} est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} eCPM`}</Skeleton>
            ),
        },
        secondStatsCell,
        {
            title: "Fills",
            value: fills,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${getFillRate(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversionMode
                )}% Fill Rate`}</Skeleton>
            ),
        },
        {
            title: "Impressions",
            value: impressions,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${getImpressionsUseRate(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversionMode
                )}% Use Rate`}</Skeleton>
            ),
        },
    ];

    const podsAndPlaylist = [
        {
            className: css`
                border-left: 0 !important;
            `,
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} Rev`,
            prefix: currencyConfig?.symbol || "",
            value: podsAndPlaylistRevenue,
            loading: !hasLoadedAdStat,
            precision: 2,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${
                    currencyConfig?.symbol || ""
                }${getPodsAndPlaylistEstimatedECPM(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                )} est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} eCPM`}</Skeleton>
            ),
        },
        {
            title: "Pod Slots",
            value: podsAndPlaylistSlots,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${formatNumber.asNumber(
                    adBreaks
                )} Ad breaks`}</Skeleton>
            ),
        },
        {
            title: "Fills",
            value: podsAndPlaylistFills,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton
                    loading={!hasLoadedAdStat}
                    paragraph={{ rows: 1 }}
                    round={true}
                >{`${getPodsAndPlaylistFillRate(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversionMode
                )}% Fill Rate`}</Skeleton>
            ),
        },
        {
            title: "Impressions",
            value: podsAndPlaylistImpressions,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton
                    loading={!hasLoadedAdStat}
                    paragraph={{ rows: 1 }}
                    round={true}
                >{`${getPodsAndPlaylistImpressionsUseRate(
                    podsAndPlaylistImpressions,
                    podsAndPlaylistFills
                )}% Use Rate`}</Skeleton>
            ),
        },
    ];

    const standard = [
        {
            className: css`
                border-left: 0 !important;
            `,
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} Rev`,
            prefix: currencyConfig?.symbol || "",
            value: standardRevenue,
            loading: !hasLoadedAdStat,
            precision: 2,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${
                    currencyConfig?.symbol || ""
                }${getStandardEstimatedECPM(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                )} est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} eCPM`}</Skeleton>
            ),
        },
        {
            title: "Accepted Requests",
            value: standardAcceptedRequests,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>
                    {`${getStandardRequestsAcceptRate(adStat)}% Accept Rate`}
                </Skeleton>
            ),
        },
        {
            title: "Fills",
            value: standardFills,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton loading={!hasLoadedAdStat} paragraph={{ rows: 1 }} round={true}>{`${getStandardFillRate(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversionMode
                )}% Fill Rate`}</Skeleton>
            ),
        },
        {
            title: "Impressions",
            value: standardImpressions,
            loading: !hasLoadedAdStat,
            precision: 0,
            description: (
                <Skeleton
                    loading={!hasLoadedAdStat}
                    paragraph={{ rows: 1 }}
                    round={true}
                >{`${getStandardImpressionsUseRate(
                    throttledAdStat,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    preferredCurrency,
                    currencyConversionMode
                )}% Use Rate`}</Skeleton>
            ),
        },
    ];

    const toggleShowBreakdown = () => {
        setShowBreakdown(!showBreakdown);
    };

    return (
        <Card
            className={css`
                border: 1px solid #d9d9d9;
                background-color: #fafafa;
            `}
            bodyStyle={{ padding: 0 }}
            bordered={true}
            title="Today's Live Stats"
            extra={liveStatsDateLabel}
        >
            <Metrics items={items} />
            {context?.adPodEnabled && (
                <Card
                    className={css`
                        border-top: 1px solid #d9d9d9;
                        display: flex;
                        justify-content: center;
                        .ant-card-body {
                            padding: 0;
                        }
                    `}
                    bordered={false}
                >
                    <Button type="link" onClick={toggleShowBreakdown}>
                        {showBreakdown ? "Hide" : "Show"} breakdown
                    </Button>
                </Card>
            )}

            {context?.adPodEnabled && showBreakdown && (
                <>
                    <Card
                        className={css`
                            border-top: 1px solid #d9d9d9;
                        `}
                        size="small"
                        type="inner"
                        title="Pods and Playlists"
                        bodyStyle={{ padding: 0 }}
                    />
                    <Metrics items={podsAndPlaylist} />
                    <Card
                        className={css`
                            border-top: 1px solid #d9d9d9;
                        `}
                        size="small"
                        type="inner"
                        title="Standard"
                        bodyStyle={{ padding: 0 }}
                    />
                    <Metrics items={standard} />
                </>
            )}
        </Card>
    );
};
