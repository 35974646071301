import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AdServerLadleResponse,
    AdUnit,
    useActivateAdServerLadleMutation,
    useActivateAdServerLadlesForAdUnitMutation,
    useCreateAdServerLadleMutation,
    useGetNetworkEndpointsAwsRegionsQuery,
    useGetSeatAvailableNetworksQuery,
    useLazyGetAdUnitQuery,
    useSearchQuery,
} from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { Form, FormInstance, notification, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../../../reducer";
import { UnitUrlIds, useInventoryDetailsDrawerUrlId } from "../../../useInventoryDetailsDrawerUrlId";
import { useNestedData } from "../../useNestedData";

const ACTIVATE_LADLE_TYPE = 1;
const SAVE_AND_ACTIVATE_LADLE_TYPE = 2;
export const CHANNEL_AD_UNIT_QUERY_PARAMETER = "ad-unit";

export const enum LADLE_FORM_FIELDS {
    Region = "awsRegionCode",
    LadleName = "ladleName",
    DealCode = "dealCode",
    SupplyDomain = "sourceDomain",
    BundleId = "bundleId",
    LineItemId = "lineItemId",
    OrderId = "orderId",
    AdvertiserDomain = "adomain",
    CreativeId = "creativeId",
    Demand = "networkCode",
    Creative = "hasCreative",
    CreativeUnfilled = "creativeUnfilled",
    AdUnitCode = "adUnitCode",
}

interface FormValuesType {
    [LADLE_FORM_FIELDS.Region]: string | undefined;
    [LADLE_FORM_FIELDS.LadleName]: string | undefined;
    [LADLE_FORM_FIELDS.DealCode]: string | undefined;
    [LADLE_FORM_FIELDS.SupplyDomain]: string | undefined;
    [LADLE_FORM_FIELDS.BundleId]: number | undefined;
    [LADLE_FORM_FIELDS.LineItemId]: number | undefined;
    [LADLE_FORM_FIELDS.OrderId]: number | undefined;
    [LADLE_FORM_FIELDS.AdvertiserDomain]: string | undefined;
    [LADLE_FORM_FIELDS.CreativeId]: number | undefined;
    [LADLE_FORM_FIELDS.Demand]: string | undefined;
    [LADLE_FORM_FIELDS.Creative]: boolean | null;
    [LADLE_FORM_FIELDS.CreativeUnfilled]: boolean;
    [LADLE_FORM_FIELDS.AdUnitCode]: string;
}

const initialValues: FormValuesType = {
    [LADLE_FORM_FIELDS.Region]: undefined,
    [LADLE_FORM_FIELDS.LadleName]: undefined,
    [LADLE_FORM_FIELDS.DealCode]: undefined,
    [LADLE_FORM_FIELDS.SupplyDomain]: undefined,
    [LADLE_FORM_FIELDS.BundleId]: undefined,
    [LADLE_FORM_FIELDS.LineItemId]: undefined,
    [LADLE_FORM_FIELDS.OrderId]: undefined,
    [LADLE_FORM_FIELDS.AdvertiserDomain]: undefined,
    [LADLE_FORM_FIELDS.CreativeId]: undefined,
    [LADLE_FORM_FIELDS.Demand]: undefined,
    [LADLE_FORM_FIELDS.Creative]: null,
    [LADLE_FORM_FIELDS.CreativeUnfilled]: false,
    [LADLE_FORM_FIELDS.AdUnitCode]: "",
};

interface UseLadleContent {
    initialValues: FormValuesType;
    form: FormInstance;
    isSubmitting: boolean;
    hasAdminAccess: boolean;
    handleCancel: () => void;
    handleSubmit: (saveAndActivate?: boolean) => void;
    breadcrumbs?: string;
    isFetchingOrders: boolean;
    isFetchingNetworks: boolean;
    isFetchingLineItems: boolean;
    handleDefinition: () => void;
    isFetchingAwsRegions: boolean;
    ordersOptions: LabeledValue[];
    networksOptions: LabeledValue[];
    lineItemsOptions: LabeledValue[];
    awsRegionsOptions: LabeledValue[];
    handleSaveAndActivate: () => void;
}

export const useLadleContent = (): UseLadleContent => {
    const [getAdUnit] = useLazyGetAdUnitQuery();
    const isUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const history = useHistory();
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);
    const channelAdUnitId = parameters.get(CHANNEL_AD_UNIT_QUERY_PARAMETER);
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UnitUrlIds>();
    const unitId = useInventoryDetailsDrawerUrlId();
    const { nestedBreadcrumbs } = useNestedData(unitId);

    const [form] = Form.useForm<FormValuesType>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { hasAdminAccess } = useUserAccess();

    const { data: awsRegions, isFetching: isFetchingAwsRegions } = useGetNetworkEndpointsAwsRegionsQuery();
    const { data: lineItems, isFetching: isFetchingLineItems } = useSearchQuery({
        type: "lineItem",
    });
    const { data: orders, isFetching: isFetchingOrders } = useSearchQuery({ type: "order" });
    const { data: networks, isFetching: isFetchingNetworks } = useGetSeatAvailableNetworksQuery(Number(seatId));

    const [createLadle] = useActivateAdServerLadlesForAdUnitMutation();
    const [activateAdServerLadle] = useActivateAdServerLadleMutation();
    const [createAdServerLadle] = useCreateAdServerLadleMutation();

    const awsRegionsOptions = useMemo(
        () =>
            (awsRegions || [])
                .filter((region) => region.active)
                .map((region) => ({
                    value: region.code,
                    label: region.name,
                })),
        [awsRegions]
    );

    const lineItemsOptions = useMemo(
        () =>
            (lineItems || []).map((lineItem) => ({
                value: lineItem.id,
                label: lineItem.name,
            })),
        [lineItems]
    );

    const ordersOptions = useMemo(
        () =>
            (orders || []).map((order) => ({
                value: order.id,
                label: order.name,
            })),
        [orders]
    );

    const networksOptions = useMemo(
        () =>
            (networks || []).map((network) => ({
                value: network.code,
                label: network.name,
            })),
        [networks]
    );

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
    };

    const handleDefinition = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_LADLE_DEFINITION(
                isUnitPage,
                seatId,
                drawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            ) as string
        );
    };

    const handleSaveAndActivate = async () => {
        handleSubmit(true);
    };

    const handleSubmit = async (saveAndActivate = false) => {
        try {
            await form.validateFields();
            setIsSubmitting(true);
            const payload = form.getFieldsValue();

            try {
                let ladleRes: AdServerLadleResponse;

                if (saveAndActivate) {
                    const adUnitData: AdUnit = await getAdUnit(Number(channelAdUnitId || adUnitId)).unwrap();
                    payload[LADLE_FORM_FIELDS.AdUnitCode] = adUnitData?.code as string;
                    const createdAdServerLadle = await createAdServerLadle({
                        id: null,
                        body: { ...payload, ladleType: SAVE_AND_ACTIVATE_LADLE_TYPE },
                    }).unwrap();
                    ladleRes = await activateAdServerLadle({
                        id: Number(createdAdServerLadle.id),
                        body: {},
                    }).unwrap();
                } else {
                    ladleRes = await createLadle({
                        id: Number(channelAdUnitId || unitId),
                        body: { ...payload, ladleType: ACTIVATE_LADLE_TYPE },
                    }).unwrap();
                }

                notification.success({
                    message: "Ladle Processing...",
                    description: (
                        <Typography.Text>
                            {ladleRes.ladleName} is currently processing. Once finished you can view the results under
                            <a href={`#${ROUTE_FORMATTERS.SEAT_DIAGNOSTICS(seatId)}`}> Ladle Results</a>
                        </Typography.Text>
                    ),
                    duration: 10,
                });
                form.resetFields();
            } catch (error) {
                notification.error({
                    message: "Ladle Error",
                    description: error?.data?.errorDescription,
                    duration: 10,
                });
            } finally {
                setIsSubmitting(false);
            }
        } catch (error) {}
    };

    return {
        initialValues,
        form,
        isSubmitting,
        handleCancel,
        handleSubmit,
        ordersOptions,
        hasAdminAccess,
        networksOptions,
        lineItemsOptions,
        isFetchingOrders,
        handleDefinition,
        breadcrumbs: nestedBreadcrumbs,
        awsRegionsOptions,
        isFetchingNetworks,
        isFetchingLineItems,
        isFetchingAwsRegions,
        handleSaveAndActivate,
    };
};
