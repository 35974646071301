import { Publisher } from "./publishers";
import {
    BRANDS_SUPPLY_TAG,
    BRAND_TAG,
    consoleApi,
    PUBLISHER_BRANDS_TAG,
    BRAND_CHANNEL_TAG,
    APP_STORE_TAG,
    BRAND_INHERITED_PIXELS_TAG,
    CHANGELOG_TAG,
    SUPPLY_INHERITED_PIXELS_TAG,
    AD_UNITS_INHERITED_PIXELS_TAG,
} from "./console";
import { SeatAdvertiserDomainFilterListDef } from "./seatAdvertiserDomainFilterListDefs";
import { ThirdPartyPixel } from "./pixels";
import { Label, LabelValue } from "./labels";
import {
    AdomainFreqCapping,
    AdUnit,
    ChangeLogEntityType,
    IabCategory,
    IndustriesCategory,
    SupplyResellType,
    Targeting,
    TargetingMode,
} from "@app/core/services";
import { toQueryString } from "./utils";
import { GlobalIndustry } from "@app/core/components";

export interface BlockedIabCategory {
    creationDate: string;
    updateDate: string;
    code: string;
    id: number;
    name: string;
    parentId: number;
    parentName: string;
}

export interface AppStore {
    creationDate: string;
    entityType: "AppStore";
    id: number;
    name: string;
    updateDate: string;
}

export interface AppStoreData {
    appStore: AppStore | null;
    bundleId: "notFound" | string;
    creationDate: string;
    id: number | null;
    internalId: number | null;
    name: "notFound" | string;
    storeUrl: "notFound" | string;
    updateDate: string;
}

export interface Brand {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    code: string;
    description: string;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    publisher: Publisher;
    advertiserDomains: Array<string>;
    iabCategories: IabCategory[];
    blockedIabCategories: BlockedIabCategory[];
    blockExceptionIabCategories: BlockedIabCategory[];
    blockedIndustries: GlobalIndustry[] | null;
    publisherReauction?: boolean | null;
    advertiserBlockDomains: string[];
    enableAdvertisersAsWhitelist: boolean;
    calculonUseRateOverride: number | null;
    upperCalculonUseRateOverride: number | null;
    calculatedCalculonUseRateOverride: number | null;
    calculatedUpperCalculonUseRateOverride: number | null;
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    buyerSeatList: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    } | null;
    labelValues: LabelValue[];
    affiliateCostModel?: { id: number; name: string };
    affiliateCostValuePercent?: number | null;
    affiliateCostValueFixed?: number | null;
    distributionGroupLabelValues: LabelValue[];
    internalLabelValues?: LabelValue[];
    creativeBlockingMode?: { id: number; name: string } | null;
    thirdPartyPixels: ThirdPartyPixel[];
    externalCodes: unknown[];
    targeting: Array<Targeting>;
    targetingMode: TargetingMode;
    entityType: "Brand" | "Channel";
}

export interface BrandSupply {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    enableNoAdCookieSync: boolean | null;
    description: string;
    type: {
        name: string;
        id: number;
    };
    appStore: AppStore | null;
    maskIFAForNetworks: {
        entityType: string;
        id: number;
        name: string;
        seat: {
            code: string;
            entityType: string;
            id: number;
            name: string;
        };
    }[];
    resellType: SupplyResellType;
    thirdPartyPixels: ThirdPartyPixel[];
    keywords: string[];
    bundleId: string | null;
    publisherReauction?: boolean | null;
    bundleIdIsOverride: boolean;
    affiliateCostModel?: { id: number; name: string };
    affiliateCostValuePercent?: number | null;
    affiliateCostValueFixed?: number | null;
    hasPrivacyPolicy: boolean | null;
    ifaType: {
        id: number;
        name: string;
        code: string;
    } | null;
    isCoppa: boolean | null;
    paidType: {
        id: number;
        name: string;
    } | null;
    supplyNameOverride: string | null;
    storeUrl: string | null;
    brand: Brand;
    code: string;
    domain: string | null;
    inventoryPartnerDomain: string | null;
    videoQuality: {
        creationDate: string;
        updateDate: string;
        name: string;
        id: number;
    };
    sourceRelationship: {
        id: number;
        name: string;
    };
    externalCodes: unknown[];
    allowVastWrappers: boolean;
    advertiserBlockDomains: string[];
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    supplySupplyDomainFilterLists: { id: number; name: string; creationDate: string; updateDate: string }[];
    allowSupplySupplyDomainFilterLists: unknown[];
    supplyBundleIdFilterLists: { id: number; name: string; creationDate: string; updateDate: string }[];
    allowSupplyBundleIdFilterLists: unknown[];
    calculonUseRateOverride: number | null;
    upperCalculonUseRateOverride: number | null;
    calculatedCalculonUseRateOverride: number | null;
    calculatedUpperCalculonUseRateOverride: number | null;
    labelValues: LabelValue[];
    prefilterLabelValues: LabelValue[];
    internalLabelValues: LabelValue[];
    buyerSeatList: { id: number; name?: string } | null;
    viewabilityVendors: {
        domain: string;
        id: number;
        name: string;
    }[];
    entityType: "Supply";
    isDynamic: boolean;
}

export interface BrandChannel {
    id: number;
    name: string;
    associatedTemplate: null;
    publisher: Publisher;
    brand: Brand;
    supply: BrandSupply;
    adUnit: AdUnit;
}

export interface GetBrandSupplyAdUnitsParams {
    brandId: string | number;
    max?: number;
    page?: number;
    keyword?: string;
}

export interface GetPublisherBrandsParams {
    publisherId: string | number;
    max?: number;
    page?: number;
    keyword?: string;
}

export interface GetSeatBrandsSafetyParams {
    seatId: number | string;
}

export interface GetSeatBrandsParams {
    seatId: string;
    max?: number;
    page?: number;
    keyword?: string;
    channels?: boolean;
}

export interface GetAppStoreDataParams {
    appStoreId: number;
    bundleId: string;
}

export interface GetSeatAllBrandsParams {
    seatId: number;
}

export interface BrandSafetyCreated {
    advertiserDomains: string[];
    blockedIabCategories: {
        id: number;
        name: string;
    }[];
    blockedIndustries: { id: number }[] | null;
    id: number;
    name: string;
    seat: { id: number };
    seatAdvertiserDomainFilterListDefs: {
        id: number;
    }[];
    targeting: Targeting;
    targetingMode: {
        id: number;
        name: string;
    };
    type: {
        id: number;
        name: string;
    };
}

export interface BrandPayload {
    publisher: { id: number };

    // General
    name: string;
    code: string;
    description: string;

    // Brand Safety
    seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
    advertiserBlockDomains: string[] | null;
    blockedIabCategories: { id: number; name?: string }[];
    blockedIndustries: { id: number }[] | null;
    buyerSeatList: { id: number; name?: string } | null;
    creativeBlockingMode?: { id: number; name?: string };

    // Internal Section
    calculonUseRateOverride?: number | null;
    upperCalculonUseRateOverride?: number | null;
    publisherReauction?: boolean | null;

    //Supply Details
    iabCategories: { id: number; name?: string }[];
    enableAdvertisersAsWhitelist?: boolean;

    // Custom Pixels
    thirdPartyPixels: { id: number; name?: string }[];

    // Labels
    labelValues: { id: number; value: string; label: Label }[];
    internalLabelValues?: { id: number; value: string; label: Label }[];
    distributionGroupLabelValues?: { id: number; value: string; label: Label }[];
}

interface CreateBrandParams {
    publisherId: number;
    body: BrandPayload;
}

interface UpdateBrandPayload extends BrandPayload {
    id: number;
}

interface AdUnitFlags {
    podDeDupeMode: number | null;
}

export interface BrandChannelPayload {
    name: string;
    publisher: {
        id: number;
        seat: {
            code: string;
            id: number;
            name: string;
        };
    };
    brand: {
        name: string;
        id: null | number | undefined;
        description: string;
        iabCategories: { id: number; name?: string }[];
        // Brand Safety
        seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
        advertiserBlockDomains: string[] | null;
        blockedIabCategories: { id: number; name?: string }[];
        blockedIndustries: { id: number }[] | null;
        buyerSeatList: { id: number; name?: string } | null;
        // Custom Pixels
        thirdPartyPixels: { id: number; name?: string }[];
        // Labels
        labelValues: { id: number; value: string; label: Label }[];
        internalLabelValues?: { id: number; value: string; label: Label }[];
        distributionGroupLabelValues?: { id: number; value: string; label: Label }[];
        //Detailed Configuration Section
        enableAdvertisersAsWhitelist: boolean;
    };
    supply: {
        name: string;
        id: null | number | undefined;
        isCoppa: boolean | null;
        hasPrivacyPolicy: boolean | null;
        //Detailed Configuration Section
        allowVastWrappers: boolean;
        enableNoAdCookieSync?: boolean;
        inventoryPartnerDomain: string | null;
    };
    adUnit: {
        name: string;
        id: null | number | undefined;
        status: { id: number; name?: string };
        code: string;

        ///Defaults Section
        mimes: { id: number; name?: string }[];
        supportedApis: { id: number; name?: string }[];
        linearity: { id: number } | null;
        type: { id: number; name?: string };
        startDelay: number;
        isInterstitial: boolean;
        placement: { id: number; name?: string };
        iabCategories: { id: number; name?: string }[];
        supportedProtocols: { id: number; name?: string }[];
        minDuration: number;
        maxDuration: number;
        maxExtended: number;
        minBitrate: number | null;
        maxBitrate: number | null;
        ssaiType: { id: number; name?: string } | null;
        isLiveStream: boolean;
        skip: boolean | null;
        skipafter: number | null;
        skipmin: number | null;
        multiplicity: { id: number; name?: string };
        // Ad Pod Section
        maxPodSeconds: number | null;
        maxAdsPerPod: number | null;
        adPodFillMode: { id: number; name?: string } | null;
        podEnforcement: { id: number; name?: string } | null;
        separationGroups:
            | {
                  iabCategories: IabCategory[];
                  id: number;
                  industries: IndustriesCategory[];
                  name: string;
              }[]
            | null;
        adUnitFlags: AdUnitFlags | null;
        // Playlist Configuration Section
        maxAdsPerAdvertiser?: number | null;
        playlistDefinitionMode?: { id: number; name?: string } | null;
        //Ad Break Rules Section
        adBreakRules: {
            adBreakLabel: string;
            maxAdsPerPod: number;
            maxPodLengthSeconds: number;
            midRollIndex: number | null;
        }[];
        cuepoints: string[] | null;
        //Detailed Configuration Section
        lsaEnabled: boolean;
        lsaWindowSizeSeconds: number | null;
        unwrapVast: boolean;
        impressionWaitTime: { id: number; name?: string } | null;
        publisherReauction?: boolean | null;
        adomainFreqCappingType: { id: number; name?: string };
        adomainFreqCappings: AdomainFreqCapping[] | null;
        // Custom Pixels
        thirdPartyPixels: { id: number; name?: string }[];
    };
}

interface CreateBrandChannelParams {
    publisherId: number;
    body: BrandChannelPayload;
}

interface UpdateBrandChannelPayload extends BrandChannelPayload {
    id: number;
}

interface BrandValidationPayloadParams {
    seatId: number;
    body: string[];
}

export interface UpdateBrandBrandSafetyPayload {
    id: number;
    advertiserBlockDomains: string[];
    buyerSeatList: { id: number } | null;
    seatAdvertiserDomainFilterListDefs: { id: number }[];
    blockedIabCategories: { id: number }[];
    blockedIndustries: { id: number }[] | null;
}

export interface UpdateBrandChannelBrandSafetyPayload {
    id: number;
    brand: {
        advertiserBlockDomains: string[];
        buyerSeatList: { id: number } | null;
        seatAdvertiserDomainFilterListDefs: { id: number }[];
        blockedIabCategories: { id: number }[];
        blockedIndustries: { id: number }[] | null;
    };
}

export const brandsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getBrandById: builder.query<Brand, number>({
            query: (id: number) => `/brands/${id}`,
            providesTags: (_, __, id) => [{ type: BRAND_TAG, id }],
        }),
        getBrandsSupply: builder.query<BrandSupply[], GetBrandSupplyAdUnitsParams>({
            query: ({ brandId }: GetBrandSupplyAdUnitsParams) => {
                return `brands/${brandId}/supply`;
            },
            providesTags: (_, __, { brandId: id }) => [{ type: BRANDS_SUPPLY_TAG, id }],
        }),

        getPublisherBrands: builder.query<Brand[], GetPublisherBrandsParams>({
            query: ({ publisherId }: GetPublisherBrandsParams) => `publishers/${publisherId}/brands`,
            providesTags: (_, __, { publisherId: id }) => [{ type: PUBLISHER_BRANDS_TAG, id }],
        }),
        getBrandChannel: builder.query<BrandChannel, number>({
            query: (channelId: number) => `channels/brandChannels/${channelId}`,
            providesTags: (_, __, id) => [{ type: BRAND_CHANNEL_TAG, id }],
        }),
        createBrandChannel: builder.mutation<BrandChannel, CreateBrandChannelParams>({
            query: ({ body, publisherId }: CreateBrandChannelParams) => {
                return {
                    url: `publishers/${publisherId}/brandChannels`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err, { publisherId }) =>
                err ? [] : [{ type: PUBLISHER_BRANDS_TAG, id: publisherId }],
        }),
        updateBrandChannel: builder.mutation<BrandChannel, UpdateBrandChannelPayload>({
            query: (body: UpdateBrandChannelPayload) => {
                return {
                    url: `channels/brandChannels/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id, publisher }) =>
                err
                    ? []
                    : [
                          { type: PUBLISHER_BRANDS_TAG, id: publisher.id },
                          { type: BRAND_CHANNEL_TAG, id },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.BrandChannels}-${id}` },
                          { type: SUPPLY_INHERITED_PIXELS_TAG },
                          { type: AD_UNITS_INHERITED_PIXELS_TAG },
                      ],
        }),
        deleteBrandChannel: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `channels/brandChannels/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [PUBLISHER_BRANDS_TAG]),
        }),
        getSeatBrandsPicklist: builder.query<Brand[], GetSeatBrandsParams>({
            query: (params: GetSeatBrandsParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/brands/picklist;${toQueryString(rest)}`;
            },
        }),
        getAppStore: builder.query<AppStore[], void>({
            query: () => "appStore",
            providesTags: [APP_STORE_TAG],
        }),
        getAppStoreData: builder.query<AppStoreData, GetAppStoreDataParams>({
            query: ({ appStoreId, bundleId }) => {
                return {
                    url: `appStoreData/appStore/${appStoreId}/bundleId/${bundleId}`,
                    method: "POST",
                    body: {},
                };
            },
        }),
        getSeatAllBrands: builder.query<Brand[], GetSeatAllBrandsParams>({
            query: (params: GetSeatAllBrandsParams) => {
                const { seatId } = params;
                return `seats/${seatId}/brands`;
            },
        }),
        getSeatBrands: builder.query<Brand[], GetSeatBrandsParams>({
            query: (params: GetSeatBrandsParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/brands;${toQueryString(rest)}`;
            },
        }),
        updateBrandBrandSafety: builder.mutation<BrandSafetyCreated, UpdateBrandBrandSafetyPayload>({
            query: (payload: UpdateBrandBrandSafetyPayload) => {
                return {
                    url: `brands/${payload.id}`,
                    method: "PUT",
                    body: payload,
                };
            },
            invalidatesTags: (_, __, { id }) => [{ type: BRAND_TAG, id }],
        }),
        updateBrandChannelBrandSafety: builder.mutation<BrandChannel, UpdateBrandChannelBrandSafetyPayload>({
            query: (body: UpdateBrandChannelBrandSafetyPayload) => {
                return {
                    url: `channels/brandChannels/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, __, { id }) => [{ type: BRAND_TAG, id }],
        }),
        createBrand: builder.mutation<Brand, CreateBrandParams>({
            query: ({ body, publisherId }: CreateBrandParams) => {
                return {
                    url: `/publishers/${publisherId}/brands`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err, { publisherId }) =>
                err ? [] : [{ type: PUBLISHER_BRANDS_TAG, id: publisherId }],
        }),
        updateBrand: builder.mutation<Brand, UpdateBrandPayload>({
            query: (body: UpdateBrandPayload) => {
                return {
                    url: `/brands/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id, publisher }) =>
                err
                    ? []
                    : [
                          { type: PUBLISHER_BRANDS_TAG, id: publisher.id },
                          { type: BRAND_TAG, id },
                          { type: BRAND_INHERITED_PIXELS_TAG, id },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Brand}-${id}` },
                      ],
        }),
        deleteBrand: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `brands/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [PUBLISHER_BRANDS_TAG]),
        }),
        validateBrands: builder.mutation<Brand[], BrandValidationPayloadParams>({
            query: ({ seatId, body }: BrandValidationPayloadParams) => {
                return {
                    url: `validations/access;seatId=${seatId};type=brand`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAppStoreDataQuery,
    useGetAppStoreQuery,
    useGetBrandByIdQuery,
    useCreateBrandChannelMutation,
    useUpdateBrandChannelMutation,
    useDeleteBrandChannelMutation,
    useCreateBrandMutation,
    useDeleteBrandMutation,
    useGetBrandsSupplyQuery,
    useLazyGetBrandsSupplyQuery,
    useGetPublisherBrandsQuery,
    useLazyGetPublisherBrandsQuery,
    useGetBrandChannelQuery,
    useGetSeatBrandsPicklistQuery,
    useGetSeatBrandsQuery,
    useGetSeatAllBrandsQuery,
    useUpdateBrandMutation,
    useValidateBrandsMutation,
    useUpdateBrandBrandSafetyMutation,
    useUpdateBrandChannelBrandSafetyMutation,
} = brandsApi;
