import { FC, useEffect } from "react";
import { Flex, Grid, Space } from "antd";
import { useParams } from "react-router-dom";
import { Loading } from "@app/core/components";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { Seat, useGetSeatSelfQuery } from "@app/core/services";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { useBrandSafetyCollapsePanel } from "../BrandSafetyPublisherPage/useBrandSafetyCollapsePanel";
import { ScreensBreakpoint, TreeDrawer } from "@app/features/inventory/InventoryBrandSafety/TreeDrawer";
import { BRAND_SAFETY_GUTTER } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";

const { useBreakpoint } = Grid;

export const BrandSafetySeatPage: FC = () => {
    const screens: ScreensBreakpoint = useBreakpoint();
    const { md } = screens;
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelf, isFetching } = useGetSeatSelfQuery(seatId);
    const seat: Seat | null = seatSelf?.seat || null;
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.SEAT,
    });

    useEffect((): void => {
        if (seat) {
            expandKeys([`seat-${seat.id}`]);
            setSelectedKeys([`seat-${seat.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seat]);

    if (!seat || isFetching) return <Loading />;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex align="center" justify={md ? "space-between" : "start"} wrap gap={BRAND_SAFETY_GUTTER}>
                <Flex align="center" gap={BRAND_SAFETY_GUTTER}>
                    {!md && <TreeDrawer />}
                    <InventoryFloorsBreadcrumb sufix={`Seat Brand Safety: ${seat.name}`} />
                </Flex>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel seat={seat} activeKey={activeKey} onChange={onChange} />
        </Space>
    );
};
