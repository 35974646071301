import { SEARCH } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { useGetAuditLogQuery } from "@magnite/client-streaming-platform";
import { uri } from "@rubicon/utils";
import { Button, Collapse, Layout, Space, Typography } from "antd";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AuditLogTable } from "./AuditLogTable";
import { USER_QUERY_PARAM, getUserQueryParam } from "./AuditLogFilters/AuditLogUserSelect";
import { ENTITY_QUERY_PARAM, getEntityQueryParam } from "./AuditLogFilters/AuditLogEntitySelect";
import { ACTION_TYPE_QUERY_PARAM, getActionTypeQueryParam } from "./AuditLogFilters/AuditLogActionTypeSelect";
import {
    END_DATE_QUERY_PARAM,
    START_DATE_QUERY_PARAM,
    getEndDateQueryParam,
    getStartDateQueryParam,
} from "./AuditLogFilters/AuditLogDateRangePickers";
import { AuditLogFilters } from "./AuditLogFilters";
import { PAGE, SIZE } from "../../../core/components/hooks/usePagination";
import { PageHeader } from "@ant-design/pro-layout";
import { sectionHeaderClassname } from "../constants";

export const AUDIT_LOG_CLEAR_BUTTON_SDET = "audit-log-clear-button";
export const AUDIT_LOG_REFRESH_PRIMARY_BUTTON_SDET = "audit-log-refresh-primary-button";

const getAuditLogParams = (queryString: string, seatId: string) => {
    const keyword = new URLSearchParams(queryString).get(SEARCH.key) || SEARCH.default;
    const email = getUserQueryParam(queryString);
    const actionType = getActionTypeQueryParam(queryString);
    const type = getEntityQueryParam(queryString);
    const startDate = getStartDateQueryParam(queryString);
    const endDate = getEndDateQueryParam(queryString);
    const page = Number(new URLSearchParams(queryString).get(PAGE.key)) || Number(PAGE.default);
    const size = Number(new URLSearchParams(queryString).get(SIZE.key)) || Number(SIZE.default);

    return {
        rootType: "com.tremorvideo.ssp.platform.model.Seat",
        rootId: Number(seatId),
        email,
        actionType,
        type,
        maxRows: size,
        page,
        keyword,
        startDate,
        endDate,
    };
};
export const AuditLogPage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const params = useMemo(() => getAuditLogParams(queryString, seatId), [queryString, seatId]);
    const { data = [], isFetching, error, refetch } = useGetAuditLogQuery(params);
    const history = useHistory();

    useNotificationError(error);

    const onClear = () => {
        const deleteEmail = uri.deleteSearchParam(queryString, USER_QUERY_PARAM);
        const deleteType = uri.deleteSearchParam(deleteEmail, ENTITY_QUERY_PARAM);
        const deleteActionType = uri.deleteSearchParam(deleteType, ACTION_TYPE_QUERY_PARAM);
        const deleteStartDate = uri.deleteSearchParam(deleteActionType, START_DATE_QUERY_PARAM);
        const nextQueryString = uri.deleteSearchParam(deleteStartDate, END_DATE_QUERY_PARAM);

        history.push({
            search: nextQueryString,
        });
    };

    const { email, actionType, type, keyword, startDate, endDate, page, maxRows } = params;
    // Faked to only ever show next page since we don't have any pagination metadata
    const totalCount = page * maxRows + (data?.length === maxRows ? 1 : 0);

    // since totalCount is based on page and size, need to reset page to 1 when params other than page are changed
    useEffect(() => {
        if (page !== 1) {
            const nextSearchPage = uri.setSearchParam(queryString, PAGE.key, PAGE.default);
            history.push({ search: nextSearchPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, actionType, type, keyword, startDate, endDate, maxRows]);

    return (
        <>
            <PageHeader
                title="Audit Log"
                extra={
                    <Button data-sdet={AUDIT_LOG_REFRESH_PRIMARY_BUTTON_SDET} type="primary" onClick={refetch}>
                        Refresh
                    </Button>
                }
            />
            <Layout.Content>
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <Collapse
                        defaultActiveKey={[1]}
                        bordered
                        collapsible="header"
                        className={sectionHeaderClassname}
                        ghost={false}
                        items={[
                            {
                                key: "1",
                                children: <AuditLogFilters />,
                                label: (
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        Filters
                                    </Typography.Title>
                                ),
                                extra: (
                                    <Button data-sdet={AUDIT_LOG_CLEAR_BUTTON_SDET} onClick={onClear}>
                                        Clear
                                    </Button>
                                ),
                            },
                        ]}
                    />
                    <AuditLogTable data={data} isFetching={isFetching} totalCount={totalCount} />
                </Space>
            </Layout.Content>
        </>
    );
};
