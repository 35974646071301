import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { NONE } from "@app/core/components/constants";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { AnimatedCell } from "@app/core/components/AnimatedCell";
import { AdStat, AdStatsById, getFillRate, getOtimesForThrottleUnit } from "@app/features/adStats";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { useSeatAuthContext } from "@app/core/auth";
import { CurrencyConversionModes } from "@app/core/clients/console";

interface InventoryHealthInventoryFillRateProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
}

export const InventoryHealthInventoryFillRate: FC<InventoryHealthInventoryFillRateProps> = ({
    adStats,
    sourceId,
    isConnected,
}) => {
    const { session } = useSeatAuthContext();
    const adStat: AdStat | null = adStats[sourceId];
    const { preferredCurrency } = useCurrencyConversion();
    const currencyConversionMode = session?.user.userPreferenceMap.currencyConversionMode as CurrencyConversionModes;
    const fillRate: number | undefined = getFillRate(adStat, preferredCurrency, currencyConversionMode, false);
    const otime = getOtimesForThrottleUnit(adStat);

    const throttledTries: number | undefined = useThrottle<number | undefined>(fillRate || undefined, {
        forceUpdateDependencies: [otime, preferredCurrency, currencyConversionMode],
    });

    if (!isConnected || !adStat) return <LoadingCell />;

    if (throttledTries === undefined) return <>{NONE}</>;

    return <AnimatedCell value={throttledTries} decimals={2} suffix="%" />;
};
