import { FC, useMemo } from "react";
import { MixOptions } from "@antv/g2plot";
import { formatNumber } from "@rubicon/utils";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { DEFAULT_COLOR, LABEL_COLORS } from "@app/core/components/charts/constants";
import { MixChart } from "./MixChart";

export interface MetricOptions {
    /**
     * Defines which metrics are currency values
     */
    currency: boolean;
}

export interface ValueMetric1 {
    time: string;
    value1: number;
    name1: string;
    options?: MetricOptions;
}

export interface ValueMetric2 {
    time: string;
    value2: number;
    name2: string;
    options?: MetricOptions;
}

export type Metrics = { metricOne: ValueMetric1[]; metricTwo: ValueMetric2[] };

/**
 *
 * @param metrics
 * @param multiplier - added for scaling the axis max value, defaulted as 1.2 (+20%)
 */
export const getMaxValue1 = (metrics: ValueMetric1[] = [], multiplier = 1.2) => {
    const values = metrics.map((m) => m.value1);
    if (values.length === 0) return 0;
    return Math.max(...values) * multiplier;
};

/**
 *
 * @param metrics
 * @param multiplier - added for scaling the axis max value, defaulted as 1.2 (+20%)
 */
export const getMaxValue2 = (metrics: ValueMetric2[] = [], multiplier = 1.2) => {
    const values = metrics.map((m) => m.value2);
    if (values.length === 0) return 0;
    return Math.max(...values) * multiplier;
};

export interface MixChartProps {
    chartId: string;
    metricOne?: ValueMetric1[];
    metricTwo?: ValueMetric2[];
    metricOneYAxisTitle?: string;
    metricTwoYAxisTitle?: string;
    disablePoints?: boolean;
    smoothLine?: boolean;
    height?: number;
}

/**
 * Mix chart with count metrics in @param metricOne represented as an area and currency metrics in @param metricTwo represented as a line.
 * Count metrics are raw numbers eg: impressions
 * Currency metrics are shown with an associated currency symbol
 */
export const MixCountAreaCurrencyLineChart: FC<MixChartProps> = ({
    metricOne = [],
    metricTwo = [],
    metricOneYAxisTitle = "",
    metricTwoYAxisTitle = "",
    chartId = "chart",
    disablePoints = false,
    smoothLine = true,
    height = 400,
}) => {
    const { preferredCurrency } = useCurrencyConversion();
    const currencyCode = preferredCurrency?.code;
    const mixConfig = useMemo<MixOptions>(
        () => ({
            tooltip: {
                shared: true,
            },
            height,
            syncViewPadding: true,
            legend: false,
            plots: [
                {
                    type: "area",
                    options: {
                        animation: false,
                        isStack: false,
                        data: metricOne,
                        xField: "time",
                        yField: "value1",
                        seriesField: "name1",
                        point: disablePoints ? undefined : { shape: "circle" },
                        areaStyle: (d) => ({
                            fillOpacity: 0.8,
                            fill: `l(270): 0:#ffffff00 1:${LABEL_COLORS[d.name1] || DEFAULT_COLOR}`,
                        }),
                        line: {
                            style: {
                                lineWidth: 2,
                            },
                        },
                        tooltip: {
                            // separate tooltip as general formater is now working
                            showContent: false,
                            formatter: (data) => {
                                return {
                                    name: data.name1,
                                    value: formatNumber.asAbbreviated(data.value1),
                                };
                            },
                            position: "left",
                        },
                        yAxis: {
                            tickCount: 8,
                            top: true,
                            min: 0,
                            max: getMaxValue1(metricOne),
                            title: {
                                text: metricOneYAxisTitle,
                            },
                            label: {
                                formatter: (v) => formatNumber.asAbbreviated(v),
                            },
                        },
                        smooth: smoothLine,
                        color: (d) => LABEL_COLORS[d.name1] || DEFAULT_COLOR,
                    },
                },
                {
                    type: "line",
                    options: {
                        xAxis: {
                            //@ts-expect-error - G2Plot types are not up to date
                            visible: !metricOne.length,
                        },
                        animation: false,
                        data: metricTwo,
                        xField: "time",
                        yField: "value2",
                        seriesField: "name2",
                        point: disablePoints ? undefined : { shape: "circle" },
                        lineStyle: {
                            lineWidth: 3,
                        },
                        tooltip: {
                            // separate tooltip as general formater is now working
                            showContent: false,
                            formatter: (data) => {
                                return {
                                    name: data.name2,
                                    value: formatNumber.asMoneyAbbreviated(data.value2, currencyCode),
                                };
                            },
                            position: "left",
                        },
                        yAxis: {
                            tickCount: 8,
                            title: {
                                text: metricTwoYAxisTitle,
                                //@ts-expect-error - G2Plot types are not up to date
                                rotate: Math.PI / 2,
                            },
                            top: true,
                            min: 0,
                            max: getMaxValue2(metricTwo),
                            position: "right",
                            //Show lines for net revenue when there is no data for the other metrics
                            grid: metricOne.length ? null : undefined,
                            label: {
                                formatter: (v) => formatNumber.asMoneyAbbreviated(v, currencyCode),
                            },
                        },
                        smooth: smoothLine,
                        color: (d) => LABEL_COLORS[d.name2] || DEFAULT_COLOR,
                    },
                },
            ],
        }),
        [
            metricOne,
            metricTwo,
            metricOneYAxisTitle,
            metricTwoYAxisTitle,
            currencyCode,
            disablePoints,
            smoothLine,
            height,
        ]
    );

    return (
        <div style={{ paddingBottom: "16px" }}>
            <MixChart chartId={chartId} config={mixConfig} />
        </div>
    );
};
