import { FC } from "react";
import { Card } from "antd";
import { CustomPixels } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/CustomPixelsSection/Fields";
import { BrandChannelInheritedCustomPixels } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/CustomPixelsSection/Fields/CustomPixels/BrandChannelInheritedCustomPixels";

export const CustomPixelsSection: FC = () => (
    <Card bordered={false}>
        <CustomPixels />
        <BrandChannelInheritedCustomPixels />
    </Card>
);
