import { FC } from "react";
import { Card, Space, Button } from "antd";

interface Props {
    visible: boolean;
    loading: boolean;
    cancel: () => void;
    handleSubmit: () => void;
    dealObjectMode: string;
}
export const DealObjectFormControls: FC<Props> = ({ visible, loading, cancel, handleSubmit, dealObjectMode }) => {
    if (!visible) {
        return null;
    }

    return (
        <Card bordered={false} style={{ paddingTop: 0, boxShadow: "none" }}>
            <Space>
                <Button loading={loading} disabled={loading} type="primary" onClick={handleSubmit}>
                    {dealObjectMode === "create" ? "Submit" : "Save"}
                </Button>
                <Button onClick={cancel}>Cancel</Button>
            </Space>
        </Card>
    );
};
