// View Filter
// From console source src/main/webapp/js/vm/home/supplyhome.vm.js
export const OVERALL = { id: 1, name: "Overall" };
export const PODS_AND_PLAYLISTS = { id: 2, name: "Pods & Playlists" };
export const STANDARD = { id: 3, name: "Standard" };

// Demand Type Filter
// From console source src/main/webapp/js/globals.js
export const DEMAND_TYPE_OPTIONS = [
    { id: 1, name: "Magnite Programmatic" },
    { id: 2, name: "Tag-Based" },
    { id: 3, name: "AdServing" },
];
// TODO: Reference constants instead of magic numbers
export const DEMAND_TYPE_EXTERNAL_IDS = {
    1: [1, 2, 3, 4, 10],
    2: [5, 6, 7, 8, 9],
    3: [100],
};
