import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetSupplyInheritedPixelsQuery } from "@app/core/services";
import { InheritedCustomPixels } from "@app/features/inventory/components/InheritedCustomPixels";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

export const SupplyInheritedCustomPixels: FC = () => {
    const { supplyId } = useParams<{ supplyId: string }>();
    const { mode } = useSupplyFormInstance();
    const isEditMode: boolean = mode === "edit";

    const { data = [] } = useGetSupplyInheritedPixelsQuery(
        { supplyId, includeMine: false },
        { skip: !isEditMode || !supplyId }
    );

    if (!isEditMode) return null;

    return <InheritedCustomPixels thirdPartyPixels={data} />;
};
