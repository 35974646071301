import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetAdUnitInheritedPixelsQuery } from "@app/core/services";
import { InheritedCustomPixels } from "@app/features/inventory/components/InheritedCustomPixels";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

export const AdUnitInheritedCustomPixels: FC = () => {
    const { adUnitId } = useParams<{ adUnitId: string }>();
    const { mode } = useAdUnitFormInstance();
    const isEditMode: boolean = mode === "edit";

    const { data = [] } = useGetAdUnitInheritedPixelsQuery(
        { adUnitId, includeMine: false },
        { skip: !isEditMode || !adUnitId }
    );

    if (!isEditMode) return null;

    return <InheritedCustomPixels thirdPartyPixels={data} />;
};
