import { downloadReport } from "@app/core/clients/console";
import { useGetAsyncAdSourceReportQuery } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { notification } from "antd";
import {
    selectHistoricalChartDealId,
    selectHistoricalChartAdSource,
    selectHistoricalChartDateRange,
    selectHistoricalChartGroupBy,
} from "@app/core/components/charts/HistoricalChart/reducer";

interface UseHistoricalChartExportButton {
    handleMenuClick: (e: { key: string }) => void;
}

export const useHistoricalChartExportButton = (): UseHistoricalChartExportButton => {
    const adSourceId = useAppSelector(selectHistoricalChartAdSource);
    const dealId = useAppSelector(selectHistoricalChartDealId);
    const dateRange = useAppSelector(selectHistoricalChartDateRange);
    const groupBy = useAppSelector(selectHistoricalChartGroupBy);
    const currencyId = useCurrencyConversion()?.preferredCurrency?.id ?? 1;

    const { data } = useGetAsyncAdSourceReportQuery(
        { adSourceId, dateRange, dealId, groupBy, currencyId },
        { skip: !adSourceId }
    );

    const handleMenuClick = (e: { key: "csv" | "xls" }) => {
        try {
            if (data) {
                downloadReport(data.code, e.key);
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.errorDescription });
        }
    };

    return {
        handleMenuClick,
    };
};
