import { FC, useMemo } from "react";
import { DualAxesOptions } from "@antv/g2plot";
import { formatNumber } from "@rubicon/utils";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { DualAxesChart } from "@app/core/components/charts/DualAxesChart/DualAxesChart";
import { DEFAULT_COLOR, LABEL_COLORS } from "@app/core/components/charts/constants";

export interface MetricOptions {
    /**
     * Defines which metrics are currency values
     */
    currency: boolean;
}

export interface ValueMetric1 {
    time: string;
    value1: number;
    name1: string;
    options?: MetricOptions;
}

export interface ValueMetric2 {
    time: string;
    value2: number;
    name2: string;
    options?: MetricOptions;
}

export type Metrics = { metricOne: ValueMetric1[]; metricTwo: ValueMetric2[] };

/**
 *
 * @param metrics
 * @param multiplier - added for scaling the axis max value, defaulted as 1.2 (+20%)
 */
export const getMaxValue1 = (metrics: ValueMetric1[] = [], multiplier = 1.2) => {
    const values = metrics.map((m) => m.value1);
    return Math.max(...values) * multiplier;
};

/**
 *
 * @param metrics
 * @param multiplier - added for scaling the axis max value, defaulted as 1.2 (+20%)
 */
export const getMaxValue2 = (metrics: ValueMetric2[] = [], multiplier = 1.2) => {
    const values = metrics.map((m) => m.value2);
    return Math.max(...values) * multiplier;
};

export interface DualAxesChartProps {
    chartId: string;
    metricOne?: ValueMetric1[];
    metricTwo?: ValueMetric2[];
    metricOneYAxisTitle?: string;
    metricTwoYAxisTitle?: string;
    disablePoints?: boolean;
}

/**
 * Dual Axes chart with count metrics in @param metricOne represented as a line and currency metrics in @param metricTwo also represented as a line.
 * Count metrics are raw numbers eg: impressions
 * Currency metrics are shown with an associated currency symbol
 */
export const DualAxesCountLineCurrencyLineChart: FC<DualAxesChartProps> = ({
    metricOne = [],
    metricTwo = [],
    metricOneYAxisTitle = "",
    metricTwoYAxisTitle = "",
    chartId = "chart",
    disablePoints = false,
}) => {
    const { preferredCurrency } = useCurrencyConversion();
    const currencyCode = preferredCurrency?.code;

    const config = useMemo<DualAxesOptions>(
        () => ({
            animation: false,
            data: [metricOne, metricTwo],
            height: 400,
            xField: "time",
            yField: ["value1", "value2"],
            legend: false,
            tooltip: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formatter: (data) => {
                    if (data.value1 || data.name1) {
                        return {
                            name: data.name1,
                            value: formatNumber.asAbbreviated(data.value1),
                        };
                    } else if (data.value2 || data.name2) {
                        return {
                            name: data.name2,
                            value: formatNumber.asMoneyAbbreviated(data.value2, currencyCode),
                        };
                    }
                },
            },
            yAxis: {
                value1: {
                    top: true,
                    min: 0,
                    max: getMaxValue1(metricOne),
                    tickCount: 8,
                    title: {
                        text: metricOneYAxisTitle,
                    },
                    label: {
                        formatter: (v) => formatNumber.asAbbreviated(v),
                    },
                },
                value2: {
                    title: {
                        text: metricTwoYAxisTitle,
                        rotate: Math.PI / 2,
                    },
                    top: true,
                    min: 0,
                    max: getMaxValue2(metricTwo),
                    tickCount: 8,
                    label: {
                        formatter: (v) => formatNumber.asMoneyAbbreviated(v, currencyCode),
                    },
                },
            },
            geometryOptions: [
                {
                    geometry: "line",
                    seriesField: "name1",
                    point: disablePoints
                        ? null
                        : {
                              shape: "circle",
                          },
                    color: (d) => LABEL_COLORS[d.name1] || DEFAULT_COLOR,
                },
                {
                    geometry: "line",
                    seriesField: "name2",
                    point: disablePoints
                        ? null
                        : {
                              shape: "circle",
                          },
                    color: (d) => LABEL_COLORS[d.name2] || DEFAULT_COLOR,
                },
            ],
        }),
        [metricOne, metricTwo, metricOneYAxisTitle, metricTwoYAxisTitle, disablePoints, currencyCode]
    );
    return <DualAxesChart chartId={chartId} config={config} />;
};
