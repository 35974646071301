import { useEffect } from "react";
import { Space, Grid, Flex } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";
import { useBrandSafetyCollapsePanel } from "./useBrandSafetyCollapsePanel";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { usePublisherById } from "@app/features/inventory/InventoryFloors/usePublisherById";
import { ScreensBreakpoint, TreeDrawer } from "@app/features/inventory/InventoryBrandSafety/TreeDrawer";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";
import { BRAND_SAFETY_GUTTER } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

const { useBreakpoint } = Grid;

export const BrandSafetyPublisherPage = () => {
    const screens: ScreensBreakpoint = useBreakpoint();
    const { md } = screens;
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { publisher, isFetching } = usePublisherById();
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.PUBLISHER,
    });

    useEffect(() => {
        if (context && publisher) {
            expandKeys([`seat-${context.id}`, `publisher-${publisher.id}`]);
            setSelectedKeys([`publisher-${publisher.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, publisher]);

    if (!context || isFetching || !publisher) {
        return <Loading />;
    }

    const items = [
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY(context.id, INVENTORY_LIST_PAGES.BRAND_SAFETY),
            title: context.name,
        },
        { title: publisher.name },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex align="baseline" justify={md ? "space-between" : "start"} wrap gap={BRAND_SAFETY_GUTTER}>
                <Flex align="center" gap={BRAND_SAFETY_GUTTER}>
                    {!md && <TreeDrawer />}
                    <InventoryFloorsBreadcrumb
                        items={items}
                        sufix={md ? `${publisher.entityType} Brand Safety:` : undefined}
                    />
                </Flex>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel seat={context} publisher={publisher} activeKey={activeKey} onChange={onChange} />
        </Space>
    );
};
