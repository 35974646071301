import { useEffect } from "react";
import { Flex, Grid, Space } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { useGetBrandByIdQuery, useGetPublisherByIdQuery, useGetSupplyQuery } from "@app/core/services";
import { useAdUnitById } from "@app/features/inventory/InventoryFloors/useAdUnitById";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { useBrandSafetyCollapsePanel } from "../BrandSafetyPublisherPage/useBrandSafetyCollapsePanel";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";
import { ScreensBreakpoint, TreeDrawer } from "@app/features/inventory/InventoryBrandSafety/TreeDrawer";
import { BRAND_SAFETY_GUTTER } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

const { useBreakpoint } = Grid;

export const BrandSafetyAdUnitPage = () => {
    const screens: ScreensBreakpoint = useBreakpoint();
    const { md } = screens;
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { adUnit, isFetching: isFetchingAdUnit } = useAdUnitById();
    const { data: supply, isFetching: isFetchingSupply } = useGetSupplyQuery(adUnit?.supply.id as number, {
        skip: !adUnit?.supply.id,
    });
    const { data: brand, isFetching: isFetchingBrand } = useGetBrandByIdQuery(adUnit?.supply.brand.id as number, {
        skip: !adUnit?.supply.brand.id,
    });
    const { data: publisher, isFetching: isFetchingPublisher } = useGetPublisherByIdQuery(
        adUnit?.supply.brand.publisher.id as number,
        {
            skip: !adUnit?.supply.brand.publisher.id,
        }
    );
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.AD_UNIT,
    });

    useEffect(() => {
        if (context && adUnit) {
            expandKeys([
                `seat-${context.id}`,
                `publisher-${adUnit.supply.brand.publisher.id}`,
                `brand-${adUnit.supply.brand.id}`,
                `supply-${adUnit.supply.id}`,
                `adUnit-${adUnit.id}`,
            ]);
            setSelectedKeys([`adUnit-${adUnit.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, adUnit]);

    if (!context || isFetchingAdUnit || isFetchingSupply || isFetchingPublisher || isFetchingBrand || !adUnit) {
        return <Loading />;
    }

    const items = [
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY(context.id, INVENTORY_LIST_PAGES.BRAND_SAFETY),
            title: context.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(
                context.id,
                adUnit.supply.brand.publisher.id
            ),
            title: adUnit.supply.brand.publisher.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND(context.id, adUnit.supply.brand.id),
            title: adUnit.supply.brand.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY(context.id, adUnit.supply.id),
            title: adUnit.supply.name,
        },
        { title: adUnit.name },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex align="baseline" justify={md ? "space-between" : "start"} wrap gap={BRAND_SAFETY_GUTTER}>
                <Flex align="center" gap={BRAND_SAFETY_GUTTER}>
                    {!md && <TreeDrawer />}
                    <InventoryFloorsBreadcrumb items={items} sufix={md ? "Ad Unit Brand Safety:" : undefined} />
                </Flex>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel
                seat={context}
                publisher={publisher}
                brand={brand}
                supply={supply}
                adUnit={adUnit}
                activeKey={activeKey}
                onChange={onChange}
            />
        </Space>
    );
};
