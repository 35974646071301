import { FC } from "react";
import { Card } from "antd";
import { CustomPixels } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/CustomPixelsSection/Fields";
import { BrandInheritedCustomPixels } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/CustomPixelsSection/Fields/CustomPixels/BrandInheritedCustomPixels";

export const CustomPixelsSection: FC = () => (
    <Card bordered={false}>
        <CustomPixels />
        <BrandInheritedCustomPixels />
    </Card>
);
