import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SkipToken } from "@reduxjs/toolkit/dist/query";
import {
    AdSource,
    AdSourcesListResponse,
    GetSeatAdSourcesParams,
    useGetAdSourceByIdQuery,
    useGetSeatAdSourcesQuery,
} from "@app/core/services";
import { notification } from "antd";

interface UseFetchSeatAdSource {
    adSource: AdSource | undefined;
    isFetching: boolean;
    isError: boolean;
}

interface UseFetchSeatAdSources {
    adSourcesListResponse: AdSourcesListResponse;
    isFetching: boolean;
}

export const useFetchSeatAdSource = (adSourceId: number): UseFetchSeatAdSource => {
    const { data, isFetching, isError } = useGetAdSourceByIdQuery(adSourceId);

    useEffect(() => {
        if (isError) {
            notification.error({ message: "Ad source fetching error" });
        }
    }, [isError]);

    return {
        adSource: data,
        isFetching: isFetching,
        isError,
    };
};

export const useFetchSeatAdSourceByUrlId = (): UseFetchSeatAdSource => {
    const { adSourceId } = useParams<{ adSourceId: string }>();
    const adSourceIdDigitsOnly = Number(adSourceId.replace(/\D/g, ""));
    if (!adSourceIdDigitsOnly) {
        throw new Error("Ad source ID must be present in the URL.");
    }

    return useFetchSeatAdSource(adSourceIdDigitsOnly);
};

export const useFetchSeatAdSources = (
    seatAllAdSourcesParams: GetSeatAdSourcesParams | SkipToken
): UseFetchSeatAdSources => {
    const { data, isFetching } = useGetSeatAdSourcesQuery(seatAllAdSourcesParams);

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        adSourcesListResponse: data,
        isFetching: isFetching,
    };
};
