import { AdSource } from "@app/core/services";
import { css } from "@emotion/css";
import { ReadOnlyAdSourceTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyAdSourceTargeting/ReadOnlyAdSourceTargeting";
import { Card, Collapse, Typography } from "antd";
import { FC } from "react";
import { DEAL_TYPES } from "../../constants";
import { classNameDealDetailsCardBody } from "../BuyerDealDetailsForm";
import { getAdSourceHeaderTitle } from "../helpers";
import {
    AdSourceBrandSafety,
    AdSourceCustomPixels,
    AdSourceDeliveryDetails,
    AdSourcePricing,
    AdSourceGeneral,
    AdSourceLabel,
    AdSourcePacing,
    AdSourceTransparency,
    AdSourceEnrichmentCost,
} from "./AdSourceSections";
import { gray10 } from "@rubicon/antd-components";
import { AdSourceDealsListTable } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDemandSection/AdSourceDealsListTable";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";

enum DEAL_DETAILS_SECTIONS {
    GENERAL = "GENERAL",
    PACING = "PACING",
    FLOOR_SETTINGS = "FLOOR_SETTINGS",
    DEMAND_SECTION = "DEMAND_SECTION",
    DELIVERY_DETAILS = "DELIVERY_DETAILS",
    BRAND_SAFETY = "BRAND_SAFETY",
    CUSTOM_PIXELS = "CUSTOM_PIXELS",
    TRANSPARENCY = "TRANSPARENCY",
    LABEL = "LABEL",
    TARGETING = "TARGETING",
}

export const DEAL_DETAILS_SECTIONS_NAME = {
    [DEAL_DETAILS_SECTIONS.GENERAL]: "General",
    [DEAL_DETAILS_SECTIONS.PACING]: "Pacing",
    [DEAL_DETAILS_SECTIONS.FLOOR_SETTINGS]: "Floor Settings",
    [DEAL_DETAILS_SECTIONS.DEMAND_SECTION]: "Demand Section",
    [DEAL_DETAILS_SECTIONS.DELIVERY_DETAILS]: "Delivery Details",
    [DEAL_DETAILS_SECTIONS.BRAND_SAFETY]: "Brand Safety",
    [DEAL_DETAILS_SECTIONS.CUSTOM_PIXELS]: "Custom Pixels",
    [DEAL_DETAILS_SECTIONS.TRANSPARENCY]: "Transparency",
    [DEAL_DETAILS_SECTIONS.LABEL]: "Label",
    [DEAL_DETAILS_SECTIONS.TARGETING]: "Targeting",
};

const classNameCollapse = css`
    border: 0;
`;
export const classNamePanel = css`
    .ant-collapse-header {
        background-color: #fff;
    }

    .ant-collapse-content {
        border-top: 0;
    }
`;

const classNamePanelHeader = css`
    font-weight: 500;
    color: ${gray10};
`;

const { Text } = Typography;

const PanelHeader: FC<{ title: string }> = ({ title, ...props }) => (
    <Text {...props} className={classNamePanelHeader}>
        {title}
    </Text>
);

interface Props {
    adSource: AdSource;
    entityType: string;
}

const DealDetailsPageAdSourceInner: FC<Props> = ({ entityType, adSource }) => {
    const { context } = useSeatAuthContext();
    const { showFallbackOpportunity } = useUserAccess();
    const {
        id,
        name,
        description,
        priority,
        bookingStartDate,
        bookingEndDate,
        synthesizedAdSourceStatus,
        type,
        udePriority,
        updateDate,
        creationDate,
        nurlTimeoutOverride,
        dailyCapVolume,
        dailyRequestOpportunityFallback,
        assignee,
        costModel,
        allowAutoscale,
        region,
        costValuePercent,
        costValueFixed,
        salesContact,
        externalContractId,
        //pacing
        redistributePacing,
        pacingType,
        customCurveImps,
        customCurveStartDate,
        //pricing
        priceModel,
        tierOverflowAllowed,
        adSourceFloorType,
        alwaysSendFloor,
        currencyType,
        auctionType,
        bookingPrice,
        overrideDynamicFloor,
        adResponsePriceOverrideType,
        //delivery details fields
        adSourceMinDuration,
        adSourceMaxDuration,
        timeZone,
        bookingVolume,
        inventoryDistributionGroups,
        //custom pixels
        thirdPartyPixels,
        //transparency
        allowSitePage,
        allowDomainName,
        allowSiteName,
        allowRef,
        allowBundleId,
        allowIp,
        allowUserId,
        maskUserId,
        allowStoreUrl,
        allowContent,
        allowAllExtendedId,
        domainNameOverride,
        bundleIdOverride,
        allowedExtendedIdTransparencies,
        allowAudienceLock,
        allowOztamCoviewing,
        //label
        labelValues,
        strictMode,
        //brand safety
        advertiserBlockingMode,
        advertiserBlockDomains,
        seatAdvertiserDomainFilterListDefs,
        // marketplace info
        marketplaceInfoList,
        //targeting
        targeting,
        targetingMode,
        contentTransparencyRule,
        // enrichment cost
        demandAcquisitionCost,
        demandAcquisitionCostModel,
        iabCategoryBlockMode,
        blockedIabCategories,
        blockExceptionIabCategories,
        industryBlockMode,
        blockedIndustries,
        pacingDeliveryScheduleEnabled,
        pacingDeliverySchedules,
    } = adSource;
    const hasPacing = type.id !== DEAL_TYPES.AUCTION;
    const title = getAdSourceHeaderTitle(type.id);
    const isDemandDeal = entityType === "DemandDeal";
    const defaultActiveKey = ["1", "2", !isDemandDeal && "3"].filter(Boolean) as string[];

    const dealsWithTargeting = marketplaceInfoList.filter(
        (marketPlaceInfo) => marketPlaceInfo.targeting.length > 0
    ).length;

    return (
        <Collapse defaultActiveKey={defaultActiveKey} className={classNameCollapse}>
            <Collapse.Panel
                className={classNamePanel}
                key={1}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.GENERAL} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceGeneral
                        id={id}
                        name={name}
                        type={type.name}
                        synthesizedStatus={synthesizedAdSourceStatus}
                        bookingStartDate={bookingStartDate}
                        bookingEndDate={bookingEndDate}
                        priority={priority.id}
                        description={description}
                        datedCreated={creationDate}
                        extendedTimeOutValue={nurlTimeoutOverride}
                        udePriority={udePriority?.name}
                        lastIndexed={null}
                        lastUpdated={updateDate}
                        region={region?.name}
                        assignee={assignee?.name}
                        costModel={costModel?.name}
                        allowAutoscale={allowAutoscale}
                        costValueFixed={costValueFixed}
                        costValuePercent={costValuePercent}
                        salesContact={salesContact?.name}
                        externalContractId={externalContractId}
                    />
                </Card>
            </Collapse.Panel>

            {hasPacing && (
                <Collapse.Panel
                    className={classNamePanel}
                    key={2}
                    header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.PACING} />}
                >
                    <Card bordered={false} className={classNameDealDetailsCardBody}>
                        <AdSourcePacing
                            type={type}
                            dailyImpression={dailyCapVolume}
                            dailyRequestOpportunityFallback={dailyRequestOpportunityFallback}
                            pacingType={pacingType}
                            redistributePacing={redistributePacing}
                            customCurveImps={customCurveImps}
                            customCurveStartDate={customCurveStartDate}
                            bookingVolume={bookingVolume}
                            showFallbackOpportunity={showFallbackOpportunity}
                            pacingDeliveryScheduleEnabled={pacingDeliveryScheduleEnabled}
                            pacingDeliverySchedules={pacingDeliverySchedules}
                            timeZoneCode={timeZone?.code ?? "UTC"}
                        />
                    </Card>
                </Collapse.Panel>
            )}

            <Collapse.Panel className={classNamePanel} key={3} header={<PanelHeader title={title} />}>
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourcePricing
                        alwaysSendFloor={alwaysSendFloor}
                        auctionType={auctionType?.name}
                        bookingPrice={bookingPrice}
                        bookingVolume={bookingVolume}
                        currency={currencyType}
                        floorType={adSourceFloorType.name}
                        overrideDynamicFloor={overrideDynamicFloor}
                        priceModel={priceModel}
                        targeting={targeting}
                        tierOverflowAllowed={tierOverflowAllowed}
                        type={type}
                        adResponsePriceOverrideType={adResponsePriceOverrideType?.name}
                    />
                </Card>
            </Collapse.Panel>

            {marketplaceInfoList.length > 1 && (
                <Collapse.Panel
                    className={classNamePanel}
                    key={4}
                    header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.DEMAND_SECTION} />}
                >
                    <Card bordered={false} className={classNameDealDetailsCardBody}>
                        <AdSourceDealsListTable dealAdSourceData={adSource} />
                    </Card>
                </Collapse.Panel>
            )}

            <Collapse.Panel
                className={classNamePanel}
                key={5}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.DELIVERY_DETAILS} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceDeliveryDetails
                        timeZone={timeZone}
                        startDate={bookingStartDate}
                        endDate={bookingEndDate}
                        totalImpression={bookingVolume}
                        minDuration={adSourceMinDuration}
                        maxDuration={adSourceMaxDuration}
                        inventoryDistributionGroups={inventoryDistributionGroups}
                        isInventoryDistributionGroupsVisible={Boolean(context?.distributionGroupsEnabled)}
                        typeID={type.id}
                    />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel
                className={classNamePanel}
                key={6}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.BRAND_SAFETY} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceBrandSafety
                        iabCategoryBlockMode={iabCategoryBlockMode}
                        blockedIabCategories={blockedIabCategories}
                        blockExceptionIabCategories={blockExceptionIabCategories}
                        advertiserBlockingMode={advertiserBlockingMode}
                        advertiserBlockDomains={advertiserBlockDomains}
                        seatAdvertiserDomainFilterListDefs={seatAdvertiserDomainFilterListDefs}
                        industriesMode={industryBlockMode?.name || null}
                        blockedIndustries={blockedIndustries}
                    />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel
                className={classNamePanel}
                key={7}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.CUSTOM_PIXELS} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceCustomPixels pixels={thirdPartyPixels} />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel
                className={classNamePanel}
                key={8}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.TRANSPARENCY} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceTransparency
                        allowAllExtendedId={allowAllExtendedId}
                        bundleIdOverride={bundleIdOverride}
                        domainNameOverride={domainNameOverride}
                        allowContent={allowContent}
                        allowStoreUrl={allowStoreUrl}
                        allowUserId={allowUserId}
                        maskUserId={maskUserId}
                        allowIp={allowIp}
                        allowBundleId={allowBundleId}
                        allowRef={allowRef}
                        allowDomainName={allowDomainName}
                        allowSitePage={allowSitePage}
                        allowSiteName={allowSiteName}
                        allowedExtendedIdTransparencies={allowedExtendedIdTransparencies}
                        contentTransparencyRule={contentTransparencyRule}
                        allowAudienceLock={allowAudienceLock}
                        allowOztamCoviewing={allowOztamCoviewing}
                    />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel
                className={classNamePanel}
                key={9}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.LABEL} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <AdSourceLabel strictMode={strictMode} labelValues={labelValues} />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel
                className={classNamePanel}
                key={10}
                header={<PanelHeader title={DEAL_DETAILS_SECTIONS_NAME.TARGETING} />}
            >
                <Card bordered={false} className={classNameDealDetailsCardBody}>
                    <ReadOnlyAdSourceTargeting
                        targetingBlocks={targeting}
                        targetingModeId={targetingMode.id}
                        dealsWithTargeting={dealsWithTargeting}
                    />
                </Card>
            </Collapse.Panel>
            <Collapse.Panel className={classNamePanel} key={11} header={<PanelHeader title="Enrichment Cost" />}>
                <AdSourceEnrichmentCost
                    adSourceFloorType={adSourceFloorType}
                    bookingPrice={bookingPrice}
                    currencyType={currencyType}
                    demandAcquisitionCost={demandAcquisitionCost}
                    demandAcquisitionCostModelId={demandAcquisitionCostModel?.id}
                    marketplaceInfoList={marketplaceInfoList}
                    name={name}
                    targeting={targeting}
                    type={type}
                />
            </Collapse.Panel>
        </Collapse>
    );
};

export const DealDetailsPageAdSource: FC<Props> = (props) => <DealDetailsPageAdSourceInner {...props} />;
