import { FC, useEffect, useState } from "react";
import { Currency } from "@app/core/services";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import {
    AdStat,
    getAverageFillTime,
    getBreaksPerPlaylist,
    getFillRate,
    getFills,
    getImpressions,
    getImpressionsUseRate,
    getPlaylistRequests,
    getPodRequests,
    getPodsAndPlaylistSlots,
    getRequests,
    getSlotsPerBreak,
    getSlotsPerPlaylist,
    getTotalRejectedRequests,
    getTotalRevenue,
} from "@app/features/adStats";
import { MetricsCard } from "@rubicon/antd-components";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { Space, Typography } from "antd";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";
import { ChartColors } from "@app/core/components/constants";
import { formatNumber } from "@rubicon/utils";

interface Props {
    adStat: AdStat | AdStat[];
    handleExtend: () => void;
    isExtended: boolean;
}

export const Last24HoursStatsMetrics: FC<Props> = ({ adStat, handleExtend, isExtended = false }) => {
    const { session } = useSeatAuthContext();
    const [stats, setStats] = useState<null | AdStat | AdStat[]>(null);
    const { isTremorUser } = useUserAccess();
    const currencyConversionMode = session?.user.userPreferenceMap.currencyConversionMode as CurrencyConversionModes;
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();

    useEffect(() => {
        if (!stats && adStat) {
            setStats(adStat);
        }
    }, [adStat, stats]);

    const totalGrossRev = getTotalRevenue(
        stats,
        preferredCurrency as Currency,
        currencyConversions,
        currencyConversionMode,
        RevenueTypes.GROSS_REVENUE
    );
    const totalNetRev = getTotalRevenue(
        stats,
        preferredCurrency as Currency,
        currencyConversions,
        currencyConversionMode,
        RevenueTypes.NET_REVENUE
    );
    const impressions = getImpressions(stats, preferredCurrency as Currency, currencyConversionMode);
    const fills = getFills(stats, preferredCurrency as Currency, currencyConversionMode);
    const requests = getRequests(stats);
    const rejectedRequests = getTotalRejectedRequests(stats as AdStat, isTremorUser);
    const fillRate = getFillRate(stats, preferredCurrency as Currency, currencyConversionMode, false);
    const impressionsUseRate = getImpressionsUseRate(
        stats,
        preferredCurrency as Currency,
        currencyConversionMode,
        false
    );

    const adPodRequests = getPodRequests(stats);
    const averageFillTime = getAverageFillTime(stats, preferredCurrency as Currency, currencyConversionMode);
    const breaksPerPlaylist = getBreaksPerPlaylist(stats);
    const playlistRequests = getPlaylistRequests(stats);
    const podSlotRequests = getPodsAndPlaylistSlots(stats);
    const slotsPerBreak = getSlotsPerBreak(stats);
    const slotsPerPlaylist = getSlotsPerPlaylist(stats);

    const items = [
        {
            title: "Rejected Requests",
            value: formatNumber.asWhole(rejectedRequests),
            color: ChartColors.Red,
        },
        {
            title: "Requests",
            value: formatNumber.asWhole(requests),
        },
        {
            title: "Ad Pod Requests",
            value: formatNumber.asWhole(adPodRequests),
        },
        {
            title: "Playlist Requests",
            value: formatNumber.asWhole(playlistRequests),
        },
        {
            title: "Pod Slot Requests",
            value: formatNumber.asWhole(podSlotRequests),
        },
        {
            title: "Fills",
            value: formatNumber.asWhole(fills),
        },
        {
            title: "Impressions",
            value: formatNumber.asWhole(impressions),
        },
        {
            title: "Gross Revenue",
            value: formatNumber.asMoney(totalGrossRev, preferredCurrency?.code || "USD"),
        },
        {
            title: "Net Revenue",
            value: formatNumber.asMoney(totalNetRev, preferredCurrency?.code || "USD"),
        },
        {
            title: "Fill Rate",
            value: formatNumber.asPercent(fillRate),
        },

        {
            title: "Use Rate",
            value: formatNumber.asPercent(impressionsUseRate),
        },
        {
            title: "Avg. Fill time",
            value: `${formatNumber.asWhole(averageFillTime)} ms`,
        },
        {
            title: "Breaks Per Playlist",
            value: formatNumber.asWhole(breaksPerPlaylist),
        },
        {
            title: "Slots Per Break",
            value: formatNumber.asNumber(slotsPerBreak),
        },
        {
            title: "Slots Per Playlist",
            value: formatNumber.asWhole(slotsPerPlaylist),
        },
    ];

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Typography.Text strong>Total Live Stats</Typography.Text>
            <MetricsCard items={items} handleExtend={handleExtend} isExtended={isExtended} isLoading={!adStat} />
        </Space>
    );
};
