import { FC } from "react";
import { Form, Tag } from "antd";
import { AdUnit } from "@app/core/services";
import { FormLayout } from "antd/lib/form/Form";
import { FORM_GRID_OPTIONS } from "../BrandSafetyPublisherPage/constants";
import {
    BlockedAdvertiserDomainListFormItem,
    BlockedAdvertiserDomainsFormItem,
    BlockedBuyerSeatListFormItem,
} from "./ViewFormItems";
import { NONE } from "@app/core/components/constants";
import { getDimension } from "@app/features/inventory/helpers";
import { DEFAULT_ADVERTISER_FREQUENCY_CAPPING } from "@app/features/inventory/HierarchyForms/constants";
import {
    BRAND_SAFETY_LABEL_COL,
    BRAND_SAFETY_WRAPPER_COL,
} from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

interface Props {
    adUnit: AdUnit;
    layout?: FormLayout;
}

export const BrandSafetyAdUnitForm: FC<Props> = ({ adUnit, layout = "horizontal" }) => {
    const hasAdomainFreqCappings =
        adUnit.adomainFreqCappingType &&
        adUnit.adomainFreqCappingType.id !== DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value;
    return (
        <Form layout={layout} {...FORM_GRID_OPTIONS[layout]} labelAlign="left">
            <BlockedAdvertiserDomainListFormItem
                seatAdvertiserDomainFilterListDefs={adUnit.seatAdvertiserDomainFilterListDefs}
            />
            <BlockedAdvertiserDomainsFormItem advertiserBlockDomains={adUnit.advertiserBlockDomains} />
            <BlockedBuyerSeatListFormItem buyerSeatList={adUnit.buyerSeatList} />
            <Form.Item
                labelCol={BRAND_SAFETY_LABEL_COL}
                wrapperCol={BRAND_SAFETY_WRAPPER_COL}
                label="Advertiser Frequency Capping"
            >
                {adUnit.adomainFreqCappingType.name}
            </Form.Item>
            {hasAdomainFreqCappings && (
                <Form.Item
                    label="Advertiser Frequency Caps"
                    labelCol={BRAND_SAFETY_LABEL_COL}
                    wrapperCol={BRAND_SAFETY_WRAPPER_COL}
                >
                    {adUnit.adomainFreqCappings?.length
                        ? adUnit.adomainFreqCappings.map((capp) => (
                              <Tag key={capp.id}>{`${capp.total} ${capp?.mode?.name} in ${getDimension(
                                  capp.timeDurationMin
                              )}`}</Tag>
                          ))
                        : NONE}
                </Form.Item>
            )}
        </Form>
    );
};
