import { FC } from "react";
import { useParams } from "react-router-dom";
import { Card, Collapse, Form } from "antd";
import { useUserAccess } from "@app/core/auth";
import { HelpKeysButton } from "../HelpKeysButton";
import { EntityTypes } from "@app/features/adStats";
import { Loading, SectionTitle } from "@app/core/components";
import { General, InternalSection, SupplyDetails } from "./Sections";
import { Labels, Performance, Pixels, BrandSafety, Affiliate } from "../SharedSections";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";
import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { BRAND_DETAILS_SECTIONS_NAME, classNameCardBody, SECTIONS_NAMES } from "../constants";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { Brand, useGetSeatSelfQuery } from "@app/core/services";

interface Props {
    brand: Brand;
}

export const BrandSections: FC<Props> = ({ brand }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const isInternalOnly = isSysAdmin || isPubAcctMgr;
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const hasCreativeBlockingMode = brand?.entityType === "Brand" && isInternalOnly;

    const isAffiliateSectionShown: boolean = isTremorUser && !!seatSelfData?.seat?.affiliateCostEnabled;

    if (!brand) {
        return <Loading />;
    }

    return (
        <>
            <SupplyTrafficAlertBanner sourceSelf="Brand" />
            <Form layout="vertical" style={{ width: "100%" }} data-sdet="brand-sections">
                <Collapse defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9]} bordered ghost={false} collapsible="header">
                    <Collapse.Panel
                        key={1}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().performance.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME().performance.label}
                                sectionKey={SECTIONS_NAMES.PERFORMANCE}
                            />
                        }
                    >
                        <Performance
                            id={brand?.id}
                            adStatsEntity={EntityTypes.Brand}
                            snapShotEntity={SnapshotsEntityTypes.Brand}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={2}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().general.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME().general.label}
                                sectionKey={SECTIONS_NAMES.GENERAL}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <General />
                        </Card>
                    </Collapse.Panel>
                    {isAffiliateSectionShown && (
                        <Collapse.Panel
                            key={3}
                            header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().affiliate.label} />}
                            extra={
                                <HelpKeysButton
                                    label={BRAND_DETAILS_SECTIONS_NAME().affiliate.helpLabel}
                                    sectionKey={SECTIONS_NAMES.AFFILIATE}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Affiliate unit={brand} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={4}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().brandSafety.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME().brandSafety.label}
                                sectionKey={SECTIONS_NAMES.BRAND_SAFETY}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <BrandSafety
                                unit={brand}
                                hasBlockedCategories
                                hasBlockedIndustries
                                hasCreativeBlockingMode={hasCreativeBlockingMode}
                            />
                        </Card>
                    </Collapse.Panel>
                    {isInternalOnly && (
                        <Collapse.Panel
                            key={5}
                            header={<SectionTitle title={"Internal (Internal only)"} />}
                            extra={<HelpKeysButton label="Internal" sectionKey={SECTIONS_NAMES.INTERNAL} />}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <InternalSection brand={brand} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={6}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().supplyDetails.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME().supplyDetails.label}
                                sectionKey={SECTIONS_NAMES.SUPPLY_DETAILS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <SupplyDetails brand={brand} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={7}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().pixels.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME().pixels.label}
                                sectionKey={SECTIONS_NAMES.PIXELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Pixels id={brand?.id} thirdPartyPixels={brand?.thirdPartyPixels} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={8}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().labels.label} />}
                        extra={
                            <HelpKeysButton
                                label={BRAND_DETAILS_SECTIONS_NAME(isInternalOnly).labels.label}
                                sectionKey={SECTIONS_NAMES.LABELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Labels
                                labels={brand?.labelValues}
                                isInternalOnly={isInternalOnly}
                                distributionGroupsEnabled={seatSelfData?.seat?.distributionGroupsEnabled}
                                distributionGroupLabelValues={brand?.distributionGroupLabelValues}
                                internalLabelValues={brand?.internalLabelValues}
                            />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={9}
                        header={<SectionTitle title={BRAND_DETAILS_SECTIONS_NAME().floors.label} />}
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <FloorsTable readOnly id={brand?.id} sourceSelf="Brand" hiddenColumns={["actualPrice"]} />
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </>
    );
};
