import { LoadingState } from "@app/core/components";
import { useAnimatedNumber } from "@app/core/utils/useAnimatedNumber";
import { AdSourceStat } from "@app/features/seatAdSources/data";
import { getTagOrNetRevenue } from "@app/features/seatAdSources/data/utils";
import { MetricsCard } from "@rubicon/antd-components";
import { formatNumber } from "@rubicon/utils";
import { Space, Typography } from "antd";
import { FC } from "react";

interface Props {
    adSourceTypeId: number;
    adSourceStat: AdSourceStat | undefined;
    handleExtend: () => void;
    isExtended: boolean;
    hasLoadedAdStat: LoadingState;
}

const noDataItems = [
    {
        title: "Tries",
        value: 0,
    },
    {
        title: "Fills",
        value: 0,
    },
    {
        title: "Impressions",
        value: 0,
    },
    {
        title: "Errors",
        value: 0,
    },
    {
        title: "Fallbacks",
        value: 0,
    },
    {
        title: "Skips",
        value: 0,
    },
    {
        title: "Revenue",
        value: formatNumber.asMoney(0, "USD"),
    },
];

export const AdSourcesPerformanceMetrics: FC<Props> = ({
    adSourceStat,
    adSourceTypeId,
    handleExtend,
    isExtended,
    hasLoadedAdStat,
}) => {
    const tagOrNetRevenue = getTagOrNetRevenue(adSourceStat, adSourceTypeId);

    const tries = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.tries : 0,
        isPlaying: true,
    });

    const fills = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.fills : 0,
        isPlaying: true,
    });

    const impressions = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.impressions : 0,
        isPlaying: true,
    });

    const errors = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.errors : 0,
        isPlaying: true,
    });

    const fallbacks = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.fallbacks : 0,
        isPlaying: true,
    });

    const skips = useAnimatedNumber({
        value: adSourceStat ? adSourceStat?.skips : 0,
        isPlaying: true,
    });

    const tagOrNetRevenueAnimated = useAnimatedNumber({
        value: tagOrNetRevenue.value,
        isPlaying: true,
    });

    const items = [
        {
            title: "Tries",
            value: formatNumber.asWhole(tries),
        },
        {
            title: "Fills",
            value: formatNumber.asWhole(fills),
        },
        {
            title: "Impressions",
            value: formatNumber.asWhole(impressions),
        },
        {
            title: "Errors",
            value: formatNumber.asWhole(errors),
        },
        {
            title: "Fallbacks",
            value: formatNumber.asWhole(fallbacks),
        },
        {
            title: "Skips",
            value: formatNumber.asWhole(skips),
        },
        {
            title: tagOrNetRevenue.name,
            value: formatNumber.asMoney(tagOrNetRevenueAnimated, adSourceStat?.currencyCode || "USD"),
        },
    ];

    const loadedWithoutData = hasLoadedAdStat === "loadedNoData";

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Typography.Text strong>Total Live Stats</Typography.Text>
            <MetricsCard
                items={loadedWithoutData ? noDataItems : items}
                handleExtend={handleExtend}
                isExtended={isExtended}
                isLoading={hasLoadedAdStat === "loading"}
            />
        </Space>
    );
};
