export const CLASSIFICATIONS_LABEL_KEY_INPUT_SDET = "classifications-label-key-input";
export const CLASSIFICATIONS_LABEL_DISTRIBUTION_GROUP_RADIO_GROUP_SDET =
    "classifications-label-distribution-group-radio-group";
export const CLASSIFICATIONS_LABEL_MULTI_INSTANCE_RADIO_GROUP_SDET = "classifications-label-multi-instance-radio-group";
export const CLASSIFICATIONS_LABEL_PREFILTER_RADIO_GROUP_SDET = "classifications-label-prefilter-radio-group";
export const CLASSIFICATIONS_LABEL_LABEL_VISIBILITY_RADIO_GROUP_SDET =
    "classifications-label-label-visibility-radio-group";
export const CLASSIFICATIONS_LABEL_LABEL_VALUES_INPUT_SDET = "classifications-label-label-values-input";

export const KEY_FORM_NAME = "key";
export const DISTRIBUTION_GROUP_FORM_NAME = "isDistributionGroup";
export const MULTI_INSTANCE_FORM_NAME = "multiValue";
export const PREFILTER_FORM_NAME = "enablePrefilter";
export const LABEL_VISIBILITY_FORM_NAME = "isPublic";
export const LABEL_VALUES_FORM_NAME = "labelValues";
export const ADDED_VALUES_FORM_NAME = "deleteLabelValues";
export const I_D_G_VALUE = "idg";

export const CLASSIFICATION_LABEL_FORM_CREATE_INITIAL_VALUES = {
    isDistributionGroup: false,
    multiValue: false,
    enablePrefilter: false,
    isPublic: true,
};

export const CLASSIFICATIONS_LABEL_FORM_ITEMS = {
    KEY: {
        name: KEY_FORM_NAME,
        label: "Label Key",
        rules: [
            { required: true, message: "Label Key is required.", whitespace: true },
            { max: 70, message: "Label Key cannot be longer than 70 characters." },
            {
                validator: (_, value) => {
                    if (/\s/.test(value)) return Promise.reject(new Error("Label Key cannot have any whitespace."));

                    return Promise.resolve();
                },
            },
        ],
    },
    DISTRIBUTION_GROUP: {
        name: DISTRIBUTION_GROUP_FORM_NAME,
        label: "Inventory Distribution Group",
    },
    MULTI_INSTANCE: {
        name: MULTI_INSTANCE_FORM_NAME,
        label: "Multi-Instance",
    },
    PREFILTER: {
        name: PREFILTER_FORM_NAME,
        label: "Waterfall Pre-Filter",
    },
    LABEL_VISIBILITY: {
        name: LABEL_VISIBILITY_FORM_NAME,
        label: "Label Visibility",
    },
    LABEL_VALUES: {
        name: LABEL_VALUES_FORM_NAME,
        label: "Values to Add",
        description:
            'Values should be entered one per line. The Values are just that-the values in the category (e.g. "Blue" or "Green")',
    },
    ADDED_VALUES: {
        name: ADDED_VALUES_FORM_NAME,
        label: "Added Values",
    },
};

export type ClassificationsLabelCreateFormValues = {
    [KEY_FORM_NAME]: string;
    [DISTRIBUTION_GROUP_FORM_NAME]: boolean;
    [MULTI_INSTANCE_FORM_NAME]: boolean;
    [PREFILTER_FORM_NAME]: boolean;
    [LABEL_VISIBILITY_FORM_NAME]: boolean;
    [LABEL_VALUES_FORM_NAME]: string;
};

export type ClassificationsLabelEditFormValues = ClassificationsLabelCreateFormValues & {
    [ADDED_VALUES_FORM_NAME]: number[];
};
