import { FC, ReactNode, useMemo } from "react";
import { formatNumber } from "@rubicon/utils";
import { Dashes } from "../Dashes";
import { Typography } from "antd";
import { useAnimatedNumber } from "@app/core/utils/useAnimatedNumber";

export interface AnimatedNumberCellProps {
    value: number | undefined;
    isMoney?: boolean;
    currencyCode?: string;
    isPercent?: boolean;
    prefix?: ReactNode;
    suffix?: ReactNode;
}

interface _AnimatedNumberCellProps extends AnimatedNumberCellProps {
    value: number;
}

const _AnimatedNumberCell: FC<_AnimatedNumberCellProps> = ({
    currencyCode,
    isMoney,
    isPercent,
    prefix,
    suffix,
    value,
}) => {
    const animatedValue = useAnimatedNumber({
        value,
        isPlaying: true,
    });

    const formattedValue = useMemo(
        () =>
            isPercent
                ? formatNumber.asPercent(value)
                : isMoney
                ? currencyCode
                    ? formatNumber.asMoneyVerbose(animatedValue, currencyCode)
                    : formatNumber.asFixed(animatedValue)
                : formatNumber.asWhole(animatedValue),
        [isPercent, value, isMoney, currencyCode, animatedValue]
    );

    return (
        <Typography.Text data-sdet="animated-number-cell">
            {prefix}
            {formattedValue}
            {suffix}
        </Typography.Text>
    );
};

export const AnimatedNumberCell: FC<AnimatedNumberCellProps> = ({
    value,
    isMoney,
    currencyCode,
    isPercent,
    prefix = null,
    suffix = null,
}) => {
    if (value === undefined || Number.isNaN(value)) {
        return <Dashes />;
    }

    return (
        <_AnimatedNumberCell
            currencyCode={currencyCode}
            isMoney={isMoney}
            isPercent={isPercent}
            prefix={prefix}
            suffix={suffix}
            value={value}
        />
    );
};
