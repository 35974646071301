import { FC } from "react";
import { Collapse, CollapseProps, Typography } from "antd";
import { AdUnit, Brand, BrandSupply, Publisher, Seat } from "@app/core/services";
import { css } from "@emotion/css";
import { BrandSafetySeatCollapsePanel } from "./BrandSafetySeatCollapsePanel";
import { BrandSafetyPublisherCollapsePanel } from "./BrandSafetyPublisherCollapsePanel";
import { BrandSafetyBrandCollapsePanel } from "./BrandSafetyBrandCollapsePanel";
import { BrandSafetySupplyCollapsePanel } from "./BrandSafetySupplyCollapsePanel";
import { BrandSafetyAdUnitCollapsePanel } from "./BrandSafetyAdUnitCollapsePanel";

export const BrandSafetyCollapsePanelKeys = {
    SEAT: "seat",
    PUBLISHER: "publisher",
    BRAND: "brand",
    SUPPLY: "supply",
    AD_UNIT: "adUnit",
} as const;

export type BrandSafetyCollapsePanelKeys =
    (typeof BrandSafetyCollapsePanelKeys)[keyof typeof BrandSafetyCollapsePanelKeys];

interface Props {
    seat: Seat;
    publisher?: Publisher;
    brand?: Brand;
    supply?: BrandSupply;
    adUnit?: AdUnit;
    activeKey?: CollapseProps["activeKey"];
    onChange?: CollapseProps["onChange"];
}

export const BrandSafetyCollapsePanel: FC<Props> = ({
    seat,
    publisher,
    brand,
    supply,
    adUnit,
    activeKey,
    onChange,
}) => {
    return (
        <Collapse
            activeKey={activeKey}
            collapsible="header"
            onChange={onChange}
            className={css`
                .ant-collapse-header {
                    text-overflow: ellipsis;
                    &.ant-collapse-header-collapsible-only {
                        align-items: center;
                    }
                }
            `}
        >
            <BrandSafetySeatCollapsePanel
                seat={seat}
                onChange={onChange}
                key={BrandSafetyCollapsePanelKeys.SEAT}
                header={<Typography.Text strong>Seat: {seat.name}</Typography.Text>}
            />
            {publisher && (
                <BrandSafetyPublisherCollapsePanel
                    onChange={onChange}
                    publisher={publisher}
                    key={BrandSafetyCollapsePanelKeys.PUBLISHER}
                    header={
                        <Typography.Text strong>
                            {publisher.entityType}: {publisher.name}
                        </Typography.Text>
                    }
                />
            )}
            {brand && (
                <BrandSafetyBrandCollapsePanel
                    brand={brand}
                    onChange={onChange}
                    key={BrandSafetyCollapsePanelKeys.BRAND}
                    header={
                        <Typography.Text strong>
                            {brand.entityType}: {brand.name}
                        </Typography.Text>
                    }
                />
            )}
            {supply && (
                <BrandSafetySupplyCollapsePanel
                    supply={supply}
                    onChange={onChange}
                    key={BrandSafetyCollapsePanelKeys.SUPPLY}
                    header={<Typography.Text strong>Supply: {supply.name}</Typography.Text>}
                />
            )}
            {adUnit && (
                <BrandSafetyAdUnitCollapsePanel
                    adUnit={adUnit}
                    onChange={onChange}
                    key={BrandSafetyCollapsePanelKeys.AD_UNIT}
                    header={<Typography.Text strong>Ad Unit: {adUnit.name}</Typography.Text>}
                />
            )}
        </Collapse>
    );
};
