import { useEffect } from "react";
import { Space, Grid, Flex } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { useBrandById } from "@app/features/inventory/InventoryFloors/useBrandById";
import { Loading } from "@app/core/components";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { useGetPublisherByIdQuery } from "@app/core/services";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { useBrandSafetyCollapsePanel } from "../BrandSafetyPublisherPage/useBrandSafetyCollapsePanel";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";
import { ScreensBreakpoint, TreeDrawer } from "@app/features/inventory/InventoryBrandSafety/TreeDrawer";
import { BRAND_SAFETY_GUTTER } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

const { useBreakpoint } = Grid;

export const BrandSafetyBrandPage = () => {
    const screens: ScreensBreakpoint = useBreakpoint();
    const { md } = screens;
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { brand, isFetching: isFetchingBrand } = useBrandById();
    const { data: publisher, isFetching: isFetchingPublisher } = useGetPublisherByIdQuery(
        brand?.publisher.id as number,
        {
            skip: !brand?.publisher.id,
        }
    );
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.BRAND,
    });

    useEffect(() => {
        if (context && brand) {
            expandKeys([`seat-${context.id}`, `publisher-${brand.publisher.id}`, `brand-${brand.id}`]);
            setSelectedKeys([`brand-${brand.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, brand]);

    if (!context || isFetchingPublisher || isFetchingBrand || !brand) {
        return <Loading />;
    }

    const items = [
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY(context.id, INVENTORY_LIST_PAGES.BRAND_SAFETY),
            title: context.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(context.id, brand.publisher.id),
            title: brand.publisher.name,
        },
        { title: brand.name },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex align="baseline" justify={md ? "space-between" : "start"} wrap gap={BRAND_SAFETY_GUTTER}>
                <Flex align="center" gap={BRAND_SAFETY_GUTTER}>
                    {!md && <TreeDrawer />}
                    <InventoryFloorsBreadcrumb items={items} sufix={md ? "Brand Safety:" : undefined} />
                </Flex>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel
                seat={context}
                publisher={publisher}
                brand={brand}
                activeKey={activeKey}
                onChange={onChange}
            />
        </Space>
    );
};
