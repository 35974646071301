import { HistoricalDealReport, useGetHistoricalDealDataQuery } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    selectHistoricalChartDealId,
    selectHistoricalChartAdSource,
    selectHistoricalChartDateRange,
    selectHistoricalChartGroupBy,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";

interface UseHistoricalChartData {
    data?: HistoricalDealReport[];
    isFetching: boolean;
}

export const useGetHistoricalChartData = (): UseHistoricalChartData => {
    const adSourceId = useAppSelector(selectHistoricalChartAdSource);
    const dealId = useAppSelector(selectHistoricalChartDealId);
    const dateRange = useAppSelector(selectHistoricalChartDateRange);
    const groupBy = useAppSelector(selectHistoricalChartGroupBy);
    const currencyId = useCurrencyConversion()?.preferredCurrency?.id ?? 1;
    const { data, isFetching } = useGetHistoricalDealDataQuery(
        { adSourceId, dealId, dateRange, groupBy, currencyId },
        { skip: !adSourceId }
    );

    return {
        data,
        isFetching,
    };
};
