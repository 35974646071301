import { BLOCKED_INDUSTRIES_LABEL, BLOCKED_INDUSTRIES_NAME } from "@app/core/components";

export const BRAND_SAFETY_LABEL_COL = { xs: 24, sm: 24, md: 24, lg: 24, xl: { span: 7 }, xxl: { span: 5 } };
export const BRAND_SAFETY_WRAPPER_COL = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 10 };
export const BRAND_SAFETY_GUTTER = 8;

export const FORM_FIELDS = {
    BLOCKED_ADVERTISER_DOMAIN_LISTS: {
        name: "blockedAdvertiserDomainLists",
        label: "Blocked Advertiser Domain Lists",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "blockedAdvertiserDomains",
        label: "Blocked Advertiser Domains",
    },
    BLOCKED_BUYER_SEAT_LIST: {
        name: "blockedBuyerSeatList",
        label: "Blocked Buyer Seat List",
    },
    ADVERTISER_FREQUENCY_CAPPING_TYPE: {
        name: "adomainFreqCappingType",
        label: "Advertiser Frequency Capping",
    },
    ADVERTISER_FREQUENCY_CAPPING: {
        name: "adomainFreqCappings",
        label: "Advertiser Domain Frequency Caps",
    },
    BLOCKED_IAB_CATEGORIES: {
        name: "blockedIabCategories",
        label: "Blocked Categories",
    },
    BLOCKED_INDUSTRIES: {
        name: BLOCKED_INDUSTRIES_NAME,
        label: BLOCKED_INDUSTRIES_LABEL,
    },
} as const;
