import { DualAxesConfig } from "@ant-design/plots";
import { HistoricalQueriesResults } from "@app/core/services";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { formatNumber } from "@rubicon/utils";
import { LabeledValue } from "antd/lib/select";
import { useEffect, useMemo, useState } from "react";
import {
    CATEGORIES,
    EST_PRICE_OPTIMAL,
    formatter,
    generateSeries,
    getDefaultLegend,
    getEstPriceLabel,
    getLineMax,
    getParsedDataFromApi,
} from "./helpers";
import { LineChartData, LegendDataItem, MapByDay, ColumnChartData, GROUP_COLUMN, GROUP_LINE } from "./types";

interface UseHistoricalFloorAnalysisChart {
    estPriceOptions: LabeledValue[];
    estPriceValue: number;
    handleChangeEstPrice: (value: number) => void;
    config: DualAxesConfig;
    legendData: LegendDataItem[];
    setLegendDataState: (legendData: LegendDataItem[]) => void;
}

export const useHistoricalFloorAnalysisChart = (
    data: HistoricalQueriesResults[] = [],
    isLoading: boolean
): UseHistoricalFloorAnalysisChart => {
    const { currencyConfig } = useCurrencyConversion();

    const [estPriceValue, setValue] = useState<number>(EST_PRICE_OPTIMAL.value);
    const isOptimal = useMemo(() => estPriceValue === EST_PRICE_OPTIMAL.value, [estPriceValue]);

    const [legendDataState, setLegendDataState] = useState<LegendDataItem[] | null>(null);
    let legendData = legendDataState;

    if (legendData === null) {
        legendData = getDefaultLegend(isOptimal, formatNumber.asMoney(estPriceValue, currencyConfig?.code));
    }

    const { estPrice, mapByDay, mapByPotentialFloor } = useMemo(() => {
        const { estPrice, mapByDay, mapByPotentialFloor } = getParsedDataFromApi(data);

        for (const key in mapByPotentialFloor) {
            if (mapByPotentialFloor.hasOwnProperty(key)) {
                mapByPotentialFloor[key].sort((l: MapByDay, r: MapByDay) => l.ts - r.ts);
            }
        }

        //INFO: Get the optimal floor for each day
        for (const day in mapByDay) {
            if (mapByDay.hasOwnProperty(day)) {
                mapByDay[day].sort((l: MapByDay, r: MapByDay) => r.estimatedRevenue - l.estimatedRevenue);

                mapByDay[day] = {
                    optimalFloor: mapByDay[day][0].potentialFloor,
                    ts: mapByDay[day][0].ts,
                    day: day,
                    estimatedRevenue: mapByDay[day][0].estimatedRevenue,
                    recordedAvgFloorPrice: mapByDay[day][0].recordedAvgFloorPrice,
                    recordedRevenue: mapByDay[day][0].recordedRevenue,
                };
            }
        }

        return { estPrice, mapByDay, mapByPotentialFloor };
    }, [data]);

    const { lineSeries, columnSeries } = useMemo(() => {
        const mapData = isOptimal ? mapByDay : mapByPotentialFloor[estPriceValue];
        const isArr = Array.isArray(mapData);
        const normalizedData: MapByDay[] = isArr ? mapData : Object.values(mapData);

        return generateSeries(normalizedData);
    }, [mapByDay, mapByPotentialFloor, estPriceValue, isOptimal]);

    const { filteredLineSeries, filteredColumnSeries } = useMemo(() => {
        const callback = (item: LineChartData | ColumnChartData) =>
            legendData?.find(({ type }) => {
                //INFO: find in columnSeries
                if ("type" in item) {
                    return item.type === type;
                }
                //INFO: find in lineSeries
                if ("name" in item) {
                    return item.name === type;
                }
            })?.checked;

        const filteredLineSeries = lineSeries.filter((item) => callback(item));
        const filteredColumnSeries = columnSeries.filter((item) => callback(item));

        return { filteredLineSeries, filteredColumnSeries };
    }, [lineSeries, columnSeries, legendData]);

    const estPriceOptions = useMemo(
        () =>
            Array.from(estPrice)
                .map((price) => ({
                    label:
                        price === EST_PRICE_OPTIMAL.value
                            ? "Optimal"
                            : `${formatNumber.asMoney(price, currencyConfig?.code)}`,
                    value: price,
                }))
                .sort((a, b) => a.value - b.value),
        [estPrice, currencyConfig]
    );

    const handleChangeEstPrice = (value: number): void => {
        setValue(value);
    };

    const config: DualAxesConfig = useMemo(
        () => ({
            data: [filteredColumnSeries, filteredLineSeries],
            legend: false,
            xField: "time",
            yField: ["value", "count"],
            yAxis: {
                value: {
                    label: {
                        formatter: (v: string) => formatNumber.asMoney(v, currencyConfig?.code),
                    },
                },
                count: {
                    max: getLineMax(filteredLineSeries),
                    min: 0,
                    label: {
                        formatter: (v: string) => formatNumber.asMoney(v, currencyConfig?.code),
                    },
                },
            },
            xAxis: {
                label: {
                    formatter: (v: string) => formatter(Number(v)),
                },
            },
            geometryOptions: [
                {
                    geometry: "column",
                    isGroup: true,
                    groupField: "time",
                    seriesField: "type",
                    minColumnWidth: 11,
                    maxColumnWidth: 100,
                    columnWidthRatio: 0.9,
                    columnStyle: (datum: ColumnChartData) => {
                        if (datum.type === GROUP_COLUMN.COL2) {
                            return {
                                fillOpacity: 0.7,
                            };
                        }
                    },
                    pattern: ({ type }: ColumnChartData) => {
                        if (type === GROUP_COLUMN.COL2) {
                            return {
                                type: "line",
                            };
                        }
                    },
                    color: (v: ColumnChartData, defaultColor: string) => CATEGORIES[v.type].color || defaultColor,
                },
                {
                    geometry: "line",
                    seriesField: "name",
                    lineStyle: ({ name }: LineChartData) => {
                        if (name === GROUP_LINE.LINE1) {
                            return {
                                lineWidth: 3,
                                lineDash: [5, 5],
                            };
                        }
                    },
                    color: (v: LineChartData, defaultColor: string) => CATEGORIES[v.name].color || defaultColor,
                },
            ],
            tooltip: {
                title: (_: string, data: LineChartData | ColumnChartData) => formatter(data.time),
                formatter: (datum: LineChartData | ColumnChartData) => {
                    if ("type" in datum) {
                        return {
                            name:
                                !isOptimal && datum.type === GROUP_COLUMN.COL2
                                    ? "Est Floor Price"
                                    : CATEGORIES[datum.type].label,
                            value: formatNumber.asMoney(datum.value, currencyConfig?.code),
                        };
                    }
                    if ("name" in datum) {
                        return {
                            name: CATEGORIES[datum.name].label,
                            value: formatNumber.asMoney(datum.count, currencyConfig?.code),
                        };
                    }
                    return {
                        name: "No data",
                        value: 0,
                    };
                },
                showMarkers: true,
            },
            loading: isLoading,
        }),
        [filteredLineSeries, filteredColumnSeries, currencyConfig, isLoading, isOptimal]
    );

    useEffect(() => {
        if (legendDataState?.length) {
            setLegendDataState((prev: LegendDataItem[]) =>
                prev?.map(({ label, checked, color, type }) => {
                    const isLineChangeLabel = type === GROUP_COLUMN.COL2;
                    const newLabel =
                        isLineChangeLabel && !isOptimal //When type is col2 and est price is not optimal set the label as Est Floor Price
                            ? getEstPriceLabel(formatNumber.asMoney(estPriceValue, currencyConfig?.code))
                            : isOptimal && isLineChangeLabel //when isOptimal and type is col2 we want to replace old lable with Optimal text
                            ? CATEGORIES[type].label
                            : label; // old label
                    return {
                        label: newLabel,
                        checked,
                        color,
                        type,
                    };
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estPriceValue, isOptimal, currencyConfig]);

    return {
        estPriceValue,
        estPriceOptions,
        handleChangeEstPrice,
        config,
        legendData: lineSeries.length && columnSeries.length && !isLoading ? legendData : [],
        setLegendDataState,
    };
};
