import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetPublisherInheritedPixelsQuery } from "@app/core/services";
import { InheritedCustomPixels } from "@app/features/inventory/components/InheritedCustomPixels";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

export const PublisherInheritedCustomPixels: FC = () => {
    const { mode } = usePublisherFormInstance();
    const { publisherId } = useParams<{ publisherId: string }>();
    const isEditMode: boolean = mode === "edit";

    const { data = [] } = useGetPublisherInheritedPixelsQuery(
        { publisherId: publisherId, includeMine: false },
        { skip: !isEditMode || !publisherId }
    );

    if (!isEditMode) return null;

    return <InheritedCustomPixels thirdPartyPixels={data} />;
};
