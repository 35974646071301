import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { NONE } from "@app/core/components/constants";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { AnimatedCell } from "@app/core/components/AnimatedCell";
import { AdStat, AdStatsById, getFills, getOtimesForThrottleUnit, getTotalFills } from "@app/features/adStats";
import { useCurrencyConversion } from "@app/features/adStats/useCurrencyConversion";
import { PercentageMetric } from "@app/features/inventory/components/PercentageMetric";
import { INVENTORY_HEALTH_FRACTIONS_DIGITS } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/constants";
import { useSeatAuthContext } from "@app/core/auth";
import { CurrencyConversionModes } from "@app/core/clients/console";

interface InventoryHealthInventoryFillProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
    showPercentage?: boolean;
}

export const InventoryHealthInventoryFill: FC<InventoryHealthInventoryFillProps> = ({
    adStats,
    sourceId,
    isConnected,
    showPercentage = true,
}) => {
    const { session } = useSeatAuthContext();
    const adStat: AdStat | null = adStats[sourceId];
    const { preferredCurrency } = useCurrencyConversion();
    const currencyConversionMode = session?.user.userPreferenceMap.currencyConversionMode as CurrencyConversionModes;

    const totalFill: number | undefined = getTotalFills(adStats, preferredCurrency, currencyConversionMode);
    const fill: number | undefined = getFills(adStat, preferredCurrency, currencyConversionMode);
    const otime = getOtimesForThrottleUnit(adStat);
    const throttledTries = useThrottle<number | undefined>(fill || undefined, {
        forceUpdateDependencies: [otime, preferredCurrency, currencyConversionMode],
    });

    if (!isConnected || !adStat) return <LoadingCell />;

    if (throttledTries === undefined) return <>{NONE}</>;

    const percentage: string = ((fill / totalFill) * 100).toFixed(INVENTORY_HEALTH_FRACTIONS_DIGITS);

    return (
        <AnimatedCell
            value={throttledTries}
            suffix={showPercentage ? <PercentageMetric percentage={percentage} /> : undefined}
        />
    );
};
