import { FC } from "react";
import { Card } from "antd";
import { CustomPixels } from "@app/features/inventory/HierarchyForms/AdUnitForm/CustomPixelsSection/Fields";
import { AdUnitInheritedCustomPixels } from "@app/features/inventory/HierarchyForms/AdUnitForm/CustomPixelsSection/Fields/CustomPixels/AdUnitInheritedCustomPixels";

export const CustomPixelsSection: FC = () => (
    <Card bordered={false}>
        <CustomPixels />
        <AdUnitInheritedCustomPixels />
    </Card>
);
