import {
    AdUnitsApiFramework,
    AdUnitsMime,
    Category,
    ContentCategory,
    ContentMetadataContentLength,
    ContentMetadataTitles,
    ContentMetadataTvSeries,
    LabelValue,
    OperatingSystem,
    OztamDemo,
    Platform,
    Size,
    SupplyType,
    TargetingCreateIncludeExclude,
    TargetingCreatePayload,
    TargetingUpdatePayload,
} from "@app/core/services/console";
import { TargetingDimensionTypes, DEFAULT_SUPPLY_TYPE, DEFAULT_PMP_EXCLUDE_TARGETING_VALUE } from "../constants";
import { DayPartTargetsTargetingDimension, TargetingBlock, TargetingStub } from "../types";
import { getInventoryDimensionsByEntityType } from "./inventory";
import {
    DimensionValuesByConditionByType,
    DimensionsByConditionByType,
    DimensionValuesIncludesExcludes,
} from "./types";
import { updateTargetingPayloadFromSegmentRuleGroup } from "./segment";
import { convertCustomRuleGroupToCustomRules, parseTvSeriesToApi } from "./helpers";
import { LabeledValue } from "antd/lib/select";
import { filterIncludeExclude, TargetingIncludeExclude } from "./targetingPayloadFilter";

const getDimensionValuesIncludesExcludesDefault = (): DimensionValuesIncludesExcludes => ({
    [TargetingDimensionTypes.AdBreakPositionTargets]: [],
    [TargetingDimensionTypes.Audiences]: null,
    [TargetingDimensionTypes.ApiFrameworks]: [],
    [TargetingDimensionTypes.BundleIdTargets]: [],
    [TargetingDimensionTypes.Bvod]: [],
    [TargetingDimensionTypes.Categories]: [],
    [TargetingDimensionTypes.ContentCategories]: [],
    [TargetingDimensionTypes.ContentChannels]: [],
    [TargetingDimensionTypes.ContentLengths]: [],
    [TargetingDimensionTypes.ContentNetworks]: [],
    [TargetingDimensionTypes.ContentRatings]: [],
    [TargetingDimensionTypes.ContentSeries]: [],
    [TargetingDimensionTypes.Coppa]: [],
    [TargetingDimensionTypes.CustomRules]: null,
    [TargetingDimensionTypes.CustomTargets]: [],
    [TargetingDimensionTypes.DayPartTargets]: [],
    [TargetingDimensionTypes.Dnt]: [],
    [TargetingDimensionTypes.Genres]: [],
    [TargetingDimensionTypes.GeoTargets]: [],
    [TargetingDimensionTypes.Inventory]: [],
    [TargetingDimensionTypes.LabelValues]: [],
    [TargetingDimensionTypes.LiveStream]: [],
    [TargetingDimensionTypes.MaxDurationTarget]: null,
    [TargetingDimensionTypes.MimeTypes]: [],
    [TargetingDimensionTypes.MinDurationTarget]: null,
    [TargetingDimensionTypes.OperatingSystems]: [],
    [TargetingDimensionTypes.Platforms]: [],
    [TargetingDimensionTypes.Pmp]: [],
    [TargetingDimensionTypes.PodSlotPositionTargets]: [],
    [TargetingDimensionTypes.Producers]: [],
    [TargetingDimensionTypes.SegmentRules]: null,
    [TargetingDimensionTypes.Segments]: [],
    [TargetingDimensionTypes.Sizes]: [],
    [TargetingDimensionTypes.SupplyDomainTargets]: [],
    [TargetingDimensionTypes.SupplyTypes]: [],
    [TargetingDimensionTypes.TimeZoneMode]: null,
    [TargetingDimensionTypes.VideoIds]: [],
});

export const getTargetingStub = (id: number, name: string): TargetingStub => ({
    id: id,
    name: name,
    include: null,
    exclude: null,
    seat: null,
    marketplace: null,
    isStub: true,
});

type timeZone = { creationDate: string; updateDate: string; id: string; name: string };

const parseLabeledValueTo = <T>(labeledValue: LabeledValue): T => JSON.parse(String(labeledValue.value)) as T;

export const targetingBlockToTargetingPayload = (
    targetingBlock: TargetingBlock
): TargetingCreatePayload | TargetingUpdatePayload => {
    const targetingPayload = targetingBlock.id
        ? ({
              id: targetingBlock.id,
              name: targetingBlock.name || null,
              include: {},
              exclude: {},
          } as TargetingUpdatePayload)
        : ({
              include: {},
              exclude: {},
          } as TargetingCreatePayload);

    const dimensionValuesByConditionByType = targetingBlock.dimensions.reduce<DimensionValuesByConditionByType>(
        (acc, dimension) => {
            if (dimension.type === TargetingDimensionTypes.CustomRules) {
                acc[dimension.condition][dimension.type] = dimension.ruleGroup;
            } else if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                acc[dimension.condition][dimension.type] = dimension.ruleGroup;
                acc[dimension.condition].segments = dimension.values.map((segment) => String(segment.value));
            } else {
                // TODO: dimension.values is heterogeneous and requires that we create separate blocks
                // for all the possibly null values. ts-ignoring this until we can come up with something more elegant
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc[dimension.condition][dimension.type] = dimension.values;
            }
            return acc;
        },
        {
            includes: getDimensionValuesIncludesExcludesDefault(),
            excludes: getDimensionValuesIncludesExcludesDefault(),
        }
    );

    const dimensionByConditionByType = targetingBlock.dimensions.reduce<DimensionsByConditionByType>(
        (acc, dimension) => {
            if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                acc[dimension.condition][TargetingDimensionTypes.SegmentRules] = dimension;
            } else {
                acc[dimension.condition][dimension.type] = dimension;
            }
            return acc;
        },
        { includes: {}, excludes: {} }
    );

    // We can only includes audiences for now
    if (targetingBlock.usedDimensions.audiences.includes) {
        // Payload only cares about audienceIds, but we include audiences here to make the UI representation easier
        targetingPayload.include.audiences = dimensionValuesByConditionByType.includes.audiences
            ? [dimensionValuesByConditionByType.includes.audiences]
            : [];
        targetingPayload.include.audienceIds = dimensionValuesByConditionByType.includes.audiences
            ? [dimensionValuesByConditionByType.includes.audiences.id]
            : [];
    }

    if (targetingBlock.usedDimensions.customRules.includes) {
        targetingPayload.include.customRules = convertCustomRuleGroupToCustomRules(
            dimensionValuesByConditionByType.includes.customRules
        );
    }

    if (targetingBlock.usedDimensions.customRules.excludes) {
        targetingPayload.exclude.customRules = convertCustomRuleGroupToCustomRules(
            dimensionValuesByConditionByType.excludes.customRules
        );
    }

    if (targetingBlock.usedDimensions.segmentRules.includes) {
        updateTargetingPayloadFromSegmentRuleGroup(
            "includes",
            targetingPayload,
            dimensionByConditionByType,
            dimensionValuesByConditionByType
        );
    }

    if (targetingBlock.usedDimensions.segmentRules.excludes) {
        updateTargetingPayloadFromSegmentRuleGroup(
            "excludes",
            targetingPayload,
            dimensionByConditionByType,
            dimensionValuesByConditionByType
        );
    }

    if (targetingBlock.usedDimensions.adBreakPositionTargets.includes) {
        targetingPayload.include.adBreakPositionTargets =
            dimensionValuesByConditionByType.includes.adBreakPositionTargets.map((v) => Number(v));
    }

    if (targetingBlock.usedDimensions.adBreakPositionTargets.excludes) {
        targetingPayload.exclude.adBreakPositionTargets =
            dimensionValuesByConditionByType.excludes.adBreakPositionTargets.map((v) => Number(v));
    }

    if (targetingBlock.usedDimensions.podSlotPositionTargets.includes) {
        targetingPayload.include.podSlotPositionTargets =
            dimensionValuesByConditionByType.includes.podSlotPositionTargets.map((v) => Number(v));
    }

    if (targetingBlock.usedDimensions.podSlotPositionTargets.excludes) {
        targetingPayload.exclude.podSlotPositionTargets =
            dimensionValuesByConditionByType.excludes.podSlotPositionTargets.map((v) => Number(v));
    }

    if (targetingBlock.usedDimensions.geoTargets.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.geoTargets;
        targetingPayload.include.geoTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.geoTargets.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.geoTargets;
        targetingPayload.exclude.geoTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.platforms.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.platforms;
        targetingPayload.include.platforms = dimensionValues.map((value) => parseLabeledValueTo<Platform>(value));
    }

    if (targetingBlock.usedDimensions.platforms.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.platforms;
        targetingPayload.exclude.platforms = dimensionValues.map((value) => parseLabeledValueTo<Platform>(value));
    }

    if (targetingBlock.usedDimensions.sizes.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.sizes;
        targetingPayload.include.sizes = dimensionValues.map((value) => parseLabeledValueTo<Size>(value));
    }

    if (targetingBlock.usedDimensions.sizes.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.sizes;
        targetingPayload.exclude.sizes = dimensionValues.map((value) => parseLabeledValueTo<Size>(value));
    }

    if (targetingBlock.usedDimensions.apiFrameworks.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.apiFrameworks;
        targetingPayload.include.apiFrameworks = dimensionValues.map((value) =>
            parseLabeledValueTo<AdUnitsApiFramework>(value)
        );
    }

    if (targetingBlock.usedDimensions.apiFrameworks.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.apiFrameworks;
        targetingPayload.exclude.apiFrameworks = dimensionValues.map((value) =>
            parseLabeledValueTo<AdUnitsApiFramework>(value)
        );
    }

    if (targetingBlock.usedDimensions.supplyDomainTargets.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.supplyDomainTargets;
        targetingPayload.include.supplyDomainTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.supplyDomainTargets.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.supplyDomainTargets;
        targetingPayload.exclude.supplyDomainTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.supplyTypes.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.supplyTypes;
        const [value] = dimensionValues;

        // Only set supplyTypes if the value is not the default value
        if (value && value.value !== DEFAULT_SUPPLY_TYPE.value) {
            targetingPayload.include.supplyTypes = dimensionValues.map((value) =>
                parseLabeledValueTo<SupplyType>(value)
            );
        }
    }

    if (targetingBlock.usedDimensions.bundleIdTargets.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.bundleIdTargets;
        targetingPayload.include.bundleIdTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.bundleIdTargets.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.bundleIdTargets;
        targetingPayload.exclude.bundleIdTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.minDurationTarget.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.minDurationTarget;
        targetingPayload.include.minDurationTarget = dimensionValues;
    }

    if (targetingBlock.usedDimensions.minDurationTarget.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.minDurationTarget;
        targetingPayload.exclude.minDurationTarget = dimensionValues;
    }

    if (targetingBlock.usedDimensions.maxDurationTarget.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.maxDurationTarget;
        targetingPayload.include.maxDurationTarget = dimensionValues;
    }

    if (targetingBlock.usedDimensions.maxDurationTarget.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.maxDurationTarget;
        targetingPayload.exclude.maxDurationTarget = dimensionValues;
    }

    if (targetingBlock.usedDimensions.coppa.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.coppa;
        targetingPayload.include.coppa = dimensionValues;
    }

    if (targetingBlock.usedDimensions.coppa.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.coppa;
        targetingPayload.exclude.coppa = dimensionValues;
    }

    if (targetingBlock.usedDimensions.dnt.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.dnt;
        targetingPayload.include.dnt = dimensionValues;
    }

    if (targetingBlock.usedDimensions.dnt.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.dnt;
        targetingPayload.exclude.dnt = dimensionValues;
    }

    if (targetingBlock.usedDimensions.labelValues.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.labelValues;
        targetingPayload.include.labelValues = dimensionValues.map((value) => parseLabeledValueTo<LabelValue>(value));
    }

    if (targetingBlock.usedDimensions.labelValues.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.labelValues;
        targetingPayload.exclude.labelValues = dimensionValues.map((value) => parseLabeledValueTo<LabelValue>(value));
    }

    if (targetingBlock.usedDimensions.liveStream.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.liveStream;
        targetingPayload.include.liveStream = dimensionValues;
    }

    if (targetingBlock.usedDimensions.liveStream.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.liveStream;
        targetingPayload.exclude.liveStream = dimensionValues;
    }

    if (targetingBlock.usedDimensions.pmpDealIdTargets.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.pmpDealIdTargets;
        targetingPayload.include.pmpDealIdTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.pmpDealIdTargets.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.pmpDealIdTargets;
        targetingPayload.exclude.pmpDealIdTargets = dimensionValues;
    }

    if (
        !targetingBlock.usedDimensions.pmpDealIdTargets.includes &&
        !targetingBlock.usedDimensions.pmpDealIdTargets.excludes
    ) {
        // default pmpDealIdTargets to "exclude all"
        targetingPayload.exclude.pmpDealIdTargets = DEFAULT_PMP_EXCLUDE_TARGETING_VALUE;
    }

    if (targetingBlock.usedDimensions.oztamDemographics.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.oztamDemographics;
        targetingPayload.include.oztamDemographics = dimensionValues.map((value) =>
            parseLabeledValueTo<OztamDemo>(value)
        );
    }

    if (targetingBlock.usedDimensions.oztamDemographics.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.oztamDemographics;
        targetingPayload.exclude.oztamDemographics = dimensionValues.map((value) =>
            parseLabeledValueTo<OztamDemo>(value)
        );
    }

    if (targetingBlock.usedDimensions.customTargets.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.customTargets;
        targetingPayload.include.customTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.customTargets.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.customTargets;
        targetingPayload.exclude.customTargets = dimensionValues;
    }

    if (targetingBlock.usedDimensions.inventory.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.inventory;
        const { publishers, supply, brands, adUnits } = getInventoryDimensionsByEntityType(dimensionValues);
        targetingPayload.include.publishers = publishers;
        targetingPayload.include.supply = supply;
        targetingPayload.include.brands = brands;
        targetingPayload.include.adUnits = adUnits;
    }

    if (targetingBlock.usedDimensions.inventory.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.inventory;
        const { publishers, supply, brands, adUnits } = getInventoryDimensionsByEntityType(dimensionValues);
        targetingPayload.exclude.publishers = publishers;
        targetingPayload.exclude.supply = supply;
        targetingPayload.exclude.brands = brands;
        targetingPayload.exclude.adUnits = adUnits;
    }

    if (targetingBlock.usedDimensions.contentChannels.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentChannels;
        targetingPayload.include.contentChannels = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentChannels.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentChannels;
        targetingPayload.exclude.contentChannels = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentLengths.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentLengths;
        targetingPayload.include.contentLengths = dimensionValues.map((value) =>
            parseLabeledValueTo<ContentMetadataContentLength>(value)
        );
    }

    if (targetingBlock.usedDimensions.contentLengths.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentLengths;
        targetingPayload.exclude.contentLengths = dimensionValues.map((value) =>
            parseLabeledValueTo<ContentMetadataContentLength>(value)
        );
    }

    if (targetingBlock.usedDimensions.contentNetworks.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentNetworks;
        targetingPayload.include.contentNetworks = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentNetworks.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentNetworks;
        targetingPayload.exclude.contentNetworks = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentRatings.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentRatings;
        targetingPayload.include.contentRatings = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentRatings.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentRatings;
        targetingPayload.exclude.contentRatings = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.contentSeries.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentSeries;
        targetingPayload.include.contentSeries = dimensionValues.map((value) =>
            parseTvSeriesToApi(parseLabeledValueTo<ContentMetadataTvSeries>(value))
        );
    }

    if (targetingBlock.usedDimensions.contentSeries.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentSeries;
        targetingPayload.exclude.contentSeries = dimensionValues.map((value) =>
            parseTvSeriesToApi(parseLabeledValueTo<ContentMetadataTvSeries>(value))
        );
    }

    if (targetingBlock.usedDimensions.genres.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.genres;
        targetingPayload.include.genres = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.genres.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.genres;
        targetingPayload.exclude.genres = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.producers.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.producers;
        targetingPayload.include.producers = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.producers.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.producers;
        targetingPayload.exclude.producers = dimensionValues.map((value) => String(value.value));
    }

    if (targetingBlock.usedDimensions.videoIds.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.videoIds;
        targetingPayload.include.videoIds = dimensionValues
            .map((value) => parseLabeledValueTo<ContentMetadataTitles>(value))
            .map((value) => ({
                seatId: value.seatId,
                videoId: value.videoId,
                videoTitle: value.videoTitle,
            }));
    }

    if (targetingBlock.usedDimensions.videoIds.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.videoIds;
        targetingPayload.exclude.videoIds = dimensionValues
            .map((value) => parseLabeledValueTo<ContentMetadataTitles>(value))
            .map((value) => ({
                seatId: value.seatId,
                videoId: value.videoId,
                videoTitle: value.videoTitle,
            }));
    }

    if (targetingBlock.usedDimensions.mimeTypes.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.mimeTypes;
        targetingPayload.include.mimeTypes = dimensionValues.map((value) => parseLabeledValueTo<AdUnitsMime>(value));
    }

    if (targetingBlock.usedDimensions.mimeTypes.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.mimeTypes;
        targetingPayload.exclude.mimeTypes = dimensionValues.map((value) => parseLabeledValueTo<AdUnitsMime>(value));
    }

    if (targetingBlock.usedDimensions.contentCategories.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.contentCategories;
        targetingPayload.include.contentCategories = dimensionValues.map((value) =>
            parseLabeledValueTo<ContentCategory>(value)
        );
    }

    if (targetingBlock.usedDimensions.contentCategories.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.contentCategories;
        targetingPayload.exclude.contentCategories = dimensionValues.map((value) =>
            parseLabeledValueTo<ContentCategory>(value)
        );
    }

    if (targetingBlock.usedDimensions.categories.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.categories;
        targetingPayload.include.categories = dimensionValues.map((value) => parseLabeledValueTo<Category>(value));
    }

    if (targetingBlock.usedDimensions.categories.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.categories;
        targetingPayload.exclude.categories = dimensionValues.map((value) => parseLabeledValueTo<Category>(value));
    }

    if (targetingBlock.usedDimensions.dayPartTargets.includes) {
        if (!targetingPayload.include.timeZoneMode) {
            targetingPayload.include.timeZoneMode = {} as timeZone;
        }

        targetingPayload.include.dayPartTargets = dimensionValuesByConditionByType.includes.dayPartTargets;

        targetingPayload.include.timeZoneMode.id = (
            dimensionByConditionByType.includes.dayPartTargets as DayPartTargetsTargetingDimension
        ).timeZoneMode;
    }

    if (targetingBlock.usedDimensions.dayPartTargets.excludes) {
        if (!targetingPayload.exclude.timeZoneMode) {
            targetingPayload.exclude.timeZoneMode = {} as timeZone;
        }

        targetingPayload.exclude.dayPartTargets = dimensionValuesByConditionByType.excludes.dayPartTargets;

        targetingPayload.exclude.timeZoneMode.id = (
            dimensionByConditionByType.excludes.dayPartTargets as DayPartTargetsTargetingDimension
        ).timeZoneMode;
    }

    if (targetingBlock.usedDimensions.operatingSystems.includes) {
        const dimensionValues = dimensionValuesByConditionByType.includes.operatingSystems;
        targetingPayload.include.operatingSystems = dimensionValues.map((value) =>
            parseLabeledValueTo<OperatingSystem>(value)
        );
    }

    if (targetingBlock.usedDimensions.operatingSystems.excludes) {
        const dimensionValues = dimensionValuesByConditionByType.excludes.operatingSystems;
        targetingPayload.exclude.operatingSystems = dimensionValues.map((value) =>
            parseLabeledValueTo<OperatingSystem>(value)
        );
    }

    return targetingPayload;
};

export const targetingOmitEmpty = (
    targeting: TargetingCreatePayload[] | TargetingUpdatePayload[]
): TargetingCreatePayload[] | TargetingUpdatePayload[] => {
    //HACK: pretend that targeting type is TargetingUpdatePayload because in TargetingCreatePayload we don't have id field
    return (targeting as TargetingUpdatePayload[]).reduce((acc, { exclude, include, name, id }) => {
        if (name) {
            const value = { exclude, include, name };
            if (id) {
                Object.assign(value, { id });
            }
            acc.push(value as TargetingUpdatePayload);
            return acc;
        }

        //INFO: Sorting needs to place dayPartTargets before timeZoneMode
        const excludeKeys = Object.keys(exclude).sort();
        const includeKeys = Object.keys(include).sort();

        //INFO: check blank targeting block for multiple targeting purposes
        if (!includeKeys.length && excludeKeys.length === 1 && exclude[excludeKeys[0]].includes("*")) {
            return acc;
        }

        const filteredInclude: TargetingCreateIncludeExclude = filterIncludeExclude(
            TargetingIncludeExclude.Include,
            includeKeys,
            include
        );
        const filteredExclude: TargetingCreateIncludeExclude = filterIncludeExclude(
            TargetingIncludeExclude.Exclude,
            excludeKeys,
            exclude
        );

        const hasDimensions = Object.keys(filteredInclude).length || Object.keys(filteredExclude).length;
        //INFO: when no include/exclude values we should omit this targeting block
        if (!hasDimensions) {
            return acc;
        }

        const filteredTargeting = { name, exclude: filteredExclude, include: filteredInclude };
        if (id) {
            Object.assign(filteredTargeting, { id });
        }
        acc.push(filteredTargeting as TargetingUpdatePayload);

        return acc;
    }, [] as TargetingCreatePayload[] | TargetingUpdatePayload[]);
};
