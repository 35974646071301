import { createContext, FC, useState } from "react";
import { Form, FormInstance } from "antd";
import { LabeledValue } from "antd/es/select";
import { format } from "@rubicon/utils";
import { LoadingMask } from "@app/core/components";
import {
    AdSourceTermsSection,
    DeliverySection,
    PricingSection,
    PacingSection,
    DemandSection,
    BrandSafetySection,
    CustomPixelsSection,
    TransparencySection,
    LabelsSection,
    TargetingSection,
    PbsBidderConfiguraionsSection,
    EnrichmentCostSection,
    FormPacingDeliverySchedule,
} from "./AdSourcesSections";
import { Mode, useAdSourceForm } from "./useAdSourceForm";
import { AdSourcesFooter } from "./AdSourcesFooter";
import { AD_SOURCE_FIELDS } from "../constants";
import { DealDrawer, RunDateMode } from "./DealFormDrawer";
import { AdSource, AdSourcePayload, CuratorMarketplace, MarketplaceInfo, Targeting } from "@app/core/services";
import { getInitialFormValues } from "./utils/initialFormValues";
import { MarketplaceInfoDrawerMode, useMarketplaceInfoWidget } from "./useMarketplaceInfoWidget";
import moment from "moment-timezone";
import { AdSourceSyncWarningAlert } from "./sync";
import { useSeatAuthContext } from "@app/core/auth";

export interface AdSourcesForm {
    //AD SOURCE TERMS FILEDS
    [AD_SOURCE_FIELDS.TYPE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.TYPE.name];
    [AD_SOURCE_FIELDS.NAME.name]: string;
    [AD_SOURCE_FIELDS.DESCRIPTION.name]: string;
    [AD_SOURCE_FIELDS.PRIORITY.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PRIORITY.name];
    [AD_SOURCE_FIELDS.UD_PRIORITY.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.UD_PRIORITY.name];
    [AD_SOURCE_FIELDS.ASSIGNEE.name]: Partial<AdSourcePayload[typeof AD_SOURCE_FIELDS.ASSIGNEE.name]>;
    [AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name]: Partial<
        AdSourcePayload[typeof AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name]
    >;
    [AD_SOURCE_FIELDS.STATUS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.STATUS.name];
    [AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name]: CuratorMarketplace[];
    [AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name];
    [AD_SOURCE_FIELDS.TAG_URL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.TAG_URL.name];
    [AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name];
    [AD_SOURCE_FIELDS.COST_MODEL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.COST_MODEL.name];
    [AD_SOURCE_FIELDS.COST_PERCENT.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.COST_PERCENT.name];
    [AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name];
    [AD_SOURCE_FIELDS.REGION.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.REGION.name];
    [AD_SOURCE_FIELDS.SALES_CONTACT.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.SALES_CONTACT.name];
    [AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name];
    //DELIVERY FIELDS
    [AD_SOURCE_FIELDS.START_DATE.name]: moment.Moment;
    [AD_SOURCE_FIELDS.END_DATE.name]: moment.Moment | null;
    [AD_SOURCE_FIELDS.TIME_ZONE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.TIME_ZONE.name];
    [AD_SOURCE_FIELDS.MIN_DURATION.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.MIN_DURATION.name];
    [AD_SOURCE_FIELDS.MAX_DURATION.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.MAX_DURATION.name];
    [AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name];
    [AD_SOURCE_FIELDS.SHARE_OF_VOICE.name]: boolean;
    [AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name];
    [AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name];
    //PRICING SECTION FIELDS
    [AD_SOURCE_FIELDS.FIXED_CPM.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.FIXED_CPM.name];
    [AD_SOURCE_FIELDS.CURRENCY.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.CURRENCY.name];
    [AD_SOURCE_FIELDS.PRICE_MODEL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PRICE_MODEL.name];
    [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name];
    [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.FLOOR_TYPE.name];
    [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.AUCTION_TYPE.name];
    [AD_SOURCE_FIELDS.BOOKING_BUDGET.name]: number;
    [AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.name];
    [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]: number;
    [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name];
    [AD_SOURCE_FIELDS.PACING_PERIOD.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PACING_PERIOD.name] | null;
    [AD_SOURCE_FIELDS.PACING_TYPE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PACING_TYPE.name] | null;
    [AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name];
    [AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name]: FormPacingDeliverySchedule[];
    [AD_SOURCE_FIELDS.TARGET_DATE.name]: moment.Moment | null;
    [AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name]: number | null;
    [AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.name]: boolean;
    [AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP.name];
    [AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name]:
        | AdSourcePayload[typeof AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name]
        | null;
    [AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name];
    [AD_SOURCE_FIELDS.PIXELS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PIXELS.name];
    [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.SUPPLY_NAME.name];
    [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.DOMAIN_NAME.name] | "override";
    [AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name];
    [AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name];
    [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.SUPPLY_PAGE.name];
    [AD_SOURCE_FIELDS.REFER_URL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.REFER_URL.name];
    [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name];
    [AD_SOURCE_FIELDS.BUNDLE_ID.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.BUNDLE_ID.name] | "override";
    [AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name];
    [AD_SOURCE_FIELDS.STORE_URL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.STORE_URL.name];
    [AD_SOURCE_FIELDS.USER_ID.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.USER_ID.name] | "masked";
    [AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name];
    [AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name];
    [AD_SOURCE_FIELDS.CONTENT.name]: AdSourcePayload["contentTransparencyRule"] | { name: string; value: boolean };
    [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]:
        | AdSourcePayload[typeof AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]
        | "specific";
    [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.EXTENDED_USER_ID.name];
    [AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name];
    [AD_SOURCE_FIELDS.LABEL.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.LABEL.name];
    [AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name];
    [AD_SOURCE_FIELDS.DEMAND.name]: MarketplaceInfo[];
    [AD_SOURCE_FIELDS.MODE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.MODE.name];
    [AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]:
        | AdSourcePayload[typeof AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]
        | undefined;
    [AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]: string;
    [AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name];
    [AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name];
    [AD_SOURCE_FIELDS.TARGETING_MODE.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.TARGETING_MODE.name];
    [AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name];
    [AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name];
    [AD_SOURCE_FIELDS.BLOCKED_INDUSTRIES.name]: LabeledValue[] | null;
    [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name]:
        | AdSourcePayload[typeof AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name]
        | string;
    [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name];
    [AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name];
    [AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES
        .name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name];
    [AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]: LabeledValue[];
    [AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name]: AdSourcePayload[typeof AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name];
}

export interface DealInForm extends Omit<MarketplaceInfo["deal"], "startTime" | "endTime"> {
    startTime: moment.Moment;
    endTime: moment.Moment | null;
}

export interface DealObjectForm {
    dealOrigin: DealInForm;
    dealUnderEdit: DealInForm;
}

export interface DemandConfigurationForm {
    currencyType: MarketplaceInfo["currencyType"];
    enforcement: MarketplaceInfo["enforcement"];
    freqCappingType: MarketplaceInfo["freqCappingType"];
    dealFreqCappings: MarketplaceInfo["dealFreqCappings"];
    buyerSeats: MarketplaceInfo["buyerSeats"];
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    network: MarketplaceInfo["network"];
    targeting: Targeting[];
    runDateMode: RunDateMode;
}

export type AdSourceFormMode = "create" | "edit" | "copy";

interface Props {
    mode?: AdSourceFormMode;
    adSource?: AdSource;
}

export interface AdSourceForms {
    forms: {
        adSourceForm: FormInstance<AdSourcesForm>;
        dealObjectForm: FormInstance<DealObjectForm>;
        demandConfigurationForm: FormInstance<DemandConfigurationForm>;
        adSourceFormMode: AdSourceFormMode;
        adSource?: AdSource | undefined;
    };
}

interface MarketplaceInfoState {
    marketplaceInfoModeState: [MarketplaceInfoDrawerMode, (mode: MarketplaceInfoDrawerMode) => void]; //can be moved to the separate context
    dealFetchingState: [boolean, (mode: boolean) => void];
    dealObjectModeState: [Mode, (mode: Mode) => void];
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AdSourceFormsContext = createContext<AdSourceForms>(null);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const MarketplaceInfoContext = createContext<MarketplaceInfoState>(null);

const SeatAdSourcesFormContent: FC<Props> = ({ adSource, mode }) => {
    const {
        adSourceForm,
        handleCancel,
        handleSubmit,
        isSubmitting,
        handleDelete,
        isDeleting,
        openDeleteModal,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        openSyncCancelWarningModal,
        handleConfirmSyncCancelWarning,
        handleCancelSyncCancelWarning,
        isSyncingAdSourceChangesToDeal,
    } = useAdSourceForm({
        adSourceOrigin: adSource,
    });
    const { submitMarketplaceInfo } = useMarketplaceInfoWidget();
    const { context } = useSeatAuthContext();

    const initialFormValues = getInitialFormValues(mode, context);

    return (
        <div data-sdet="seat-ad-sources-form">
            <Form.Provider onFormFinish={submitMarketplaceInfo}>
                <Form<AdSourcesForm>
                    form={adSourceForm}
                    layout="horizontal"
                    initialValues={initialFormValues}
                    name="adSourceForm"
                >
                    <AdSourceSyncWarningAlert />
                    <AdSourceTermsSection />
                    <DeliverySection mode={mode} />
                    <PricingSection />
                    <PacingSection />
                    <DemandSection />
                    <PbsBidderConfiguraionsSection />
                    <BrandSafetySection />
                    <CustomPixelsSection />
                    <TransparencySection />
                    <LabelsSection />
                    <TargetingSection />
                    <EnrichmentCostSection />
                    <AdSourcesFooter
                        handleSubmit={handleSubmit}
                        adSourceName={format.asSelf(adSourceForm.getFieldValue(AD_SOURCE_FIELDS.NAME.name))}
                        handleCancel={handleCancel}
                        handleCloseDeleteModal={handleCloseDeleteModal}
                        handleDelete={handleDelete}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        isDeleting={isDeleting}
                        isSubmitting={isSubmitting}
                        openDeleteModal={openDeleteModal}
                        openSyncCancelWarningModal={openSyncCancelWarningModal}
                        handleConfirmSyncCancelWarning={handleConfirmSyncCancelWarning}
                        handleCancelSyncCancelWarning={handleCancelSyncCancelWarning}
                        mode={mode}
                    />
                </Form>
                <DealDrawer adSourceName={format.asSelf(adSourceForm.getFieldValue(AD_SOURCE_FIELDS.NAME.name))} />
                {isSyncingAdSourceChangesToDeal && <LoadingMask />}
            </Form.Provider>
        </div>
    );
};

export const SeatAdSourcesForm: FC<Props> = ({ mode = "create", ...props }) => {
    const [adSourceForm] = Form.useForm<AdSourcesForm>();
    const [dealObjectForm] = Form.useForm<DealObjectForm>();
    const [demandConfigurationForm] = Form.useForm<DemandConfigurationForm>();
    const marketplaceInfoModeState = useState<MarketplaceInfoDrawerMode>({ name: "closed" });
    const dealFetchingState = useState<boolean>(false);
    const dealObjectModeState = useState<Mode>("view");

    return (
        <AdSourceFormsContext.Provider
            value={{
                forms: {
                    adSourceForm,
                    dealObjectForm,
                    demandConfigurationForm,
                    adSourceFormMode: mode,
                    adSource: props?.adSource,
                },
            }}
        >
            <MarketplaceInfoContext.Provider
                value={{ marketplaceInfoModeState, dealObjectModeState, dealFetchingState }}
            >
                <SeatAdSourcesFormContent mode={mode} {...props} />
            </MarketplaceInfoContext.Provider>
        </AdSourceFormsContext.Provider>
    );
};
