import React, { FC } from "react";
import { Collapse, Form, Tag } from "antd";
import { ThirdPartyPixel } from "@app/core/services";
import { INHERITED_SECTION_CLASSLIST } from "@app/features/inventory/HierarchyForms/constants";

interface InheritedCustomPixelsProps {
    thirdPartyPixels: ThirdPartyPixel[];
}

export const getSourcePixelLabel = (pixel: ThirdPartyPixel): string => {
    const source: string[] | undefined = pixel?.sourceClassName?.split(".");
    return `${pixel?.name} [ ${pixel?.type?.name} ] [ Source: ${source?.[source?.length - 1]} ]`;
};

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };

export const InheritedCustomPixels: FC<InheritedCustomPixelsProps> = ({ thirdPartyPixels }) => {
    if (!thirdPartyPixels?.length) return null;

    return (
        <Collapse
            ghost
            expandIconPosition="end"
            data-sdet="inherited-custom-pixels"
            className={INHERITED_SECTION_CLASSLIST}
            items={[
                {
                    key: "0",
                    label: "View Inherited Custom Pixels",
                    children: (
                        <Form.Item
                            wrapperCol={COL_SIZES}
                            style={{ marginTop: "1.5rem" }}
                            label="Inherited Custom Pixels"
                        >
                            {thirdPartyPixels.map((pixel) => {
                                return (
                                    <Tag key={pixel.id} color="processing">
                                        {getSourcePixelLabel(pixel)}
                                    </Tag>
                                );
                            })}
                        </Form.Item>
                    ),
                },
            ]}
        />
    );
};
