import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetPublisherInheritedPixelsQuery } from "@app/core/services";
import { InheritedCustomPixels } from "@app/features/inventory/components/InheritedCustomPixels";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

export const PublisherChannelInheritedCustomPixels: FC = () => {
    const { mode } = usePublisherChannelFormInstance();
    const { channelId } = useParams<{ channelId: string }>();
    const isEditMode: boolean = mode === "edit";

    const { data = [] } = useGetPublisherInheritedPixelsQuery(
        { publisherId: channelId, includeMine: false },
        { skip: !isEditMode || !channelId }
    );

    if (!isEditMode) return null;

    return <InheritedCustomPixels thirdPartyPixels={data} />;
};
