import { useEffect, useRef, MutableRefObject, useState } from "react";
import { w3cwebsocket, IMessageEvent } from "websocket";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { sendHeartbeatEvent, sendLoginEvent } from "@app/core/socket/events";
import { sendStartHistoryStatsEvent, sendStartStatsEvent, sendStopStatsEvent } from "./events";
import {
    setAdStatsIsAuthenticated,
    setAdStatsIsConnected,
    setAdStatsError,
    setAdStatsDealAdStatEvent,
    setAdStatsDealAdStatHistoryEvent,
    addAdStatsDealSubscriber,
    removeAdStatsDealSubscriber,
    selectAdStatDealSubscriberCount,
} from "@app/features/adStats/reducer";
import { AdStat, AdStatEventMessage, AdStatHistoryEventMessage, EntityTypes, StatsByCurrency } from "./types";
import { EventTypes, RawEventData, RawEventDataWithMessage } from "@app/core/socket/types";
import adStatHistoryDemoDataEvent from "./data/inventoryentitystathistory.json";
import demoDealAdStatsEvent from "./data/dealAdStats1.json";
import conf from "@app/core/conf";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface UseDealAdStats {
    error: Error | null;
    isAuthenticated: boolean;
    isConnected: boolean;
    setSubEntityIds: (ids: string[]) => void;
}

const ADSTATS_WEBSOCKET = `${conf.mctvDealStatsWebsocketRoot}/cast/websocket/adstats`;
const HEARTBEAT_INTERVAL = 1000 * 30;

// Console src/main/webapp/demo/cc/demo-generator.js
const DEMO_EVENT_INTERVAL = 2000;
const DEMO_INVENTORY_ID = "_INVENTORY_ENTITY_";

export interface RawData {
    message: string;
    type: "adStat";
    entityType: "deal";
}

const parseMessageData = (message: IMessageEvent): RawEventData => {
    if (typeof message.data !== "string") {
        return { type: EventTypes.None, message: "" };
    }
    try {
        const eventData = JSON.parse(message.data) as RawEventData;
        return eventData;
    } catch (e) {
        // TODO: Something more robust
        console.error(e);
        return { type: EventTypes.None, message: "" };
    }
};

const adStatHistoryDemoDataRaw = (adStatHistoryDemoDataEvent as RawData).message;
const demoDealAdStatsRaw = (demoDealAdStatsEvent as RawData).message;

const demoDealAdStatsById = JSON.parse(demoDealAdStatsRaw) as { [dealId: string]: AdStat };

const adStatHistoryDemoDataByInventoryId = JSON.parse(adStatHistoryDemoDataRaw) as { [DEMO_INVENTORY_ID]: AdStat[] };
const adStatHistoryDemoData = adStatHistoryDemoDataByInventoryId[DEMO_INVENTORY_ID];

const isSocketOpen = (socket: MutableRefObject<w3cwebsocket | null>): socket is MutableRefObject<w3cwebsocket> => {
    return Boolean(socket.current && socket.current.readyState === socket.current.OPEN);
};

const incrementDemoAdStats = (mockDealAdStats: { [dealId: string]: AdStat }, dealIds: string[]) => {
    if (dealIds && dealIds.length > 0) {
        return dealIds.reduce((acc, dealId) => {
            const mockDealId = getRandomKey(mockDealAdStats);
            // use known deal ID of original AdStats if the one youre looking for isnt there
            let incrementedAdStat;
            if (mockDealAdStats && mockDealAdStats[dealId]) {
                // make a copy of it each time - so we dont try to update a read only value
                incrementedAdStat = { ...mockDealAdStats[dealId] };
            } else if (mockDealAdStats && mockDealAdStats[mockDealId]) {
                // make a copy of it each time - so we arent updating a stale reference
                incrementedAdStat = { ...mockDealAdStats[mockDealId] };
            }

            // increment tries by 500-1000
            const tries = incrementedAdStat.tries + 500 + Math.ceil(Math.random() * 500);
            incrementedAdStat.tries = tries;

            // ntime is Date.now() in ms
            incrementedAdStat.ntime = Date.now();
            // otime is today with 0 hours, mins and secs
            incrementedAdStat.otime = new Date().setHours(0, 0, 0, 0);

            // create statsByCurrency["1"]
            incrementedAdStat.statsByCurrency = { "1": {} as StatsByCurrency };
            //     increment filledRequests (only by 1 in the example response) (filled requests seems to be about 0.225% of tries)
            const filledRequests = Math.ceil(tries * 0.00225);
            incrementedAdStat.statsByCurrency[1].filledRequests = filledRequests;

            //     impressions is about 22% of filledRequests
            const impressions = Math.ceil(filledRequests * 0.22);
            incrementedAdStat.statsByCurrency[1].impressions = impressions;

            //     revenue is impressions * about 30000
            const rev = impressions * 30000;
            incrementedAdStat.statsByCurrency[1].rev = rev;
            //     cost is 90% of revenue
            incrementedAdStat.statsByCurrency[1].cost = Math.ceil(rev * 0.9);

            acc[dealId] = incrementedAdStat;
            return acc;
        }, {} as { [dealId: string]: AdStat });
    }
};

export const getRandomKey = (obj) => {
    const keys = Object.keys(obj);
    const index = Math.floor(Math.random() * keys.length);
    return keys[index];
};

export const useDealAdStats = (): UseDealAdStats => {
    const { context, session, isDemoContext } = useSeatAuthContext();
    const dispatch = useAppDispatch();
    const [subEntityIds, setSubEntityIds] = useState<string[]>([]);

    const socket = useRef<w3cwebsocket | null>(null);
    const mockDealAdStatRef = useRef(demoDealAdStatsById);
    const error = useAppSelector((state) => state.adStats.error);
    const isAuthenticated = useAppSelector((state) => state.adStats.isAuthenticated);
    const isConnected = useAppSelector((state) => state.adStats.isConnected);
    const subscriberCount = useAppSelector(selectAdStatDealSubscriberCount);
    const timezone = useAppSelector(selectUserTimezone);

    useEffect(() => {
        dispatch(addAdStatsDealSubscriber());
        return () => {
            dispatch(removeAdStatsDealSubscriber());
            if (subscriberCount === 0 && isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
                socket.current.close();
            }
        };
    }, [dispatch, subscriberCount]);

    useEffect(() => {
        if (isDemoContext) {
            return;
        }
        socket.current = new w3cwebsocket(ADSTATS_WEBSOCKET);
        socket.current.onopen = () => {
            dispatch(setAdStatsIsConnected(true));
        };

        socket.current.onclose = () => {
            dispatch(setAdStatsIsAuthenticated(false));
            dispatch(setAdStatsIsConnected(false));
        };

        socket.current.onerror = (error) => {
            dispatch(setAdStatsError(error));
        };

        socket.current.onmessage = (message) => {
            const eventData = parseMessageData(message);

            switch (eventData.type) {
                case EventTypes.AuthResult: {
                    const rawAuthResultMessage = eventData as RawEventDataWithMessage;
                    const isAuthResultAuthenticated = JSON.parse(rawAuthResultMessage.message) as boolean;
                    dispatch(setAdStatsIsAuthenticated(isAuthResultAuthenticated));
                    return;
                }
                case EventTypes.AdStat: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatEventMessage = JSON.parse(rawAddStatMessage.message) as AdStatEventMessage;
                    dispatch(setAdStatsDealAdStatEvent(adStatEventMessage));
                    return;
                }
                case EventTypes.AdStatHistory: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatHistoryEventMessage = JSON.parse(
                        rawAddStatMessage.message
                    ) as AdStatHistoryEventMessage;
                    dispatch(setAdStatsDealAdStatHistoryEvent(adStatHistoryEventMessage));
                    return;
                }
            }
        };
        return () => {
            if (isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
                socket.current.close();
            }
        };
    }, [dispatch, context, isDemoContext]);

    // Login after socket connected and user is authenticated
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && session) {
            sendLoginEvent(socket.current, session.sessionCode, session.user.emailAddress);
        }
    }, [socket, isConnected, session, context]);

    // Start stats after Socket Connected, user is authenticated, and context is selected
    // Stop stats after unmount
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated && context && timezone && subEntityIds.length > 0) {
            sendStartHistoryStatsEvent(socket.current, {
                entityType: EntityTypes.Deal,
                entityIds: [context.id],
                oneAndDone: true,
                subEntityIds,
                timezone,
            });
            sendStartStatsEvent(socket.current, {
                entityType: EntityTypes.Deal,
                entityIds: [context.id],
                oneAndDone: null,
                subEntityIds,
                timezone,
            });
        }
    }, [socket, isConnected, isAuthenticated, context, subEntityIds, timezone]);

    // Send heartbeat every 30 seconds
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated) {
            const interval = setInterval(() => {
                if (isSocketOpen(socket)) {
                    sendHeartbeatEvent(socket.current);
                }
            }, HEARTBEAT_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    }, [socket, isConnected, isAuthenticated, context]);

    useEffect(() => {
        if (!context || !isDemoContext || !session) {
            return;
        }

        // connect after 2 seconds
        setTimeout(() => dispatch(setAdStatsIsConnected(true)), 2000);

        const increment = () => {
            const incremented = incrementDemoAdStats(mockDealAdStatRef.current, subEntityIds);
            if (incremented) {
                mockDealAdStatRef.current = incremented;
            }
            dispatch(setAdStatsDealAdStatEvent(mockDealAdStatRef.current));
        };

        // increment stats now, and...
        increment();
        // ...increment stats every 2 seconds
        const interval = setInterval(() => {
            if (session && context && isDemoContext) {
                increment();
            }
        }, DEMO_EVENT_INTERVAL);

        // set the same mock last 24 hour data for each deal
        dispatch(
            setAdStatsDealAdStatHistoryEvent(
                subEntityIds.reduce<{ [dealId: number]: AdStat[] }>((acc, id) => {
                    return {
                        ...acc,
                        [id]: adStatHistoryDemoData,
                    };
                }, {})
            )
        );

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, session, context, isDemoContext, subEntityIds]);

    return {
        error,
        isAuthenticated,
        isConnected,
        setSubEntityIds,
    };
};
