import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AdSource,
    useCreateAdSourceMutation,
    useUpdateAdSourceMutation,
    useDeleteAdSourceMutation,
    useGetSeatSelfQuery,
    AD_SOURCE_TAG,
} from "@app/core/services";
import { notification } from "antd";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { AdSourceFormsContext, AdSourcesForm } from "./SeatAdSourcesForm";
import { parseAdSourceFromApiToForm, parseAdSourceFromFormToApi, parseAdSourceForCopy } from "./utils";
import { useUserAccess } from "@app/core/auth";
import { useAdSourceSyncAdSourceToDeal } from "./sync/useAdSourceSyncAdSourceToDeal";
import { useIsAdSourceOutOfSync } from "@app/features/syncedFields";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { consoleApi } from "@app/core/services";
import { selectAdSourceHasDealChanges, setAdSourceHasDealChanges } from "../reducer";
import { FormInstance } from "antd/lib";
import { useParseEnrichmentCostFields } from "./utils/useParseEnrichmentCostFields";

interface Props {
    adSourceOrigin?: AdSource;
    isCopy?: boolean;
}

export type Mode = "view" | "edit" | "create";

export interface UseAdSourceFormResult {
    adSourceForm: FormInstance<AdSourcesForm>;
    handleCancel: () => void;
    handleSubmit: () => Promise<void>;
    handleDelete: () => Promise<void>;
    handleCloseDeleteModal: () => void;
    handleOpenDeleteModal: () => void;
    openDeleteModal: boolean;
    isDeleting: boolean;
    isSubmitting: boolean;
    openSyncCancelWarningModal: boolean;
    handleConfirmSyncCancelWarning: () => void;
    handleCancelSyncCancelWarning: () => void;
    isSyncingAdSourceChangesToDeal: boolean;
}

const getErrorDescription = (error) =>
    error?.data?.errorDescription || error?.message || (typeof error === "string" ? error : "Something went wrong");

export const useAdSourceForm = ({ adSourceOrigin }: Props): UseAdSourceFormResult => {
    const dispatch = useAppDispatch();
    const adSourceHasDealChanges = useAppSelector(selectAdSourceHasDealChanges);
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceForm, adSourceFormMode } = forms;
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openSyncCancelWarningModal, setOpenSyncCancelWarningModal] = useState<boolean>(false);
    const {
        showFallbackOpportunity,
        seatAccessFlags: { hasPacingDeliverySchedulesAccess },
    } = useUserAccess();
    const { parseEnrichmentCostFieldsToApi } = useParseEnrichmentCostFields();

    useAdSourceSyncAdSourceToDeal();
    const {
        isAdSourceOutOfSync,
        setIsAdSourceOutOfSync,
        isSyncingAdSourceChangesToDeal,
        setIsSyncingAdSourceChangesToDeal,
    } = useIsAdSourceOutOfSync();

    const history = useHistory();
    const { seatId, adSourceId } = useParams<{ seatId?: string; adSourceId?: string }>();

    const { refetch: refetchSeatSelf } = useGetSeatSelfQuery(Number(seatId));
    const [createAdSource, { isLoading: isCreating }] = useCreateAdSourceMutation();
    const [updateAdSource, { isLoading: isUpdating }] = useUpdateAdSourceMutation();
    const [deleteAdSource, { isLoading: isDeleting }] = useDeleteAdSourceMutation();

    const { targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadTargeting } = useTargetingForm(
        TargetingFormKeys.AdSource
    );

    useEffect(() => {
        const isEditing = adSourceFormMode === "edit";
        const isCopying = adSourceFormMode === "copy";

        if (isEditing && adSourceOrigin) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            adSourceForm.setFieldsValue(parseAdSourceFromApiToForm(adSourceOrigin));
        }
        if (isCopying && adSourceOrigin) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            adSourceForm.setFieldsValue(parseAdSourceForCopy(adSourceOrigin));
        }
        if ((isEditing || isCopying) && adSourceOrigin) {
            loadTargeting(TargetingFormKeys.AdSource, adSourceOrigin.targeting, adSourceOrigin.targetingMode);
        }
    }, [adSourceFormMode, adSourceOrigin, adSourceForm, loadTargeting]);

    const invalidateAdSourceCacheIfDealHasChanges = () => {
        if (adSourceHasDealChanges && adSourceId) {
            dispatch(consoleApi.util.invalidateTags([{ type: AD_SOURCE_TAG, id: adSourceId }]));
            dispatch(setAdSourceHasDealChanges(false));
        }
    };

    const _handleCancel = () => {
        handleCloseDeleteModal();
        invalidateAdSourceCacheIfDealHasChanges();
        if (seatId) {
            const route = adSourceId
                ? ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, adSourceId)
                : ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId);
            history.push(route);
        }
    };

    const handleCancel = () => {
        if (isAdSourceOutOfSync) {
            setOpenSyncCancelWarningModal(true);
            return;
        }

        _handleCancel();
    };

    const handleConfirmSyncCancelWarning = () => {
        setIsAdSourceOutOfSync(false);
        _handleCancel();
    };

    const handleCancelSyncCancelWarning = () => {
        setOpenSyncCancelWarningModal(false);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleSubmit = async () => {
        try {
            const form = await adSourceForm.validateFields();
            const adResponsePriceOverride = adSourceForm.getFieldValue("adResponsePriceOverrideType");
            const enrichmentCostFields = parseEnrichmentCostFieldsToApi(
                adSourceForm.getFieldValue("type")?.id,
                adSourceForm.getFieldValue("demandAcquisitionCost"),
                adSourceForm.getFieldValue("demandAcquisitionCostModel")?.id
            );
            if (adSourceFormMode === "edit") {
                let redactedAdSourceOrigin = adSourceOrigin;
                if (adSourceOrigin) {
                    const { synthesizedAdSourceStatus: _, ...rest } = adSourceOrigin;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    redactedAdSourceOrigin = rest;
                }

                try {
                    await updateAdSource({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        id: redactedAdSourceOrigin.id,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        body: {
                            ...redactedAdSourceOrigin,
                            ...parseAdSourceFromFormToApi({
                                form,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                seatId,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.AdSource]],
                                // Get this directly from the form because we need to persist the value returned from the api...
                                // even when the flag has been disabled and the input is no longer visible.
                                // The above validateFields() call will not return the value of a hidden field.
                                adResponsePriceOverride,
                                showFallbackOpportunity,
                                enrichmentCostFields,
                                hasPacingDeliverySchedulesAccess,
                            }),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.AdSource]],
                            targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.AdSource],
                        },
                    }).unwrap();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, adSourceOrigin.id));
                } catch (error) {
                    notification.error({ message: "Ad Source Submit Error", description: getErrorDescription(error) });
                } finally {
                    return;
                }
            }
            try {
                const response = await createAdSource({
                    seatId: Number(seatId),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    body: {
                        ...parseAdSourceFromFormToApi({
                            form,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            seatId,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.AdSource]],
                            adResponsePriceOverride,
                            showFallbackOpportunity,
                            enrichmentCostFields,
                            hasPacingDeliverySchedulesAccess,
                        }),
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.AdSource]],
                        targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.AdSource],
                    },
                }).unwrap();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, response.id));
            } catch (error) {
                notification.error({
                    message: "Ad Source Submit Error",
                    description: getErrorDescription(error),
                });
            }
        } catch (error) {
            adSourceForm.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const handleDelete = async () => {
        try {
            if (!adSourceOrigin?.id || !seatId) return;

            await deleteAdSource(adSourceOrigin.id).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
        } catch (error) {
            notification.error({ message: "Ad Source Delete Error", description: getErrorDescription(error) });
        }
    };

    useEffect(() => {
        refetchSeatSelf();
        invalidateAdSourceCacheIfDealHasChanges();
        setIsSyncingAdSourceChangesToDeal(false);
        // Intentional empty array to perfom initialization actions only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        adSourceForm,
        handleCancel,
        handleSubmit,
        handleDelete,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        openDeleteModal,
        isDeleting,
        isSubmitting: isCreating || isUpdating,
        openSyncCancelWarningModal,
        handleConfirmSyncCancelWarning,
        handleCancelSyncCancelWarning,
        isSyncingAdSourceChangesToDeal,
    };
};
