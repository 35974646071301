import { FC, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { Layout } from "antd";
import { css } from "@emotion/css";
import { useSessionRefresher } from "@app/core/authClient/useSessionRefresher";
import { BuyerSideNav, Loading, SeatSideNav } from "@app/core/components";
import { AppSwitch } from "@app/core/routing";
import { AppVersionRefreshModal } from "@app/features/appVersionRefresh";
import { RunAsUserAlert } from "@app/features/runAsUser";
import { useAnalytics } from "@rubicon/component-analytics";
import { useBuyerAuthContext, useSeatAuthContext } from "@app/core/auth";
import { Pendo } from "../Pendo";

export const Main: FC = () => {
    const { isWaitingOnSession } = useSessionRefresher();
    const { context: seatContext, session: seatSession } = useSeatAuthContext();
    const { context: buyerContext, session: buyerSession } = useBuyerAuthContext();
    const { trackPageView, trackUser } = useAnalytics();
    const history = useHistory();

    useEffect(() => {
        if (seatContext && seatSession) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            trackUser({
                id: seatSession.user.id,
                email: seatSession.user.emailAddress,
                firstName: seatSession.user.firstName,
                lastName: seatSession.user.lastName,
                contextType: "demand",
                seat: { id: seatContext.id, label: seatContext.name, assignedDsps: [] },
            });
        }

        if (buyerContext && buyerSession) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            trackUser({
                id: buyerSession.user.id,
                email: buyerSession.user.emailAddress,
                firstName: buyerSession.user.firstName,
                lastName: buyerSession.user.lastName,
                contextType: "buyer",
                account: { id: buyerContext.id, label: buyerContext.name, level: 1 },
            });
        }
    }, [buyerContext, seatContext, seatSession, buyerSession, trackUser]);

    useEffect(() => {
        const unListen = history.listen(({ pathname, search }) => {
            trackPageView({ locationPathname: pathname, locationSearch: search });
        });

        return () => {
            unListen();
        };
    }, [history, trackPageView]);

    if (isWaitingOnSession) {
        return <Loading />;
    }

    return (
        <Layout
            data-sdet="main"
            className={css`
                min-height: 100vh;
                overflow: hidden;
                width: 100%;
            `}
        >
            <Route path="/seats">
                <SeatSideNav />
            </Route>
            <Route path="/buyers">
                <BuyerSideNav />
            </Route>
            <Layout
                className={css`
                    position: relative;
                    min-height: 100vh;
                `}
            >
                <AppVersionRefreshModal />
                <RunAsUserAlert />
                <AppSwitch />
            </Layout>
            <Pendo context={seatContext || buyerContext} session={seatContext ? seatSession : buyerSession} />
        </Layout>
    );
};
