import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingLabelKeyFilter } from "./useSeatAdSourcesPacingLabelKeyFilter";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesPacingHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesPacingLabelKeyFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading } = useSeatAdSourcesPacingLabelKeyFilter();

    return (
        <FilterSelect
            label={
                <HelpTooltip
                    helpKeyList={AdSourcesPacingHelpKeys}
                    helpKey={AdSourcesPacingHelpKeys.Label}
                    popover={true}
                >
                    Label
                </HelpTooltip>
            }
            onChange={handleChangeValue}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="seat-ad-sources-pacing-label-key-filter"
            placeholder="Select Key..."
        />
    );
};
