import { FC } from "react";
import { Form, Typography } from "antd";

import { Dashes } from "@app/core/components";
import {
    BRAND_SAFETY_LABEL_COL,
    BRAND_SAFETY_WRAPPER_COL,
} from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";
import { SeatAdvertiserDomainFilterListDef } from "@app/core/services/console/seatAdvertiserDomainFilterListDefs";

interface Props {
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
}

export const BlockedAdvertiserDomainListFormItem: FC<Props> = ({ seatAdvertiserDomainFilterListDefs }) => {
    return (
        <Form.Item
            label="Blocked Advertiser Domain Lists"
            wrapperCol={BRAND_SAFETY_WRAPPER_COL}
            labelCol={BRAND_SAFETY_LABEL_COL}
        >
            {seatAdvertiserDomainFilterListDefs.length ? (
                seatAdvertiserDomainFilterListDefs.map(({ name, id }) => (
                    <Typography.Text key={id}>{name} </Typography.Text>
                ))
            ) : (
                <Dashes />
            )}
        </Form.Item>
    );
};
