import {
    consoleApi,
    SEAT_ACCESS_TAG,
    SEAT_ADVERTISER_DOMAIN_FILTER_LIST_DEFS_TAG,
    SEAT_ASSIGNEES_TAG,
    SEAT_AVAILABLE_NETWORKS_TAG,
    SEAT_CONTACT_BY_ID_TAG,
    SEAT_CONTACT_TYPES_TAG,
    SEAT_CONTACTS_TAG,
    SEAT_CONTENT_TRANSPARENCY_RULES_TAG,
    SEAT_DOMAINS_TAG,
    SEAT_SELF_TAG,
    SEAT_SEPARATION_GROUPS_TAG,
    SEAT_SEPARATION_BY_ID_TAG,
    SEAT_SUPPLY_DOMAINS_TAG,
    SEAT_TAG_PARAMS_TAG,
    SEAT_TRANSPARENCY_DEFAULTS_TAG,
    SEAT_USERS_TAG,
    SEATS_ACCOUNT_TYPES_TAG,
    SEATS_ALLOWED_FMT_JSON_OPTIONS_TAG,
    SEATS_CLIENT_TYPES_TAG,
    SEATS_PICKLIST_TAG,
    SEATS_SUPPORT_TYPES_TAG,
    PUBLISHER_INHERITED_PIXELS_TAG,
    BRAND_INHERITED_PIXELS_TAG,
    SUPPLY_INHERITED_PIXELS_TAG,
    AD_UNITS_INHERITED_PIXELS_TAG,
} from "./console";
import { toQueryString } from "./utils";
import { Label, LabelValue } from "./labels";
import { User, UserPreferenceMap } from "./users";
import { SeatAdvertiserDomainFilterListDef } from "./seatAdvertiserDomainFilterListDefs";
import { AdSource, AdSourceProperties } from "./adSources";
import { TagParamRaw } from "@app/features/controls/tagParams/TagParamsTable/helpers";
import { AuthorizedExportStream } from "@app/core/services/console/externalDataStreams";
import { CreativeSamplesEntityTypes } from "../diagnostics";
import { IabCategory, IndustriesCategory, ThirdPartyPixel } from "@app/core/services";
import {
    CreateSeatParams,
    SeatContactPayload,
    SeatContactTableRaw,
    SeatContactType,
} from "@app/features/controls/seatContacts/types";
import { ViewabilityMesurement } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

interface Entity {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType?: string;
}

export interface SeatAccess {
    read: boolean;
    administration: boolean;
    report: boolean;
    write: boolean;
}

export interface AdSourceCountLimit {
    adSourceType: {
        name: string;
        id: number;
    };
    maxAllowed: number;
}

export interface AdSourceQpsLimit {
    awsRegion: { id: number; name: string; code: string };
    qpsLimit: number;
}

export interface SeatBundleIdFilter {
    bundleIdFilters: {
        bundleId: string;
        inverse: boolean;
        seatId: null | number;
        supplyId: null | number;
    }[];
    bundleIdListType: {
        creationDate: string;
        entityType: "BundleIdListType";
        id: number;
        name: string;
        sort: number;
        updateDate: string;
    };
    creationDate: string;
    defaultList: boolean;
    deleted: boolean;
    entityType: "BundleIdFilterList";
    id: number;
    name: string;
    updateDate: string;
}

interface SeatSupplyDomainFilter {
    creationDate: string;
    updateDate: string;
    id: number;
    defaultList: boolean;
    name: string;
    supplyDomainListType: Record<string, never>;
    domainFilters: unknown[];
    geo: unknown | null;
    deleted: boolean;
    entityType: string;
}

export interface InternalSeatContact {
    creationDate: string;
    entityType: "InternalSeatContact";
    id: number;
    isPrimaryContact: boolean;
    type: {
        creationDate: string;
        entityType: "InternalSeatContactType";
        id: number;
        name: string;
        publisherContact: boolean;
        sort: number;
        updateDate: string;
    };
    updateDate: string;
    user: User;
}

export interface Dmp {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
    dmpIngestionMode: Entity & { sort: number };
    dmpVendor: Entity;
    description: string;
    seatId: number | null;
    datastoreCode: string;
    membershipExpiration: boolean;
    config: {
        creationDate: string;
        updateDate: string;
        id: number;
        prefix: string;
        regex: string;
        processor: string;
        chunkSize: number;
        checkpointSize: number;
        writeConcurrency: number;
        readConcurrency: number;
        processEphemeral: boolean;
        entityType: string;
    };
    tcfId: unknown | null;
    lastRefreshed: string;
    lastUpdated: string;
    entityType: "Dmp" | "DmpView";
}

export interface SeatTransparencyDefault {
    creationDate: string;
    updateDate: string;
    id: number;
    adSourceType: AdSourceProperties & {
        sort: number;
        entityType: "AdSourceType";
    };
    allowUserId: boolean;
    maskUserId: boolean;
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowContent: boolean;
    contentTransparencyRule: AdSource["contentTransparencyRule"];
    allowSitePage: boolean;
    allowRef: boolean;
    allowIp: boolean;
    allowBundleId: boolean;
    allowStoreUrl: boolean;
    allowAllExtendedId: boolean;
    allowedExtendedIdTransparencies: AdSource["allowedExtendedIdTransparencies"];
    entityType: "SeatTransparencyDefault";
}

export interface Seat {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    code: string;
    description: string;
    clientType: SeatClientType | null;
    fillGuaranteed: boolean;
    maskDeviceId: boolean;
    accountType: SeatAccountType | null;
    enableRequestGovernor: boolean;
    honorSupplyFloor: boolean;
    requestGovernorLimitPerMin: number;
    defaultExtendedImpressionWaitTime: Entity & {
        sort: number;
        waitTimeMin: number;
    };
    timeZone: {
        creationDate?: string;
        updateDate?: string;
        id: number;
        code: string;
        name: string;
        dayLightTime?: boolean;
        dayLightSavingsOffset?: number;
        utcOffset?: number;
        entityType?: "TimeZone";
    };
    creativeBlockingMode: {
        id: number;
        name: string;
    };
    supportTier: SeatSupportTier | null;
    region: {
        creationDate?: string;
        updateDate?: string;
        id: number;
        name: string;
        entityType?: string;
        sort?: number;
    };
    // TODO: find out an account that has this data
    calculonUseRateOverride: number | null;
    // TODO: find out an account that has this data
    upperCalculonUseRateOverride: number | null;
    defaultBillingCode: string | null;
    seatContacts: unknown[];
    internalSeatContacts: InternalSeatContact[];
    seatSupplyDomainFilterLists: SeatSupplyDomainFilter[];
    demandSeatBlockAdvertiserDomainLists: unknown[];
    labelValues: LabelValue[];
    allowVastExtensionAdSourceName: boolean;
    enableMagniteMatch: boolean;
    internalLabelValues: LabelValue[];
    dmps: Dmp[];
    authorizedExportStreams: AuthorizedExportStream[];
    externalAdUnitCodeNamespaces: {
        creationDate: string;
        entityType: "ExternalAdUnitCodeNamespace";
        id: number;
        name: string;
        updateDate: string;
    }[];
    externalCodes: unknown[];
    authorizedMarketplaces: {
        authorizedDmps: Dmp[];
        creationDate: string;
        description: unknown | null;
        entityType: "Marketplace";
        id: number;
        name: string;
        orTargetingAllowed: boolean;
        updateDate: string;
    }[];
    seatTransparencyDefaults: SeatTransparencyDefault[];
    supplyDomainFilterIsBlack: boolean;
    // TODO: Properties used in legacy UI - need to confirm with live example
    seatBundleIdFilterLists: SeatBundleIdFilter[];
    bundleIdFilterIsBlack: boolean;
    publishers: unknown | null;
    isSupplySeat: boolean;
    isDemandSeat: boolean;
    isMvpd: boolean;
    advertiserBlockDomains: string[];
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    buyerSeatList: {
        buyerSeats: {
            blocked: unknown | null;
            code: string;
            creationDate: string;
            demandSeat: unknown | null;
            entityType: string;
            externalName: string;
            hidden: boolean;
            id: number;
            name: string;
            updateDate: string;
        }[];
        creationDate: string;
        description: string;
        entityType: "BuyerSeatList";
        id: number;
        name: string;
        updateDate: string;
    } | null;
    allowVastUnwrapping: boolean;
    allowVastExtensionDSP: boolean;
    allowVastExtensionCRID: boolean;
    allowVastExtensionAdomain: boolean;
    allowVastExtensionBrand: boolean;
    allowVastExtensionBidPrice: boolean;
    allowVastExtensionIabCategory: boolean;
    allowDemandCridBlocking: boolean;
    allowVastExtensionAdSourceType: boolean;
    allowTakeRateTransparency: boolean;
    affiliateCostEnabled: boolean;
    enableAdvertisersWhitelist: boolean;
    enableAudienceTargeting: boolean;
    affiliateCostModel: null | {
        creationDate: string;
        entityType: "CostModel";
        id: number;
        name: string;
        updateDate: string;
    };
    affiliateCostValuePercent: number | null;
    affiliateCostValueFixed: number | null;
    fallbackFloor: number;
    floorCurrency: { id: number; code: string };
    emitPricingInVast: boolean;
    adPodEnabled: boolean;
    enableAdServing: boolean;
    enableTestCreativeUpload: boolean;
    testCreativeId: number | null;
    moatIntegrationEnabled: boolean;
    distributionGroupsEnabled: boolean;
    contentMetadataEnabled: boolean;
    channelsEnabled: boolean;
    alertsEnabled: boolean;
    dmpCreationEnabled: boolean;
    idlEnabled: boolean;
    lsaEnabled: boolean;
    oztamEnabled: boolean;
    dacEnabled: boolean;
    businessDomain: string | null;
    seatFraudDetection: Entity;
    supplyQualityVisibilityLevel: Entity;
    thirdPartyPixels: ThirdPartyPixel[];
    // TODO: Properties used in legacy UI - need to confirm with live example
    adSourceCountLimits: AdSourceCountLimit[];
    qpsLimits: AdSourceQpsLimit[];
    seatContentMetadataKeys: { contentKey: string; id: number }[];
    seatContentMetadataKeyVersion: number;
    gdprGoogleConsentAll: boolean;
    orTargetingAllowed: boolean;
    udeCpmFee: number | null;
    udeCpmFeeCurrency: { id: number; code: string } | null;
    doohEnabled: boolean;
    mediationEnabled: boolean;
    externalLineSyncs: unknown[];
    udeEnabled: boolean;
    linearEnabled: boolean;
    asapFeatheringEnabled: boolean;
    clickTrackingEnabled: boolean;
    seatMokaFilteringMode: Entity;
    allowBlindCompetition: boolean;
    udeLidDiscoveryMode: { id: number; name: string } | null;
    adSourceMaxDurationDefault: number | null;
    adSourceMinDurationDefault: number | null;
    adSourceAuctionTypeDefault: {
        creationDate: string;
        entityType: "AdSourceAuctionType";
        id: number;
        key: number;
        name: string;
        updateDate: string;
    } | null;
    authorizedPbsServers: {
        id: number;
        name: string;
        url: string;
    }[];
    allowForwardMarketDealBlocks: boolean;
    // TODO: find out an account that has this data
    allowedFmtJsonOptions: { name: string; id: number }[];
    viewabilityMeasurement: unknown[];
    audienceLockType: { id: 1 | 2 | 3; name: string };
    audienceLockAllowedDomain: string[] | null;
    reportingBetaEnabled: boolean;
    inventoryBetaEnabled: boolean;
    liverampAtsEnabled: boolean;
    entityType: "Seat";
    seatFlags: SeatFlags | null;
}

export interface SeatFlags {
    adResponsePriceOverrideTypeEnabled: boolean;
    advancedSegmentReportingEnabled: boolean;
    allowPacingTypeNone: boolean;
    allowVastExtensionAdSourceId: boolean;
    allowVastExtensionDealId: boolean;
    controlsBetaEnabled: boolean;
    creativeReviewBetaEnabled: boolean;
    enableBidsAsSubmissions: boolean;
    enableCuratorAdsources: boolean;
    enablePacingDeliverySchedules: boolean;
    ignoreBcatBadvEnabled: boolean;
    industryBasedCategoryBlocking: boolean;
    integrationsBetaEnabled: boolean;
    seatFormatOverrideEnabled: boolean;
    podDeDupeMode: number;
    telariaChainDisabled: boolean;
}

export interface SeatSelf {
    count: number;
    seat: Seat | null;
}

export interface UpdateSeatPayload {
    id: number;
    seatFlags?: SeatFlags;
    advertiserBlockDomains?: string[] | null;
    buyerSeatList?: { id: number; name?: string } | null;
    seatAdvertiserDomainFilterListDefs?: { id: number; name?: string }[] | null;
    description?: string;
    floorCurrency?: { id: number; code: string };
    fallbackFloor?: number;
    adSourceMinDurationDefault?: number;
    adSourceMaxDurationDefault?: number;
    adSourceAuctionTypeDefault?: {
        id: number;
        name?: string;
    } | null;
    thirdPartyPixels?: { id: number; name?: string }[];
    labelValues?: {
        id: number;
        value: string;
        label: Label;
    }[];
    name?: string;
    businessDomain?: string;
    accountType?: { id: number; name?: string } | null;
    clientType?: { id: number; name?: string } | null;
    region?: { id: number; name?: string } | null;
    supportTier?: { id: number; name?: string } | null;
    timeZone?: { id: number; name?: string } | null;
    defaultExtendedImpressionWaitTime?: { id: number; name?: string } | null;
    internalLabelValues?: {
        id: number;
        value: string;
        label: Label;
    }[];
    code?: string;
    isMvpd?: boolean;
    fillGuaranteed?: boolean;
    defaultBillingCode?: string;
    seatFraudDetection?: { id: number; name?: string } | null;
    seatMokaFilteringMode?: { id: number; name?: string } | null;
    qpsLimits?: AdSourceQpsLimit[];
    requestGovernorLimitPerMin?: number;
    adSourceCountLimits?: AdSourceCountLimit[];
    seatSupplyDomainFilterLists?: { id: number; name?: string }[];
    seatBundleIdFilterLists?: { id: number; name?: string }[];
    viewabilityMeasurement?: ViewabilityMesurement[];
    externalAdUnitCodeNamespaces?: { id: number; name?: string }[];
    authorizedPbsServers?: { id: number; name?: string }[];
    distributionGroupsEnabled?: boolean;
    channelsEnabled?: boolean;
    contentMetadataEnabled?: boolean;
    seatContentMetadataKeys?: { contentKey: string }[];
    adPodEnabled?: boolean;
    affiliateCostEnabled?: boolean;
    affiliateCostModel?: { id: number; name?: string } | null;
    affiliateCostValuePercent?: number | null;
    affiliateCostValueFixed?: number | null;
    linearEnabled?: boolean;
    lsaEnabled?: boolean;
    dmpCreationEnabled?: boolean;
    dmps?: { id: number; name?: string }[];
    idlEnabled?: boolean;
    oztamEnabled?: boolean;
    maskDeviceId?: boolean;
    gdprGoogleConsentAll?: boolean;
    liverampAtsEnabled?: boolean;
    enableAdvertisersWhitelist?: boolean;
    creativeBlockingMode?: { id: number; name?: string };
    calculonUseRateOverride?: number | null;
    upperCalculonUseRateOverride?: number | null;
    allowedFmtJsonOptions?: { id: number; name?: string }[];
    emitPricingInVast?: boolean;
    authorizedMarketplaces?: { id: number; name?: string }[];
    allowForwardMarketDealBlocks?: boolean;
    allowTakeRateTransparency?: boolean;
    doohEnabled?: boolean;
    udeEnabled?: boolean;
    udeCpmFee?: number | null;
    udeCpmFeeCurrency?: { id: number; code: string } | null;
    udeLidDiscoveryMode?: { id: number; name?: string } | null;
    allowBlindCompetition?: boolean;
    mediationEnabled?: boolean;
    testCreativeId?: number | null;
    audienceLockAllowedDomain?: string[] | null;
    audienceLockType?: { id: number } | null;
    alertsEnabled?: boolean;
    enableTestCreativeUpload?: boolean;
    orTargetingAllowed?: boolean;
    allowVastUnwrapping?: boolean;
    allowVastExtensionDSP?: boolean;
    allowVastExtensionCRID?: boolean;
    allowVastExtensionAdomain?: boolean;
    allowVastExtensionBrand?: boolean;
    allowVastExtensionBidPrice?: boolean;
    allowVastExtensionAdSourceType?: boolean;
    allowVastExtensionIabCategory?: boolean;
    allowVastExtensionAdSourceName?: boolean;
    asapFeatheringEnabled?: boolean;
    authorizedExportStreams?: { id: number; name?: string }[];
    clickTrackingEnabled?: boolean;
}

export interface CommonPageParams {
    keyword?: string;
    max?: number;
    page?: number;
}

export interface SeatPicklistItem {
    id: number;
    name: string;
    code: string;
    entityType: "Seat";
}

export interface SeatGrants {
    userId: number;
    emailAddress: string;
    groupId: number | null;
    groupName: string | null;
    permission: string;
    entity: {
        id: number;
        name: string;
    };
    objectId: number;
    objectClass: string;
}

export interface SeatSupplyDomainsParams {
    keyword?: string;
}

export type SeatBundleIdsParams = SeatSupplyDomainsParams;

export interface SeatUsersPayload {
    id: number;
    params?: CommonPageParams;
}

export interface SeatAccountType extends Entity {
    entityType: "AccountType";
    sort: number;
}

export interface SeatSupportTier extends Entity {
    sort: number;
    entityType: "SeatSupportTier";
}

export interface SeatClientType extends Entity {
    entityType: "ClientType";
    sort: number;
}

export interface SeatAdvertiserDomainFilterListDefParams {
    seatId: number;
    typeId?: number;
}

export interface SeatSeparationGroupsParams {
    seatId: number | string;
    iabGroups?: boolean;
    industryGroups?: boolean;
}

export interface IndustryParams {
    seatId: number | string;
    keyword?: string;
    max?: number;
    page?: number;
}

export interface SeparationGroup {
    creationDate: string;
    iabCategories: IabCategory[];
    industries: IndustriesCategory[];
    id: number;
    name: string;
    updateDate: string;
}

export interface SeatSeparationGroupsPayload {
    iabCategories: { id: number; name?: string }[];
    industries: { id: number; name?: string }[];
    name: string;
    seat: { id: string | number; name?: string };
}

interface CreateSeatSeparationGroupsParams {
    seatId: number | string;
    body: SeatSeparationGroupsPayload;
}

export interface UpdateSeatSeparationGroupsPayload {
    id?: number;
    iabCategories?: { id: number; name?: string }[];
    industries?: { id: number; name?: string }[];
    name: string;
}

interface UpdateSeatSeparationGroupsParams {
    id: number | string;
    body: UpdateSeatSeparationGroupsPayload;
}

export interface SeatAllowedFmtJsonOption {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "AllowedFmtJsonOption";
}

export interface UpdateSeatBrandSafetyPayload {
    id: number;
    advertiserBlockDomains: string[];
    buyerSeatList: { id: number } | null;
    seatAdvertiserDomainFilterListDefs: { id: number }[];
}

export interface SeatAssignees {
    creationDate: string;
    updateDate: string;
    id: number;
    emailAddress: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    timezoneCode: string;
    currencyCode: string;
    localeCode: string;
    company: string;
    userRoles: { roleType: { id: number; typeName: string } };
    userPreferenceMap: unknown;
    name: string;
}

export interface SeatAvailableNetworks {
    allowedCurrencies?: unknown[];
    code?: string;
    entityType?: string;
    externalDealSyncIntegrations?: unknown[];
    generateFallbackDeviceId?: boolean;
    id: number;
    name: string;
    preferredCurrency?: string;
    seat?: {
        code: string;
        entityType: string;
        id: number;
        name: string;
    };
}

export interface SeatAvailableNetworksParams {
    seatId: number;
    adSourceTypeId?: number;
    extendedId?: string | null;
}

export interface SeatContentTransparencyRule {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    description: string;
    contentFields: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    }[];
}

interface SeatUpdateBlockedDomainsParams {
    domainList: string[];
    seatId: number;
    entityType: CreativeSamplesEntityTypes;
}

interface SeatTagParamSettingUpdate {
    selected: boolean;
    macro: string;
    name: string;
    tagParamDefinition: {
        id: number;
    };
    id: number;
}

interface SeatTagParamSettingCreate {
    selected: boolean;
    macro: string;
    name: string;
    seat: {
        id: number;
    };
    tagParamDefinition: {
        id: number;
    };
}

interface EntityCreated {
    creationDate: string;
    updateDate: string;
}

export interface SeatDomain {
    adomain: string;
    advertiserId: number | null;
    advertiserName: string | null;
}

export interface ExternalUser {
    company: string;
    countryCode: string;
    creationDate: string;
    currencyCode: string;
    emailAddress: string;
    firstName: string;
    id: number;
    lastName: string;
    localeCode: string;
    name: string;
    timezoneCode: string;
    updateDate: string;
    userPreferenceMap: UserPreferenceMap;
    userRoles: ExternalUserRole[];
}

export interface ExternalUserRole {
    roleType: {
        id: number;
        typeName: string;
    };
}

export const seatsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatAccess: builder.query<SeatAccess, number>({
            query: (id: number) => `seats/${id}/access`,
            providesTags: [SEAT_ACCESS_TAG],
        }),
        getSeatExternalUsers: builder.query<ExternalUser[], number>({
            query: (seatId) => `seats/${seatId}/externalUsers`,
        }),
        getSeatUsers: builder.query<User[], SeatUsersPayload>({
            query: ({ id, params = {} }: SeatUsersPayload) => `seats/${id}/users;${toQueryString(params)}`,
            providesTags: [SEAT_USERS_TAG],
        }),
        getSeatAdvertiserDomainFilterListDefs: builder.query<
            SeatAdvertiserDomainFilterListDef[],
            SeatAdvertiserDomainFilterListDefParams
        >({
            query: (params: SeatAdvertiserDomainFilterListDefParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/seatAdvertiserDomainFilterListDefs;${toQueryString(rest)}`;
            },
            providesTags: [SEAT_ADVERTISER_DOMAIN_FILTER_LIST_DEFS_TAG],
        }),
        getSeatSeparationGroups: builder.query<SeparationGroup[], SeatSeparationGroupsParams>({
            query: (params: SeatSeparationGroupsParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/separationGroups;${toQueryString(rest)}`;
            },
            providesTags: [SEAT_SEPARATION_GROUPS_TAG],
        }),
        getSeatSeparationGroupById: builder.query<SeparationGroup, number>({
            query: (id: number) => `separationGroups/${id}`,
            providesTags: (_, __, id) => [{ type: SEAT_SEPARATION_BY_ID_TAG, id }],
        }),
        createSeatSeparationGroups: builder.mutation<SeparationGroup, CreateSeatSeparationGroupsParams>({
            query: (params: CreateSeatSeparationGroupsParams) => {
                const { seatId, body } = params;
                return {
                    url: `seats/${seatId}/separationGroups`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [SEAT_SEPARATION_GROUPS_TAG],
        }),
        createSeatContact: builder.mutation<SeatContactPayload, CreateSeatParams>({
            query: (params: CreateSeatParams) => {
                const { seatId, body } = params;
                return {
                    url: `seats/${seatId}/contacts`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [SEAT_CONTACTS_TAG],
        }),
        updateSeatContact: builder.mutation<SeatContactPayload, SeatContactPayload>({
            query: (body) => {
                return {
                    url: `seats/contacts/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [{ type: SEAT_CONTACT_BY_ID_TAG, id }, { type: SEAT_CONTACTS_TAG }],
        }),
        updateSeatSeparationGroupsById: builder.mutation<SeparationGroup, UpdateSeatSeparationGroupsParams>({
            query: (params: UpdateSeatSeparationGroupsParams) => {
                const { id, body } = params;
                return {
                    url: `separationGroups/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [{ type: SEAT_SEPARATION_BY_ID_TAG, id }, { type: SEAT_SEPARATION_GROUPS_TAG }],
        }),
        deleteSeatContact: builder.mutation<unknown, number | string>({
            query: (id) => {
                return {
                    url: `seats/contacts/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (_, err, id) =>
                err ? [] : [{ type: SEAT_CONTACT_BY_ID_TAG, id: Number(id) }, { type: SEAT_CONTACTS_TAG }],
        }),
        deleteSeatSeparationGroups: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `separationGroups/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [SEAT_SEPARATION_GROUPS_TAG],
        }),
        getSeatsAccountTypes: builder.query<SeatAccountType[], void>({
            query: () => `seats/accountTypes`,
            providesTags: [SEATS_ACCOUNT_TYPES_TAG],
        }),
        getSeatsClientTypes: builder.query<SeatClientType[], void>({
            query: () => `seats/clientTypes`,
            providesTags: [SEATS_CLIENT_TYPES_TAG],
        }),
        getSeatsSupportTiers: builder.query<SeatSupportTier[], void>({
            query: () => `seats/supportTiers`,
            providesTags: [SEATS_SUPPORT_TYPES_TAG],
        }),
        getSeatSelf: builder.query<SeatSelf, number | string>({
            query: (id: number) => `seats/self;seatId=${id}`,
            providesTags: (_, __, id) => [{ type: SEAT_SELF_TAG, id }],
        }),
        updateSeatSelf: builder.mutation<Seat, UpdateSeatPayload>({
            query: (body) => {
                return {
                    url: `seats/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err
                    ? []
                    : [
                          { type: SEAT_SELF_TAG, id },
                          { type: PUBLISHER_INHERITED_PIXELS_TAG },
                          { type: BRAND_INHERITED_PIXELS_TAG },
                          { type: SUPPLY_INHERITED_PIXELS_TAG },
                          { type: AD_UNITS_INHERITED_PIXELS_TAG },
                      ],
        }),
        getSeatsPicklist: builder.query<SeatPicklistItem[], CommonPageParams>({
            query: (params: CommonPageParams) => `seats/picklist;${toQueryString(params)}`,
            providesTags: (_, err) => (err ? [] : [SEATS_PICKLIST_TAG]),
        }),
        getSeatsAllowedFmtJsonOptions: builder.query<SeatAllowedFmtJsonOption[], void>({
            query: () => "seats/allowedFmtJsonOptions",
            providesTags: (_, err) => (err ? [] : [SEATS_ALLOWED_FMT_JSON_OPTIONS_TAG]),
        }),
        updateSeatBrandSafety: builder.mutation<Seat, UpdateSeatBrandSafetyPayload>({
            query: (payload: UpdateSeatBrandSafetyPayload) => ({
                url: `seats/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, { id }) => (err ? [] : [{ type: SEAT_SELF_TAG, id }]),
        }),
        updateSeatBlockedDomains: builder.mutation<Seat, SeatUpdateBlockedDomainsParams>({
            query: ({ seatId, domainList, entityType }) => ({
                url: `${entityType}/${seatId}/blockedDomains`,
                method: "POST",
                body: domainList,
            }),
            invalidatesTags: (_, err, { seatId }) => (err ? [] : [{ type: SEAT_SELF_TAG, seatId }]),
        }),
        getSeatSupplyDomains: builder.query<SeatSupplyDomainFilter[], SeatSupplyDomainsParams>({
            query: (params) => `supplyDomains;${toQueryString(params)}`,
            providesTags: [SEAT_SUPPLY_DOMAINS_TAG],
        }),
        getSeatBundleIds: builder.query<SeatBundleIdFilter[], SeatBundleIdsParams>({
            query: (params) => `bundleIds;${toQueryString(params)}`,
            providesTags: [SEAT_SUPPLY_DOMAINS_TAG],
        }),
        getSeatAssignees: builder.query<SeatAssignees[], number>({
            query: (id: number) => `seats/${id}/assignees`,
            providesTags: [SEAT_ASSIGNEES_TAG],
        }),
        getSeatContacts: builder.query<SeatContactTableRaw[], number>({
            query: (id) => `seats/${id}/contacts`,
            providesTags: [SEAT_CONTACTS_TAG],
        }),
        getSeatContact: builder.query<SeatContactPayload, string>({
            query: (contactId: string) => `seats/contacts/${contactId}`,
            providesTags: (_, err, contactId) => (err ? [] : [{ type: SEAT_CONTACT_BY_ID_TAG, id: Number(contactId) }]),
        }),
        getSeatContactTypes: builder.query<SeatContactType[], void>({
            query: () => "seats/contacts/types",
            providesTags: [SEAT_CONTACT_TYPES_TAG],
        }),
        getSeatDomains: builder.query<SeatDomain[], { seatId: string | number; params?: CommonPageParams }>({
            query: ({ seatId, params }) => {
                return `seats/${seatId}/domains;${toQueryString(params || {})}`;
            },
            providesTags: [SEAT_DOMAINS_TAG],
        }),
        getSeatTagParamSettingId: builder.query<TagParamRaw[], number>({
            query: (id: number) => `seats/${id}/tagParams`,
            providesTags: [SEAT_TAG_PARAMS_TAG],
        }),
        updateSeatTagParamSettingId: builder.mutation<TagParamRaw, SeatTagParamSettingUpdate>({
            query: (payload: SeatTagParamSettingUpdate) => ({
                url: `seatTagParamSettings/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: [SEAT_TAG_PARAMS_TAG],
        }),
        createSeatTagParamSettingId: builder.mutation<EntityCreated, SeatTagParamSettingCreate>({
            query: (payload: SeatTagParamSettingCreate) => ({
                url: `seats/${payload.seat.id}/tagParamSettings`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: [SEAT_TAG_PARAMS_TAG],
        }),
        getAvailableNetworks: builder.query<SeatAvailableNetworks[], SeatAvailableNetworksParams>({
            query: ({ seatId, ...rest }: SeatAvailableNetworksParams) => {
                const params: Omit<SeatAvailableNetworksParams, "seatId"> = {
                    ...rest,
                };

                return `seats/${seatId}/availableNetworks;${toQueryString(params)}`;
            },
            providesTags: () => [SEAT_AVAILABLE_NETWORKS_TAG],
        }),
        getContentTransparencyRules: builder.query<SeatContentTransparencyRule[], number>({
            query: (seatId: number) => `seats/${seatId}/contentTransparencyRules`,
            providesTags: [SEAT_CONTENT_TRANSPARENCY_RULES_TAG],
        }),
        getTransparencyDefaults: builder.query<SeatTransparencyDefault[], number>({
            query: (seatId: number) => `seats/${seatId}/defaults/transparency`,
            providesTags: [SEAT_TRANSPARENCY_DEFAULTS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSeatContactsQuery,
    useGetSeatContactQuery,
    useCreateSeatContactMutation,
    useUpdateSeatContactMutation,
    useDeleteSeatContactMutation,
    useCreateSeatSeparationGroupsMutation,
    useGetAvailableNetworksQuery,
    useGetSeatAccessQuery,
    useGetSeatDomainsQuery,
    useGetSeatsAccountTypesQuery,
    useGetSeatsClientTypesQuery,
    useGetSeatsSupportTiersQuery,
    useGetSeatsAllowedFmtJsonOptionsQuery,
    useLazyGetSeatSelfQuery,
    useGetSeatsPicklistQuery,
    useGetSeatContactTypesQuery,
    useGetSeatSupplyDomainsQuery,
    useGetSeatBundleIdsQuery,
    useGetSeatUsersQuery,
    useUpdateSeatSelfMutation,
    useUpdateSeatBlockedDomainsMutation,
    useGetSeatAssigneesQuery,
    useCreateSeatTagParamSettingIdMutation,
    useGetContentTransparencyRulesQuery,
    useLazyGetSeatAccessQuery,
    useGetSeatAdvertiserDomainFilterListDefsQuery,
    useGetSeatSelfQuery,
    useGetSeatSeparationGroupsQuery,
    useGetSeatSeparationGroupByIdQuery,
    useGetSeatTagParamSettingIdQuery,
    useGetSeatExternalUsersQuery,
    useGetTransparencyDefaultsQuery,
    useUpdateSeatBrandSafetyMutation,
    useUpdateSeatTagParamSettingIdMutation,
    useUpdateSeatSeparationGroupsByIdMutation,
    useDeleteSeatSeparationGroupsMutation,
} = seatsApi;
