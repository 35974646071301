import { FC } from "react";
import { Dashes } from "@app/core/components";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { AdSource, getTimeZoneLabel } from "@app/core/services/console";
import { Col, Form, Row, Tag, Typography } from "antd";
import { format } from "@rubicon/utils";
import { formatDateStringFromApi } from "@app/core/utils";
import { DEAL_TYPES } from "@app/features/deals/constants";

interface Props {
    startDate: string;
    endDate: string | null;
    timeZone: AdSource["timeZone"];
    totalImpression?: number | null;
    shareOfVoicePercent?: number | null;
    minDuration: number | null;
    maxDuration: number | null;
    inventoryDistributionGroups: AdSource["inventoryDistributionGroups"];
    isInventoryDistributionGroupsVisible: boolean;
    typeID: number;
}
export const AdSourceDeliveryDetails: FC<Props> = ({
    endDate,
    timeZone,
    maxDuration,
    minDuration,
    totalImpression,
    shareOfVoicePercent,
    startDate,
    inventoryDistributionGroups,
    isInventoryDistributionGroupsVisible,
    typeID,
}) => {
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Start Date">
                        {formatDateStringFromApi(startDate, timeZone?.code, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="End Date">
                        {formatDateStringFromApi(endDate, timeZone?.code, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)}
                    </Form.Item>
                </Col>

                <Col sm={8}>
                    <Form.Item label="Time Zone">
                        {timeZone ? getTimeZoneLabel(timeZone) : format.constants.DEFAULT_DASHES}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Share of Voice Enabled">{format.asYesOrNo(shareOfVoicePercent)}</Form.Item>
                </Col>
                <Col sm={8}>
                    {totalImpression && <Form.Item label="Total Impressions">{totalImpression}</Form.Item>}
                    {shareOfVoicePercent && <Form.Item label="Share Of Voice Percent">{shareOfVoicePercent}</Form.Item>}
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Min Duration">
                        <Dashes value={minDuration} />
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Max Duration">
                        <Dashes value={maxDuration} />
                    </Form.Item>
                </Col>
            </Row>
            {isInventoryDistributionGroupsVisible && (
                <Row>
                    <Col sm={8}>
                        <Form.Item label="Inventory Distribution Groups">
                            <Typography.Paragraph>
                                {inventoryDistributionGroups && inventoryDistributionGroups.length > 0
                                    ? inventoryDistributionGroups.map((label) => (
                                          <Tag key={label.labelValue?.id}>
                                              {label?.labelValue?.label?.key}:
                                              {typeID === DEAL_TYPES.GUARANTEE ? label.cap : label.throttle}
                                          </Tag>
                                      ))
                                    : format.constants.DEFAULT_DASHES}
                            </Typography.Paragraph>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </>
    );
};
