import { FC, useState } from "react";
import { Badge, Breakpoint, Button, Space } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { gold6, ResizableDrawer } from "@rubicon/antd-components";
import { SeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/SeatTree";

export type ScreensBreakpoint = Partial<Record<Breakpoint, boolean>>;

import { InventorySearch } from "@app/features/inventory/InventoryBrandSafety/InventorySearch";

export const TreeDrawer: FC = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    return (
        <>
            <Button data-sdet="open-tree-drawer-button" type="default" onClick={showDrawer}>
                <MenuOutlined />
            </Button>
            <ResizableDrawer
                open={open}
                width="100%"
                zIndex={10000}
                closable={false}
                placement="right"
                title="Select Inventory"
                rootStyle={{ zIndex: 10000 }}
                extra={
                    <Button data-sdet="close-tree-drawer-button" type="text" block onClick={closeDrawer}>
                        <CloseOutlined />
                    </Button>
                }
            >
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <InventorySearch />
                    <Badge color={gold6} text="Custom floor price set at given level" />
                    <SeatTree onTreeSelect={closeDrawer} />
                </Space>
            </ResizableDrawer>
        </>
    );
};
