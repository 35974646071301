import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetBrandInheritedPixelsQuery } from "@app/core/services";
import { InheritedCustomPixels } from "@app/features/inventory/components/InheritedCustomPixels";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

export const BrandChannelInheritedCustomPixels: FC = () => {
    const { mode } = useBrandChannelFormInstance();
    const { brandId } = useParams<{ brandId: string }>();

    const isEditMode: boolean = mode === "edit";

    const { data = [] } = useGetBrandInheritedPixelsQuery(
        { brandId, includeMine: false },
        { skip: !isEditMode || !brandId }
    );

    if (!isEditMode) return null;

    return <InheritedCustomPixels thirdPartyPixels={data} />;
};
