import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesLabelKey, setFiltersSeatAdSourcesLabelValue } from "../../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useGetSeatLabelsQuery } from "@app/core/services/console/labels";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseSeatAdSourcesPacingLabelKeyFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue | string | null;
    isLoading: boolean;
}

export const useSeatAdSourcesPacingLabelKeyFilter = (): UseSeatAdSourcesPacingLabelKeyFilter => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const seatAdSourcesPacingLabelKey = useAppSelector(
        (state) => state.seatAdSourcesPacing.filters.seatAdSourcesLabelKey
    );
    const { data, isFetching } = useGetSeatLabelsQuery({ seatId: Number(seatId) });
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.key, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue) => {
        dispatch(setFiltersSeatAdSourcesLabelKey(option));
        dispatch(setFiltersSeatAdSourcesLabelValue(null));
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesPacingLabelKey,
        isLoading: isFetching,
    };
};
