import { FC } from "react";
import { Form, Input } from "antd";
import {
    BRAND_SAFETY_LABEL_COL,
    BRAND_SAFETY_WRAPPER_COL,
} from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/constants";

interface Props {
    advertiserBlockDomains: string[];
}

export const BlockedAdvertiserDomainsFormItem: FC<Props> = ({ advertiserBlockDomains }) => {
    return (
        <Form.Item
            label="Blocked Advertiser Domains"
            wrapperCol={BRAND_SAFETY_WRAPPER_COL}
            labelCol={BRAND_SAFETY_LABEL_COL}
        >
            <Input.TextArea readOnly rows={10} style={{ resize: "none" }} value={advertiserBlockDomains.join("\r\n")} />
        </Form.Item>
    );
};
