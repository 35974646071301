import { css } from "@emotion/css";
import { gray5 } from "@rubicon/antd-components";
import {
    AFFILIATE_COST_MODEL,
    AFFILIATE_COST_VALUE_FIXED,
    AFFILIATE_COST_VALUE_PERCENT,
    INTERNAL_LABEL_VALUES,
    LABEL_VALUES,
} from "@app/features/inventory/constants";

export const INVENTORY_FORM_FIELDS = {
    // Seat Details General
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: "Seat Name is required",
        placeholder: "Enter Name",
    },
    CODE: {
        name: "code",
        label: "Code",
        rulesMessage: "Code is required",
        placeholder: "Enter Code",
    },
    DATE_CREATED: {
        label: "Date Created",
    },
    LAST_UPDATED: {
        label: "Last Updated",
    },
    LAST_INDEXED: {
        label: "Last Indexed",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
    },
    BUSINESS_DOMAIN: {
        name: "businessDomain",
        label: "Business Domain",
        placeholder: "Enter Business Domain",
        rulesMessage: "Business Domain is required",
    },
    ACCOUNT_TYPE: {
        name: "accountType",
        label: "Account Type",
        placeholder: "Select Account Type...",
    },
    CLIENT_TYPE: {
        name: "clientType",
        label: "Client Type",
        placeholder: "Select Client Type...",
    },
    REGION: {
        name: "region",
        label: "Region",
        placeholder: "Select Region...",
    },
    SUPPORT_TIER: {
        name: "supportTier",
        label: "Support Tier",
        placeholder: "Select Support Tier...",
    },
    IS_SUPPLY_SEAT: {
        name: "isSupplySeat",
        label: "Supply Seat",
    },
    IS_DEMAND_SEAT: {
        name: "isDemandSeat",
        label: "Demand Seat",
    },
    IS_MVPD: {
        name: "isMvpd",
        label: "MVPD",
    },
    IS_FILL_GUARANTEED: {
        name: "fillGuaranteed",
        label: "Fill Guaranteed",
    },
    // Seat Details Financial
    FLOOR_CURRENCY: {
        name: "floorCurrency",
        label: "Currency",
    },
    FALLBACK_FLOOR: {
        name: "fallbackFloor",
        label: "Fallback Floor",
    },
    DEFAULT_BILLING_CODE: {
        name: "defaultBillingCode",
        label: "Default Billing Code",
        placeholder: "Enter Default Billing Code",
    },
    AFFILIATE_COST_MODEL: {
        name: AFFILIATE_COST_MODEL,
        label: "Cost Model",
    },
    AFFILIATE_COST_VALUE_PERCENT: {
        name: AFFILIATE_COST_VALUE_PERCENT,
        label: "Cost Value",
        placeholder: "Enter Revshare Percentage value",
        rulesMessage: "Revshare Percentage value is required",
    },
    AFFILIATE_COST_VALUE_FIXED: {
        name: AFFILIATE_COST_VALUE_FIXED,
        label: "Cost Value",
        placeholder: "Enter Fixed CPM value",
        rulesMessage: "Fixed CPM value is required",
    },
    AFFILIATE_COST_ENABLED: {
        name: "affiliateCostEnabled",
        label: "Affiliate Cost",
    },
    // Seat Details Seat Defaults
    TIME_ZONE: {
        name: "timeZone",
        label: "Time Zone",
        placeholder: "Select Time Zone...",
    },
    AD_SOURCE_MIN_DURATION_DEFAULT: {
        name: "adSourceMinDurationDefault",
        label: "Ad Source Min Duration",
    },
    AD_SOURCE_MAX_DURATION_DEFAULT: {
        name: "adSourceMaxDurationDefault",
        label: "Ad Source Max Duration",
    },
    AD_SOURCE_AUCTION_TYPE_DEFAULT: {
        name: "adSourceAuctionTypeDefault",
        label: "Ad Source Auction Type Default",
        placeholder: "Select Ad Source Auction Type Default...",
    },
    DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME: {
        name: "defaultExtendedImpressionWaitTime",
        label: "Default Extended Impression Wait Time",
        placeholder: "Off",
    },
    // Seat Details Brand Safety
    BLOCK_ADVERTISER_DOMAIN_LISTS: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain Lists",
        placeholder: "Select Block Advertiser Domains...",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Blocked Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains one per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List...",
    },
    // Seat Details Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels...",
    },
    // Seat Details Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },
    // Exchange Protections Inventory Limits
    COUNT_LIMITS: {
        name: "adSourceCountLimits",
        label: "Ad Source Type Limits",
    },
    QPS_LIMITS: {
        name: "qpsLimits",
        label: "QPS Limits",
    },
    GOVERNOR_REQUEST: {
        name: "requestGovernorLimitPerMin",
        label: "Max Ads / User in 1 Minute",
    },
    VIEWABILITY_MESUREMENT: {
        name: "viewabilityMeasurement",
        label: "Viewability Measurement",
    },
    VIEWABILITY_MESUREMENT_SAMPLE_RATE: {
        name: "sampleRate",
    },
    MOKA_FILTERING_MODE: {
        name: "seatMokaFilteringMode",
        label: "Moka Integration",
    },
    // Exchange Protections Quality Control
    FRAUD_DETECTION: {
        name: "seatFraudDetection",
        label: "Fraud Detection",
    },
    DOMAIN_FILTER_MODE: {
        name: "supplyDomainFilterIsBlack",
        label: "Supply Domain Filter Mode",
    },
    SUPPLY_DOMAIN_FILTER: {
        name: "seatSupplyDomainFilterLists",
        label: "Supply Domain List",
        placeholder: "Select Supply Domain...",
        rulesMessage: "Please choose at least one white list",
    },
    BUNDLE_ID_FILTER_MODE: {
        name: "bundleIdFilterIsBlack",
        label: "App Bundle ID Filter Mode",
    },
    BUNDLE_ID_FILTER: {
        name: "seatBundleIdFilterLists",
        label: "App Bundle Id Lists",
        placeholder: "Select Bundle Id...",
        rulesMessage: "Please choose at least one white list",
    },
    // Advanced Features Inventory Management
    SSP_CONNECT_ENABLED: {
        name: "sspConnectEnabled",
        label: "SSP Connect",
    },
    EXTERNAL_AD_UNIT_CODE_NAMESPACES: {
        name: "externalAdUnitCodeNamespaces",
        label: "Enabled External Code Namespaces",
        placeholder: "Select External Code Namespaces...",
        rulesMessage: "You must select at least 1 External Code Namespace if SSP Connect is enabled",
    },
    AUTHORIZED_PBS_SERVERS: {
        name: "authorizedPbsServers",
        label: "Authorized PBS Servers",
        placeholder: "Select Authorized PBS Servers...",
    },
    CHANNELS_ENABLED: {
        name: "channelsEnabled",
        label: "Channels Enabled",
    },
    INVENTORY_DISTRIBUTION_GROUPS: {
        name: "distributionGroupsEnabled",
        label: "Inventory Distribution Groups",
    },
    // Advanced Features CTV Tools
    CONTENT_METADATA_ENABLED: {
        name: "contentMetadataEnabled",
        label: "Content Metadata Enabled",
    },
    SEAT_CONTENT_METADATA_KEYS: {
        name: "seatContentMetadataKeys",
        label: "Content Metadata Keys",
        placeholder: "Select Content Metadata Keys...",
    },
    SEAT_POD_DEDUPING_LEVEL: {
        name: "podDeDupeMode",
        label: "Pod Deduping Level",
    },
    AD_POD_ENABLED: {
        name: "adPodEnabled",
        label: "Show Ad Pod",
    },
    LINEAR_ENABLED: {
        name: "linearEnabled",
        label: "Linear TV",
    },
    LSA_ENABLED: {
        name: "lsaEnabled",
        label: "Live Stream Acceleration",
    },
    // Advanced Features Identity and Regulations
    DMP_CREATION_ENABLED: {
        name: "dmpCreationEnabled",
        label: "DMP Creation Enabled",
    },
    DMPS: {
        name: "dmps",
        label: "Enabled DMP List",
        placeholder: "Select DMP...",
    },
    IDL_ENABLED: {
        name: "idlEnabled",
        label: "IDL Enabled",
    },
    OZTAM_ENABLED: {
        name: "oztamEnabled",
        label: "BVOD Connect",
    },
    MASK_DEVICE_ID: {
        name: "maskDeviceId",
        label: "Mask Device ID",
    },
    GDPR_GOOGLE_CONSENT_ALL: {
        name: "gdprGoogleConsentAll",
        label: "Google GDPR Consent All",
    },
    ENABLE_AUDIENCE_TARGETING: {
        name: "enableAudienceTargeting",
        label: "Enable Audience Targeting",
    },
    ENABLE_MAGNITE_MATCH: {
        name: "enableMagniteMatch",
        label: "Enable Magnite Match",
    },
    ENABLE_LIVERAMP_ATS: {
        name: "liverampAtsEnabled",
        label: "Enable Liveramp ATS",
    },
    // Advanced Features Creative Control
    ENABLE_ADVERTISER_WHITELIST: {
        name: "enableAdvertisersWhitelist",
        label: "Advertisers Allow List",
    },
    CREATIVE_BLOCK_MODE: {
        name: "creativeBlockingMode",
        label: "Creative Block Mode",
    },
    CALCULON_USE_RATE_OVERRIDE: {
        name: "calculonUseRateOverride",
        label: "Lower Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: "Lower Calculon Use Rate Override value should be between .001 - .999",
    },
    UPPER_CALCULON_USE_RATE_OVERRIDE: {
        name: "upperCalculonUseRateOverride",
        label: "Upper Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: "Upper Calculon Use Rate Override value should be between .001 - .999",
    },
    IGNORE_BCAT_BADV_ENABLED: {
        name: "ignoreBcatBadvEnabled",
        label: "Ignore BCAT/BADV",
    },
    // Advanced Features Ad Responses
    EMIT_PRICEING_IN_VAST: {
        name: "emitPricingInVast",
        label: "Emit Pricing in VAST",
    },
    ALLOW_VAST_EXTENSION_DSP: {
        name: "allowVastExtensionDSP",
        label: "Allow DSP VAST Extension",
    },
    ALLOW_VAST_EXTENSION_CRID: {
        name: "allowVastExtensionCRID",
        label: "Allow CRID VAST Extension",
    },
    ALLOW_VAST_EXTENSION_ADOMAIN: {
        name: "allowVastExtensionAdomain",
        label: "Allow Adomain VAST Extension",
    },
    ALLOW_VAST_EXTENSION_BRAND: {
        name: "allowVastExtensionBrand",
        label: "Allow Brand VAST Extension",
    },
    ALLOW_VAST_EXTENSION_BID_PRICE: {
        name: "allowVastExtensionBidPrice",
        label: "Allow Bid Price VAST Extension",
    },
    ALLOW_VAST_EXTENSION_AD_SOURCE_TYPE: {
        name: "allowVastExtensionAdSourceType",
        label: "Allow Ad Source Type VAST Extension",
    },
    ALLOW_VAST_EXTENSION_IAB_CATEGORY: {
        name: "allowVastExtensionIabCategory",
        label: "Allow IAB Category VAST Extension",
    },
    ALLOW_AD_SOURCE_NAME_VAST_EXTENSION: {
        name: "allowVastExtensionAdSourceName",
        label: "Allow Ad Source Name VAST Extension",
    },
    ALLOWED_FMT_JSON_OPTIONS: {
        name: "allowedFmtJsonOptions",
        label: "Allowed FMT=JSON Options",
        placeholder: "Select Allowed FMT=JSON Options...",
    },
    ALLOW_AD_RESPONSE_PRICE_OVERRIDE: {
        name: "adResponsePriceOverrideTypeEnabled",
        label: "Allow Ad Response Price Override",
    },
    TELARIA_CHAIN_DISABLED: {
        name: "telariaChainDisabled",
        label: "Telaria Chain Disabled",
    },
    SEAT_FORMAT_OVERRIDE_ENABLED: {
        name: "seatFormatOverrideEnabled",
        label: "Seat Format Override Enabled",
    },
    // Advanced Features Demand Management
    AUTHORIZED_MARKETPLACES: {
        name: "authorizedMarketplaces",
        label: "Authorized Marketplaces",
        placeholder: "Select Authorized Marketplace...",
    },
    ALLOW_FORWARD_MARKET_DEAL_BLOCKS: {
        name: "allowForwardMarketDealBlocks",
        label: "Allow Forward Market Deal Blocks",
    },
    ALLOW_HIGH_CPM: {
        name: "doohEnabled",
        label: "Allow High CPM",
    },
    ALLOW_TAKE_RATE_TRANSPARENCY: {
        name: "allowTakeRateTransparency",
        label: "Allow Take Rate Transparency",
    },
    EXTERNAL_DATA_STREAMS: {
        name: "authorizedExportStreams",
        label: "External Data Streams",
        placeholder: "Select External Data Stream...",
    },
    ENABLE_DEMAND_ACQUISITION_COST: {
        name: "dacEnabled",
        label: "Enable Demand Acquisition Cost",
    },
    ASAP_FEATHERING: {
        name: "asapFeatheringEnabled",
        label: "ASAP Feathering",
    },
    // Advanced Features Unified Decisioning
    UD_ENABLED: {
        name: "udeEnabled",
        label: "UD Enabled",
    },
    UD_FEE: {
        name: "udeCpmFee",
        label: "UD Fee",
        placeholder: "Enter UD Fee",
    },
    UDE_CPM_FEE_CURRENCY: {
        name: "udeCpmFeeCurrency",
        label: "UD Fee Currency",
    },
    UD_LID_DISCOVERY_MODE: {
        name: "udeLidDiscoveryMode",
        label: "UD LID Discovery Mode",
        placeholder: "Select UD LID Discovery Mode...",
    },
    ALLOW_BLIND_COMPETITION: {
        name: "allowBlindCompetition",
        label: "Allow Blind Competition",
    },
    MEDIATION_ENABLED: {
        name: "mediationEnabled",
        label: "Mediation Enabled",
    },
    // Advanced Features Admin Tools
    ALERTS_ENABLED: {
        name: "alertsEnabled",
        label: "Alerts Enabled",
    },
    TEST_CREATIVES_ENABLED: {
        name: "enableTestCreativeUpload",
        label: "Enable Creative Test Mode",
    },
    TEST_CREATIVE_ID: {
        name: "testCreativeId",
        label: "Test Creative Id",
        placeholder: "Select Test Creative Id...",
    },
    ALLOW_OR_TARGETING: {
        name: "orTargetingAllowed",
        label: "Allow Or Targeting",
    },
    CLICK_TRACKING: {
        name: "clickTrackingEnabled",
        label: "Click Tracking",
    },
    ALLOW_VAST_UNWRAPPING: {
        name: "allowVastUnwrapping",
        label: "Allow VAST Unwrapping",
    },
    AUDIENCE_LOCK_TYPE: {
        name: "audienceLockType",
        label: "Data Lock",
    },
    AUDIENCE_LOCK_ALLOWED_DOMAIN: {
        name: "audienceLockAllowedDomain",
        label: "Allowed Data Lock Domains",
        placeholder: "Enter Allowed Data Lock Domains one per line",
    },
} as const;

export interface RequiredQPSLimitsRegions {
    id: number;
    name: string;
}

export const REQUIRED_QPS_LIMITS_REGIONS: RequiredQPSLimitsRegions[] = [
    { id: 1, name: "US East (N. Virginia)" },
    { id: 4, name: "US West(Oregon)" },
    { id: 15, name: "Asia Pacific (Singapore)" },
    { id: 7, name: "EU (Ireland)" },
];
export const SEAT_MOKA_FILTERING_MODE_OPTIONS = [
    { value: 1, label: "On" },
    { value: 2, label: "Off" },
    { value: 3, label: "MLP Only" },
    { value: 4, label: "Ignore" },
];
export const SEAT_FRAUD_DETECTION_OPTIONS = [
    { value: 1, label: "On" },
    { value: 2, label: "Off" },
    { value: 3, label: "Ignore" },
];
export const SEAT_SUPPLY_DOMAIN_FILTER_MODE_OPTIONS = [
    { value: true, label: "Block" },
    { value: false, label: "Allow" },
];

export const CREATIVE_BLOCK_MODE_OFF_OPTION = { value: 1, label: "Off" };
export const CREATIVE_BLOCK_MODE_BLOCK_OPTION = { value: 2, label: "Block" };
export const CREATIVE_BLOCK_MODE_ALLOW_OPTION = { value: 3, label: "Allow" };
export const CREATIVE_BLOCK_MODE_INHERITED_OPTION = { value: 4, label: "Inherited" };
export const CREATIVE_BLOCK_MODE_OPTIONS = [
    CREATIVE_BLOCK_MODE_OFF_OPTION,
    CREATIVE_BLOCK_MODE_BLOCK_OPTION,
    CREATIVE_BLOCK_MODE_ALLOW_OPTION,
    CREATIVE_BLOCK_MODE_INHERITED_OPTION,
];

export const AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE = { value: 1, label: "Off Entire Seat" };
export const AUDIENCE_LOCK_ON_ENTIRE_SEAT_TYPE = { value: 2, label: "On Entire Seat" };
export const AUDIENCE_LOCK_ON_SPECIFY_AD_SOURCE_TYPE = { value: 3, label: "On Specify Ad Source" };
export const AUDIENCE_LOCK_TYPE_OPTIONS = [
    AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE,
    AUDIENCE_LOCK_ON_ENTIRE_SEAT_TYPE,
    AUDIENCE_LOCK_ON_SPECIFY_AD_SOURCE_TYPE,
];
export const SEAT_SUPPLY_BUNDLE_ID_FILTER_MODE_OPTIONS = [
    { value: true, label: "Block" },
    { value: false, label: "Allow" },
];
export const REVSHARE_PERCENTAGE_COST_MODEL = { value: 1, label: "Revshare Percentage" };
export const INVENTORY_SEAT_DETAILS = "details";
export const INVENTORY_SEAT_FLOORS = "floors";
export const INVENTORY_SEAT_PROTECTIONS = "protections";
export const INVENTORY_SEAT_ADVANCED = "advanced";
export const INVENTORY_SEAT_INTERNAL = "internal";
export const INVENTORY_SEAT_INTERNAL_DEFINITION = "internalDefinition";
export const INVENTORY_SEAT_DEFAULT_PRICES = "prices";

export type INVENTORY_SEAT_TABS_TYPES =
    | typeof INVENTORY_SEAT_DETAILS
    | typeof INVENTORY_SEAT_FLOORS
    | typeof INVENTORY_SEAT_PROTECTIONS
    | typeof INVENTORY_SEAT_ADVANCED
    | typeof INVENTORY_SEAT_INTERNAL
    | typeof INVENTORY_SEAT_INTERNAL_DEFINITION
    | typeof INVENTORY_SEAT_DEFAULT_PRICES;

export const INVENTORY_SEAT_TABS = {
    DETAILS: {
        key: INVENTORY_SEAT_DETAILS,
        label: "Seat Details",
    },
    FLOORS: {
        key: INVENTORY_SEAT_FLOORS,
        label: "Floors",
    },
    PROTECTIONS: {
        key: INVENTORY_SEAT_PROTECTIONS,
        label: "Exchange Protections",
    },
    ADVANCED: {
        key: INVENTORY_SEAT_ADVANCED,
        label: "Advanced Features",
    },
    INTERNAL: {
        key: INVENTORY_SEAT_INTERNAL,
        label: "Internal Contacts",
    },
    PRICES: {
        key: INVENTORY_SEAT_DEFAULT_PRICES,
        label: "Default Prices",
    },
};

export enum SeatDetailsDrawerTitles {
    Performance = "Performance",
    General = "General",
    Financial = "Financial",
    SeatDefaults = "Seat Defaults",
    BrandSafety = "Brand Safety",
    CustomPixels = "Custom Pixels",
    Labels = "Labels",
}

export enum ExchangeProtectionsDrawerTitles {
    Limits = "Limits",
    Control = "Control",
}

export enum PublisherDrawerTitles {
    General = "General",
    Affiliate = "Affiliate",
    BrandSafety = "BrandSafety",
    Internal = "Internal",
    AdvancedFeatures = "AdvancedFeatures",
    CustomPixels = "CustomPixels",
    Labels = "Labels",
    Floors = "Floors",
}

export enum BrandDrawerTitles {
    General = "General",
    Affiliate = "Affiliate",
    BrandSafety = "BrandSafety",
    Internal = "Internal",
    SupplyDetails = "SupplyDetails",
    CustomPixels = "CustomPixels",
    Labels = "Labels",
    Floors = "Floors",
}

export enum BrandChannelTitles {
    General = "General",
    Defaults = "Defaults",
    AdPod = "AdPod",
    PlaylistConfiguration = "Playlist Configuration",
    AdBreakRules = "Ad Break Rules",
    BrandSafety = "BrandSafety",
    CustomPixels = "CustomPixels",
    DetailConfiguration = "Detail Configuration",
    Labels = "Labels",
    Floors = "Floors",
}

export enum SupplyDrawerTitles {
    General = "General",
    Affiliate = "Affiliate",
    BrandSafety = "BrandSafety",
    SiteDetails = "SiteDetails",
    AppDetails = "AppDetails",
    ExchangeSafety = "ExchangeSafety",
    Internal = "Internal",
    SupplyDetails = "SupplyDetails",
    CustomPixels = "CustomPixels",
    Labels = "Labels",
    WaterfallPrefiltering = "WaterfallPrefiltering",
    Floors = "Floors",
}

export enum adUnitDrawerTitles {
    General = "General",
    Affiliate = "Affiliate",
    UD = "UD",
    BrandSafety = "BrandSafety",
    DemandRequirements = "Demand Requirements",
    Internal = "Internal",
    SupplyDetails = "Supply Details",
    CustomPixels = "CustomPixels",
    AdBreakRules = "Ad Break Rules",
    AdPod = "AdPod",
    PlaylistConfiguration = "Playlist Configuration",
    Labels = "Labels",
    WaterfallPrefiltering = "WaterfallPrefiltering",
    Floors = "Floors",
    AdPodDedupeLevel = "Pod Deduping Level Override",
}

export enum AdvancedFeaturesDrawerTitles {
    InventoryManagement = "InventoryManagement",
    CTVTools = "CTVTools",
    IdentityAndRegulations = "IdentityAndRegulations",
    CreativeControl = "CreativeControl",
    AdResponses = "AdResponses",
    DemandManagement = "DemandManagement",
    UnifiedDecisioning = "UnifiedDecisioning",
    AdminTools = "AdminTools",
}

export enum SeatHelpKeys {
    Name = "name",
    Code = "code",
    CreationDate = "creationdate",
    UpdatedDate = "updatedate",
    LastIndexed = "lastindexed",
    SeatRequestGovernorLimitPerMin = "seat.requestgovernorlimitpermin",
    SeatMokaEnabled = "seat.mokaenabled",
    PublisherAffiliateCostModel = "publisher.affiliatecostmodel",
    PublisherAffiliateCostValue = "publisher.affiliatecostvalue",
    BrandAffiliateCostModel = "brand.affiliatecostmodel",
    BrandAffiliateCostValue = "brand.affiliatecostvalue",
    SupplyAffiliateCostModel = "supply.affiliatecostmodel",
    SupplyAffiliateCostValue = "supply.affiliatecostvalue",
    AdUnitAffiliateCostModel = "adunit.affiliatecostmodel",
    AdUnitAffiliateCostValue = "adunit.affiliatecostvalue",
    SeatEnableAdvertisersAsWhitelist = "seat.enableAdvertisersAsWhitelist",
    EnabledChannels = "seat.channelsenabled",
    EnabledExternalCodeNamespaces = "seat.externaladunitcodenamespace",
    SSPConnect = "seat.enablesspconnect",
    SeatEnableContentMetaData = "seat.enablecontentmetadata",
    SeatContentMetadataKeys = "seat.contentmetadatakeys",
    EnablelinearTv = "seat.enablelineartv",
    SeatEnableLsa = "seat.enablelsa",
    SeatFraudDetection = "seat.frauddetection",
    SeatSupplyDomainFilterMode = "seat.supplydomainfiltermode",
    SeatSupplyDomainList = "seat.supplydomainlists",
    SeatAppBundleIdFilterMode = "seat.appbundlefiltermode",
    SeatAppBundleIdLists = "seat.appbundlelists",
    SeatCurrency = "seat.currency",
    SeatFallbackFloor = "seat.fallbackfloor",
    SeatEnableAdPods = "seat.enableadpods",
    SeatBVODConnect = "seat.bvodconnect",
    SeatDmpCreationEnabled = "seat.dmpcreationenabled",
    SeatEnabledDmpLists = "seat.enableddmplists",
    SeatGdrpGoogleConsentAll = "seat.gdprGoogleConsentAll",
    SeatEnableAudienceTargeting = "seat.enableAudienceTargeting",
    SeatEnableMagniteMatch = "seat.enableMagniteMatch",
    SeatLiverampAtsEnabled = "seat.liverampAtsEnabled",
    SeatIdlEnabled = "seat.idlenabled",
    SeatMaskIfa = "seat.maskifa",
    SeatAdvertisersAllowlist = "seat.advertisersallowlist",
    SeatAllowDemandCRIDblocking = "seat.allowdemandcridblocking",
    SeatCalculonUseRateOverride = "seat.calculonuserateoverride",
    SeatUpperCalculonUserateOverride = "seat.uppercalculonuserateoverride",
    SeatAllowVastExtensionAdsourceType = "seat.allowvastextensionadsourcetype",
    SeatAllowVastExtensionAdomain = "seat.allowvastextensionadomain",
    SeatAllowVastExtensionAdSourceName = "seat.allowVastExtensionAdSourceName",
    SeatAllowVastExtensionBrand = "seat.allowvastextensionbrand",
    SeatAllowVastExtenstionCrid = "seat.allowvastextenstioncrid",
    SeatAllowVastExtenstionDsp = "seat.allowvastextensiondsp",
    SeatAllowVastExtenstionIABcat = "seat.allowvastextensioniabcat",
    SeatAllowedFMTJsonOptions = "seat.allowedfmtjsonoptions",
    SeatDistributionGroupsEnabled = "seat.distributiongroupsenabled",
    SeatAdsourceTypeLimits = "seat.adsourcetypelimits",
    EmitPricingInVast = "emitpricinginvast",
    SeatQPSLimit = "seat.qpslimit",
    SeatSmartThrottleMinQps = "seat.smartthrottleminqps",
    SeatAllowForwardMarketDealBlocks = "seat.allowforwardmarketdealblocks",
    SeatAllowHighCpm = "seat.allowhighcpm",
    SeatAllowTakeRateTransparency = "seat.allowtakeratetransparency",
    SeatAsapFeatheringEnabled = "seat.asapfeatheringenabled",
    SeatAuthorizedMarketplaces = "seat.authorizedmarketplaces",
    SeatAuthorizedExportStreamsList = "seat.authorizedExportStreamsList",
    SeatHonorsupplyfloor = "seat.honorsupplyfloor",
    SeatEnableAdServing = "seat.enableadserving",
    SeatMediationEnabled = "seat.mediationenabled",
    SeatTimezone = "seat.timezone",
    SeatEnableUd = "seat.enableud",
    SeatUdlidMode = "seat.udlidmode",
    SeatUdBlindCompetition = "seat.udblindcompetition",
    SeatTestCreativeId = "seat.testCreativeId",
    SeatEnableTestCreative = "seat.enabletestcreative",
    SeatAlertsEnabled = "seat.alertsenabled",
    SeatAllowOrTargeting = "seat.allowortargeting",
    SeatAllowVastUnwrapping = "seat.allowvastunwrapping",
    SeatDatalock = "seat.datalock",
    SeatAllowedDataLockDomains = "seat.alloweddatalockdomains",
    SeatClickTracking = "seat.clicktracking",
    SeatBusinessDomain = "seat.businessdomain",
    SeatAccountType = "seat.accounttype",
    seatAuthorizePbsServers = "seat.authorizepbsservers",
    SeatClientType = "seat.clienttype",
    SeatRegion = "seat.region",
    SeatSupportTier = "seat.supporttier",
    SeatIsMvpd = "seat.ismvpd",
    SupplyType = "supply.type",
    SupplyIsDynamic = "supply.isdynamic",
    SeatFillGuaranteed = "seat.fillguaranteed",
    PublisherBillingCode = "publisher.billingcode",
    SeatAffiliateCostEnabled = "seat.affiliatecostenabled",
    SeatAffiliateCostModel = "seat.affiliatecostmodel",
    SeatAffiliateCostValue = "seat.affiliatecostvalue",
    SeatAdSourceAuctionTypeDefault = "seat.adsourceauctiontypedefault",
    SeatAdSourceMinDefault = "seat.adsourcemindurdefault",
    SeatAdSourceMaxDefault = "seat.adsourcemaxdurdefault",
    SeatPublisherTechnicalAccount = "seat.publishertechnicalaccountcontact",
    SeatPublisherAccount = "seat.publisheraccountcontact",
    SeatPublisherSales = "seat.publishersalescontact",
    SeatBillingContact = "seat.billingcontact",
    SeatDemandTechincalAccount = "seat.demandtechnicalaccountcontact",
    SeatDemandAccount = "seat.demandaccountcontact",
    SeatDemandSales = "seat.demandsalescontact",
    SeatDemandBilling = "seat.demandbillingcontact",
    DefaultExtendedImpressionWaitTime = "adunit.extendedimpressionwaittime",
    BrandBlockedIabCategories = "brand.blockediabcategories",
    GlobalLabels = "globallabels",
    SeatDescription = "seat.description",
    seatDacEnabled = "seat.dacEnabled",
    Description = "description",
    CodeType = "code.codetype",
    PublisherExternalComment = "publisher.externalcomment",
    PublisherName = "publisher.name",
    AdUnitAdomainFilterList = "adunit.adomainfilterlist",
    AdUnitBlockedDomains = "adunit.blockadomains",
    AdUnitBuyerSeatList = "adunit.buyerseatlist",
    AdUnitCuepoints = "adunit.cuepoints",
    AdUnitPublisherReauction = "adunit.publisherReauction",
    AdBreakName = "adbreakrule.lbl",
    AdBreakOfAds = "dbreakrule.mxpdads",
    AdBreakDuration = "adbreakrule.mxpdsec",
    AdBreakMidRollIndex = "adbreakrule.index",
    ThirdPartyPixels = "thirdpartypixels",
    SupplyNameoverride = "supply.nameoverride",
    SupplyDomain = "supply.domain",
    SupplyInventoryPartnerDomain = "supply.inventorypartnerdomain",
    SupplyAppstore = "supply.appstore",
    SupplyBundleId = "supply.bundleid",
    SupplyAppstoreName = "supply.appstorename",
    SupplyStoreUrl = "supply.storeurl",
    SupplyPaidType = "supply.paidtype",
    SupplyEnableNoAdCookieSync = "supply.enablenoadcookiesync",
    SupplyVideoQuality = "supply.videoquality",
    AdunitStatus = "adunit.status",
    AdunitMaxadsPerAdvertiser = "adunit.maxadsperadvertiser",
    AdunitUnwrapVast = "adunit.unwrapvast",
    AdunitAdvFreqCapping = "adunit.enableadomainfreqcapping",
    SupplyIfaMode = "supply.ifamode",
    SupplySourceRelationship = "supply.sourcerelationship",
    SupplyAllowVastWrappers = "supply.allowvastwrappers",
    SupplyIsCoppa = "supply.iscoppa",
    AdunitMimes = "adunit.mimes",
    AdunitApis = "adunit.apis",
    AdunitLinearity = "adunit.linearity",
    AdunitType = "adunit.type",
    AdunitInterstitial = "adunit.interstitial",
    AdunitPlacement = "adunit.placement",
    AdunitProtocols = "adunit.protocols",
    AdunitMinduration = "adunit.minduration",
    AdunitMaxduration = "adunit.maxduration",
    AdunitMaxExtended = "adunit.maxextended",
    AdunitMinBitrate = "adunit.minbitrate",
    AdunitMaxBitrate = "adunit.maxbitrate",
    AdunitSsaiType = "adunit.ssaitype",
    SupplySsliveStream = "supply.islivestream",
    AdunitSkip = "adunit.skip",
    AdunitSkippableMinimum = "adunit.skippableminimum",
    AdunitSkippableAfter = "adunit.skippableafter",
    AdunitMultiplicity = "adunit.multiplicity",
    AdunitMaxAdsPerPod = "adunit.maxadsperpod",
    AdunitMaxPodSeconds = "adunit.maxpodseconds",
    AdunitAdpodFillMode = "adunit.adpodfillmode",
    AdunitPodEnforcement = "adunit.podenforcement",
    AdUnitEnableaDomainFreqCappings = "adunit.enableadomainfreqcappings",
    AdunitSeparationGroupMode = "adunit.separationgroupmode",
    AdUnitCompetitiveSeparation = "adunit.competitiveseparation",
    AdUnitPlaylistDefinitionMode = "adunit.playlistdefinitionmode",
    AdUnitPlaybacks = "adunit.playbacks",
    AdUnitDeliverMethod = "adunit.videodeliveries",
    AdUnitContentChannel = "adunit.contentchannel",
    AdUnitContentNetwork = "adunit.contentnetwork",
    AdUnitBoxing = "adunit.boxing",
    AdUnitName = "adunit.name",
    AdUnitDescription = "adunit.description",
    AdUnitCode = "code.code",
    AdUnitBlockedAttr = "adunit.blockedattributes",
    SupplyPrivacy = "supply.privacy",
    LabelsPrefilter = "labels.prefilter",
    AdUnitUdoptOut = "adunit.udoptout",
    SupplyViewabilityVendors = "supply.viewabilityvendors",
    SupplyMaskifafordemand = "supply.maskifafordemand",
    BrandIab = "brand.iab",
    BrandAdvertisersAllowList = "brand.advertisersallowlist",
    BlockedADomainLists = "blockedadomainlists ",
    BlockADomains = "blockadomains ",
    Labels = "labels",
    //Performance
    RejectedRequests = "inventorystats.rejectedrequests",
    Requests = "inventorystats.requests",
    AdPodRequests = "adstats-publisher.adPodRequests",
    PlaylistRequests = "adstats-publisher.playlistRequests",
    PodSlotRequests = "adstats-publisher.podSlotRequests",
    Fills = "adstats-publisher.fills",
    Impressions = "adstats-publisher.impressions",
    GrossRevenue = "adstats-publisher.grossRevenue",
    NetRevenue = "adstats-publisher.netRevenue",
    FillRate = "adstats-publisher.fillRate",
    UseRate = "adstats-publisher.useRate",
    AvgFillTime = "inventorystats.avgfilltime",
    BreaksPerPlaylist = "NA",
    SlotsPerBreak = "NA-",
    SlotsPerPlaylist = "NA+",
    LSAPodRequests = "adstats-exchange.lsaPodRequests",
    LSASlotsRequests = "adstats-exchange.lsaPodSlots",
    LSAPotentialFills = "adstats-exchange.lsaPotentialFills",
    LSAFills = "adstats-exchange.lsaAdFills",
    LSAImps = "adstats-exchange.lsaImpressions",
    SeatPodDedupeLevel = "seat.poddupelevel",
    AdUnitPodDeduping = "adunit.poddupelevel",
}

export const SEAT_INTERNAL_CONTACTS_HELP_FIELDS = [
    {
        label: "Publisher Technical Account Contact",
        helpPath: SeatHelpKeys.SeatPublisherTechnicalAccount,
        key: "PUBLISHER_TECHNIACAL_ACCOUNT",
    },
    {
        label: "Publisher Account Contact",
        helpPath: SeatHelpKeys.SeatPublisherAccount,
        key: "PUBLISHER_ACCOUNT_CONTACT",
    },
    {
        label: "Publisher Sales Contact",
        helpPath: SeatHelpKeys.SeatPublisherSales,
        key: "PUBLISHER_SALES_CONTACT",
    },
    {
        label: "Publisher Billing Contact",
        helpPath: SeatHelpKeys.SeatBillingContact,
        key: "PUBLISHER_BILLING_CONTACT",
    },
    {
        label: "Demand Technical Account Contact",
        helpPath: SeatHelpKeys.SeatDemandTechincalAccount,
        key: "DEMAND_TECHNICAL_ACCOUNT_CONTACT",
    },
    {
        label: "Demand Account Contact",
        helpPath: SeatHelpKeys.SeatDemandAccount,
        key: "DEMAND_ACCOUNT_CONTACT",
    },
    {
        label: "Demand Sales Contact",
        helpPath: SeatHelpKeys.SeatDemandSales,
        key: "DEMAND_SALES_CONTACT",
    },
    {
        label: "Demand Billing",
        helpPath: SeatHelpKeys.SeatDemandBilling,
        key: "DEMAND_BILLING",
    },
];

export const AD_UNIT_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.AdUnitName,
        key: "NAME",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.AdUnitDescription,
        key: "DESCRIPTION",
    },
    {
        label: "Status",
        helpPath: SeatHelpKeys.AdunitStatus,
        key: "STATUS",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.AdUnitCode,
        key: "CODE",
    },
];
export const AD_UNIT_AFFILIATE_HELP_FIELDS = [
    {
        label: "Affiliate Cost Model",
        helpPath: SeatHelpKeys.AdUnitAffiliateCostModel,
        key: "AFFILIATE_COST_MODEL",
    },
    {
        label: "Affiliate Cost Value",
        helpPath: SeatHelpKeys.AdUnitAffiliateCostValue,
        key: "AFFILIATE_COST_VALUE",
    },
];

export const AD_UNIT_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain List",
        helpPath: SeatHelpKeys.BlockedADomainLists,
        key: "BLOC_ADVERTISER_DOMAIN_LIST",
    },
    {
        label: "Block Advertiser Domains",
        helpPath: SeatHelpKeys.BlockADomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
    {
        label: "Advertiser Frequency Capping",
        helpPath: SeatHelpKeys.AdunitAdvFreqCapping,
        key: "ADVERTISER_FREQUENCY_CAPPING",
    },
];
export const AD_UNIT_SUPPLY_DETAILS_HELP_FIELDS = [
    {
        label: "Linearity",
        helpPath: SeatHelpKeys.AdunitLinearity,
        key: "LINEARITY",
    },
    {
        label: "Multiplicity",
        helpPath: SeatHelpKeys.AdunitMultiplicity,
        key: "MULTIPLICITY",
    },
    {
        label: "Type",
        helpPath: SeatHelpKeys.AdunitType,
        key: "TYPE",
    },
    {
        label: "Video Playbacks",
        helpPath: SeatHelpKeys.AdUnitPlaybacks,
        key: "VIDEO_PLAYBACKS",
    },
    {
        label: "Delivery Methods",
        helpPath: SeatHelpKeys.AdUnitDeliverMethod,
        key: "DELIVERY_METHODS",
    },
    {
        label: "Skippable",
        helpPath: SeatHelpKeys.AdunitSkip,
        key: "SKIPPABLE",
    },
    {
        label: "Interestial",
        helpPath: SeatHelpKeys.AdunitInterstitial,
        key: "INTERESTIAL",
    },
    {
        label: "SSAI Type",
        helpPath: SeatHelpKeys.AdunitSsaiType,
        key: "SSAI_TYPE",
    },
    {
        label: "Boxing",
        helpPath: SeatHelpKeys.AdUnitBoxing,
        key: "BOXING",
    },
    {
        label: "Unwrap VAST",
        helpPath: SeatHelpKeys.AdunitUnwrapVast,
        key: "UNWRAP_VAST",
    },
    {
        label: "Live Stream",
        helpPath: SeatHelpKeys.SupplySsliveStream,
        key: "LIVE_STREAM",
    },
    {
        label: "Content Channel",
        helpPath: SeatHelpKeys.AdUnitContentChannel,
        key: "CONTENT_CHANNEL",
    },
    {
        label: "Content Network",
        helpPath: SeatHelpKeys.AdUnitContentNetwork,
        key: "CONTENT_NETWORK",
    },
    {
        label: "UD Opt-Out",
        helpPath: SeatHelpKeys.AdUnitUdoptOut,
        key: "AD_UNIT_UDOPT_OUT",
    },
    {
        label: "Live Stream Acceleration",
        helpPath: SeatHelpKeys.SeatEnableLsa,
        key: "LIVE_STREAM_ACCELERATION",
    },
    {
        label: "Extended Imp Wait Time",
        helpPath: SeatHelpKeys.DefaultExtendedImpressionWaitTime,
        key: "EXTENDED_IMP_WAIT_TIME",
    },
];

export const AD_UNIT_INTERNAL_HELP_FILEDS = [
    {
        label: "Publisher Re-Auction",
        helpPath: SeatHelpKeys.AdUnitPublisherReauction,
        key: "PUBLISHER_RE-AUCTION",
    },
    {
        label: "Lower Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "Lower Calculon Use Rate Override",
    },
    {
        label: "Upper Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatUpperCalculonUserateOverride,
        key: "UPPER_CALCULON_USE_RATE_OVERRIDE",
    },
];

export const AD_UNIT_DEMAND_REQUIREMENTS = [
    {
        label: "Supported Protocols",
        helpPath: SeatHelpKeys.AdunitProtocols,
        key: "SUPPORTED_PROTOCOLS",
    },
    {
        label: "MIMEs",
        helpPath: SeatHelpKeys.AdunitMimes,
        key: "ADUNIT_MIMES",
    },
    {
        label: "Supported APIs",
        helpPath: SeatHelpKeys.AdunitApis,
        key: "SUPPORTED_APIS",
    },
    {
        label: "Placement",
        helpPath: SeatHelpKeys.AdunitPlacement,
        key: "PLACEMENT",
    },
    {
        label: "Min Duration",
        helpPath: SeatHelpKeys.AdunitMinduration,
        key: "MIN_DURATION",
    },
    {
        label: "Max Duration",
        helpPath: SeatHelpKeys.AdunitMaxduration,
        key: "MAX_DURATION",
    },
    {
        label: "Max Extended",
        helpPath: SeatHelpKeys.AdunitMaxExtended,
        key: "MAX_EXTENDED",
    },
    {
        label: "Min Bitrate (Kbps)",
        helpPath: SeatHelpKeys.AdunitMinBitrate,
        key: "MIN_BITRATE_(Kbps)",
    },
    {
        label: "Max Bitrate (Kbps)",
        helpPath: SeatHelpKeys.AdunitMaxBitrate,
        key: "MAX_BITRATE_(Kbps)",
    },
    {
        label: "Blocked Attributes",
        helpPath: SeatHelpKeys.AdUnitBlockedAttr,
        key: "BLOCKED_ATTRIBUTES",
    },
];

export const AD_UNIT_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];

export const AD_UNIT_PLAYLIST_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Pod Enforcement",
        helpPath: SeatHelpKeys.AdunitPodEnforcement,
        key: "POD_ENFORCEMENT",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Max Ads per Advertiser",
        helpPath: SeatHelpKeys.AdunitMaxadsPerAdvertiser,
        key: "MAX_ADS_PER_ADVERTISER",
    },
    {
        label: "Playlist Definition Mode",
        helpPath: SeatHelpKeys.AdUnitPlaylistDefinitionMode,
        key: "PLAYLIST_DEFINITION_MODE",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.AdUnitPodDeduping,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];

export const AD_UNIT_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];
export const AD_UNIT_FLOORS_PIXELS_HELP_FIELDS = [];
export const AD_UNIT_UD_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Max Ads per Advertiser",
        helpPath: SeatHelpKeys.AdunitMaxadsPerAdvertiser,
        key: "MAX_ADS_PER_ADVERTISER",
    },
];

export const AD_UNIT_AD_POD_HELP_FIELDS = [
    {
        label: "Max Pod Seconds",
        helpPath: SeatHelpKeys.AdunitMaxPodSeconds,
        key: "MAX_POD_SECONDS",
    },
    {
        label: "Max Ads Per Pod",
        helpPath: SeatHelpKeys.AdunitMaxAdsPerPod,
        key: "MAX_ADS_PER_POD",
    },
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Pod Enforcement",
        helpPath: SeatHelpKeys.AdunitPodEnforcement,
        key: "POD_ENFORCEMENT",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.AdUnitPodDeduping,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];
export const BRAND_CHANNEL_DETAIL_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Allow VAST Wrappers",
        helpPath: SeatHelpKeys.SupplyAllowVastWrappers,
        key: "ALLOW_VAST_WRAPPERS",
    },
    {
        label: "Live Stream Acceleration",
        helpPath: SeatHelpKeys.SeatEnableLsa,
        key: "LIVE_STREAM_ACCELERATION",
    },
    {
        label: "Unwrap VAST",
        helpPath: SeatHelpKeys.AdunitUnwrapVast,
        key: "UNWRAP_VAST",
    },
    {
        label: "Extended Imp Wait Time",
        helpPath: SeatHelpKeys.DefaultExtendedImpressionWaitTime,
        key: "EXTENDED_IMP_WAIT_TIME",
    },
    {
        label: "Advertisers as Allow List",
        helpPath: SeatHelpKeys.BrandAdvertisersAllowList,
        key: "ADVERTISERS_AS_ALLOW_LIST",
    },
    {
        label: "No-Ad Cookie Sync",
        helpPath: SeatHelpKeys.SupplyEnableNoAdCookieSync,
        key: "NO_AD_COOKIE_SYNC",
    },
    {
        label: "Advertiser Frequency Capping",
        helpPath: SeatHelpKeys.AdunitAdvFreqCapping,
        key: "ADVERTISER_FREQUENCY_CAPPING",
    },
    {
        label: "Advertiser Domain Frequency Caps",
        helpPath: SeatHelpKeys.AdUnitEnableaDomainFreqCappings,
        key: "ADVERTISER_DOMAIN_FREQUENCY_CAPS",
    },
    {
        label: "Inventory Partner Domain",
        helpPath: SeatHelpKeys.SupplyInventoryPartnerDomain,
        key: "INVENTORY_PARTNER_DOMAIN",
    },
];
export const BRAND_CHANNEL_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain List",
        helpPath: SeatHelpKeys.BlockedADomainLists,
        key: "BLOC_ADVERTISER_DOMAIN_LIST",
    },
    {
        label: "Blocked Advertiser Domains",
        helpPath: SeatHelpKeys.BlockADomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
    {
        label: "Blocked Categories",
        helpPath: SeatHelpKeys.BrandBlockedIabCategories,
        key: "BLOCKED_CATEGORIES",
    },
];
export const BRAND_CHANNEL_AD_POD_HELP_FIELDS = [
    {
        label: "Max Pod Seconds",
        helpPath: SeatHelpKeys.AdunitMaxPodSeconds,
        key: "MAX_POD_SECONDS",
    },
    {
        label: "Max Ads Per Pod",
        helpPath: SeatHelpKeys.AdunitMaxAdsPerPod,
        key: "MAX_ADS_PER_POD",
    },
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Pod Enforcement",
        helpPath: SeatHelpKeys.AdunitPodEnforcement,
        key: "POD_ENFORCEMENT",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.AdUnitPodDeduping,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];

export const BRAND_CHANNEL_DEFAULTS_HELP_FIELDS = [
    {
        label: "MIMEs",
        helpPath: SeatHelpKeys.AdunitMimes,
        key: "ADUNIT_MIMES",
    },
    {
        label: "Supported APIs",
        helpPath: SeatHelpKeys.AdunitApis,
        key: "SUPPORTED_APIS",
    },
    {
        label: "Linearity",
        helpPath: SeatHelpKeys.AdunitLinearity,
        key: "LINEARITY",
    },
    {
        label: "Type",
        helpPath: SeatHelpKeys.AdunitType,
        key: "TYPE",
    },
    {
        label: "Interstitial",
        helpPath: SeatHelpKeys.AdunitInterstitial,
        key: "INTERSTITIAL",
    },
    {
        label: "Placement",
        helpPath: SeatHelpKeys.AdunitPlacement,
        key: "PLACEMENT",
    },
    {
        label: "COPPA",
        helpPath: SeatHelpKeys.SupplyIsCoppa,
        key: "COPPA",
    },
    {
        label: "Privacy Policy",
        helpPath: SeatHelpKeys.SupplyPrivacy,
        key: "PRIVACY_POLICY",
    },
    {
        label: "Categories",
        helpPath: SeatHelpKeys.BrandIab,
        key: "CATEGORIES",
    },
    {
        label: "Supported Protocols",
        helpPath: SeatHelpKeys.AdunitProtocols,
        key: "SUPPORTED_PROTOCOLS",
    },
    {
        label: "Min Duration",
        helpPath: SeatHelpKeys.AdunitMinduration,
        key: "MIN_DURATION",
    },
    {
        label: "Max Duration",
        helpPath: SeatHelpKeys.AdunitMaxduration,
        key: "MAX_DURATION",
    },
    {
        label: "Max Extended",
        helpPath: SeatHelpKeys.AdunitMaxExtended,
        key: "MAX_EXTENDED",
    },
    {
        label: "Min Bitrate (Kbps)",
        helpPath: SeatHelpKeys.AdunitMinBitrate,
        key: "MIN_BITRATE_(Kbps)",
    },
    {
        label: "Max Bitrate (Kbps)",
        helpPath: SeatHelpKeys.AdunitMaxBitrate,
        key: "MAX_BITRATE_(Kbps)",
    },
    {
        label: "SSAI Type",
        helpPath: SeatHelpKeys.AdunitSsaiType,
        key: "SSAI_TYPE",
    },
    {
        label: "Live Stream",
        helpPath: SeatHelpKeys.SupplySsliveStream,
        key: "LIVE_STREAM",
    },
    {
        label: "Skippable",
        helpPath: SeatHelpKeys.AdunitSkip,
        key: "SKIPPABLE",
    },
    {
        label: "Skippable Minimum",
        helpPath: SeatHelpKeys.AdunitSkippableMinimum,
        key: "SKIPPABLE_MINIMUM",
    },
    {
        label: "Skippable After",
        helpPath: SeatHelpKeys.AdunitSkippableAfter,
        key: "SKIPPABLE_AFTER",
    },
    {
        label: "Multiplicity",
        helpPath: SeatHelpKeys.AdunitMultiplicity,
        key: "MULTIPLICITY",
    },
];

export const BRAND_CHANNEL_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];
export const BRAND_CHANNEL_PIXELS_HELP_FIELDS = [];
export const BRAND_CHANNEL_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];
export const BRAND_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Pod Enforcement",
        helpPath: SeatHelpKeys.AdunitPodEnforcement,
        key: "POD_ENFORCEMENT",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Max Ads per Advertiser",
        helpPath: SeatHelpKeys.AdunitMaxadsPerAdvertiser,
        key: "MAX_ADS_PER_ADVERTISER",
    },
    {
        label: "Playlist Definition Mode",
        helpPath: SeatHelpKeys.AdUnitPlaylistDefinitionMode,
        key: "PLAYLIST_DEFINITION_MODE",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.AdUnitPodDeduping,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];
export const BRAND_CHANNEL_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.Description,
        key: "DESCRIPTION",
    },
    {
        label: "Status",
        helpPath: SeatHelpKeys.AdunitStatus,
        key: "STATUS",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
];
export const SUPPLY_FLOORS_PIXELS_HELP_FIELDS = [];
export const SUPPLY_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];
export const SUPPLY_WATERFALL_PREFILTERING_HELP_FIELDS = [
    {
        label: "Seat Prefilter Label",
        helpPath: SeatHelpKeys.LabelsPrefilter,
        key: "SEAT_PREFILTER_LABEL",
    },
];
export const SUPPLY_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];

export const SUPPLY_SUPPLY_DETAILS_HELP_FIELDS = [
    {
        label: "Video Quality",
        helpPath: SeatHelpKeys.SupplyVideoQuality,
        key: "VIDEO_QUALITY",
    },
    {
        label: "Src Relationship",
        helpPath: SeatHelpKeys.SupplySourceRelationship,
        key: "SRC_RELATIONSHIP",
    },
    {
        label: "IFA Mode",
        helpPath: SeatHelpKeys.SupplyIfaMode,
        key: "IFA_MODE",
    },
    {
        label: "Allow VAST Wrappers",
        helpPath: SeatHelpKeys.SupplyAllowVastWrappers,
        key: "ALLOW_VAST_WRAPPERS",
    },
    {
        label: "COPPA",
        helpPath: SeatHelpKeys.SupplyIsCoppa,
        key: "COPPA",
    },
    {
        label: "Privacy Policy",
        helpPath: SeatHelpKeys.SupplyPrivacy,
        key: "PRIVACY_POLICY",
    },
    {
        label: "Viewability Vendors",
        helpPath: SeatHelpKeys.SupplyViewabilityVendors,
        key: "VIEWABILITY_VENDORS",
    },
    {
        label: "Mask IFA for Demands",
        helpPath: SeatHelpKeys.SupplyMaskifafordemand,
        key: "MASK_IFA_FOR_DEMANDS",
    },
];
export const SUPPLY_INTERNAL_HELP_FIELDS = [
    {
        label: "No-Ad Cookie Sync",
        helpPath: SeatHelpKeys.SupplyEnableNoAdCookieSync,
        key: "SUPPLY_ENABLE_NO_AD_COOKIE_SYNC",
    },
    {
        label: "Lower Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "LOWER_CALCULON_USE_RATE_OVERRIDE",
    },
    {
        label: "Upper Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "UPPER_CALCULON_USE_RATE_OVERRIDE",
    },
];
export const SUPPLY_EXCHANGE_SAFETY_HELP_FIELDS = [
    {
        label: "Supply Domain Lists",
        helpPath: SeatHelpKeys.SeatSupplyDomainList,
        key: "SUPPLY_DOMAIN_LISTS",
    },
    {
        label: "App Bundle ID Lists",
        helpPath: SeatHelpKeys.SeatAppBundleIdLists,
        key: "APP_BUNDLE_ID_LISTS",
    },
];
export const SUPPLY_APP_DETAILS_HELP_FIELDS = [
    {
        label: "App Store",
        helpPath: SeatHelpKeys.SupplyAppstore,
        key: "SUPPLY_APPSTORE",
    },
    {
        label: "Bundle ID",
        helpPath: SeatHelpKeys.SupplyBundleId,
        key: "BUNDLE_ID",
    },
    {
        label: "App Name",
        helpPath: SeatHelpKeys.SupplyAppstoreName,
        key: "APP_NAME",
    },
    {
        label: "Store URL",
        helpPath: SeatHelpKeys.SupplyStoreUrl,
        key: "STORE_URL",
    },
    {
        label: "Paid Type",
        helpPath: SeatHelpKeys.SupplyPaidType,
        key: "PAID_TYPE",
    },
    {
        label: "Domain",
        helpPath: SeatHelpKeys.SupplyDomain,
        key: "DOMAIN",
    },
    {
        label: "Inventory Partner Domain",
        helpPath: SeatHelpKeys.SupplyInventoryPartnerDomain,
        key: "INVENTORY_PARTNER_DOMAIN",
    },
];

export const SUPPLY_SITE_DETAILS_HELP_FIELDS = [
    {
        label: "External Name",
        helpPath: SeatHelpKeys.SupplyNameoverride,
        key: "EXTERNAL_NAME",
    },
    {
        label: "Domain",
        helpPath: SeatHelpKeys.SupplyDomain,
        key: "DOMAIN",
    },
    {
        label: "Inventory Partner Domain",
        helpPath: SeatHelpKeys.SupplyInventoryPartnerDomain,
        key: "INVENTORY_PARTNER_DOMAIN",
    },
];
export const SUPPLY_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.Description,
        key: "DESCRIPTION",
    },
    {
        label: "Type",
        helpPath: SeatHelpKeys.SupplyType,
        key: "TYPE",
    },
    {
        label: "Mode",
        helpPath: SeatHelpKeys.SupplyIsDynamic,
        key: "MODE",
    },
];
export const SUPPLY_AFFILIATE_HELP_FIELDS = [
    {
        label: "Affiliate Cost Model",
        helpPath: SeatHelpKeys.SupplyAffiliateCostModel,
        key: "AFFILIATE_COST_MODEL",
    },
    {
        label: "Affiliate Cost Value",
        helpPath: SeatHelpKeys.SupplyAffiliateCostValue,
        key: "AFFILIATE_COST_VALUE",
    },
];

export const BRAND_SUPPLY_DETAILS_HELP_FIELDS = [
    {
        label: "Categories",
        helpPath: SeatHelpKeys.BrandIab,
        key: "CATEGORIES",
    },
    {
        label: "Advertisers as Allow List",
        helpPath: SeatHelpKeys.BrandAdvertisersAllowList,
        key: "ADVERTISERS_AS_ALLOW_LIST",
    },
];

export const BRAND_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.Description,
        key: "DESCRIPTION",
    },
];
export const BRAND_AFFILIATE_HELP_FIELDS = [
    {
        label: "Affiliate Cost Model",
        helpPath: SeatHelpKeys.BrandAffiliateCostModel,
        key: "AFFILIATE_COST_MODEL",
    },
    {
        label: "Affiliate Cost Value",
        helpPath: SeatHelpKeys.BrandAffiliateCostValue,
        key: "AFFILIATE_COST_VALUE",
    },
];

export const BRAND_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain List",
        helpPath: SeatHelpKeys.BlockedADomainLists,
        key: "BLOC_ADVERTISER_DOMAIN_LIST",
    },
    {
        label: "Blocked Advertiser Domains",
        helpPath: SeatHelpKeys.BlockADomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
    {
        label: "Blocked Categories",
        helpPath: SeatHelpKeys.BrandBlockedIabCategories,
        key: "BLOCKED_CATEGORIES",
    },
];
export const BRAND_INTERNAL_HELP_FIELDS = [
    {
        label: "Lower Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "LOWER_CALCULON_USE_RATE_OVERRIDE",
    },
    {
        label: "Upper Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "UPPER_CALCULON_USE_RATE_OVERRIDE",
    },
];

export const PUBLISHER_FLOORS_PIXELS_HELP_FIELDS = [];
export const BRAND_FLOORS_PIXELS_HELP_FIELDS = [];
export const PUBLISHER_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];
export const BRAND_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];
export const PUBLISHER_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];
export const BRAND_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];
export const PUBLISHER_ADVANCED_FEATURES_HELP_FIELDS = [
    {
        label: "Emit Pricing in VAST",
        helpPath: SeatHelpKeys.EmitPricingInVast,
        key: "EMIT_PRICING_IN_VAST",
    },
    {
        label: "Allow DSP VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionDsp,
        key: "ALLOW_DSP_VAST_EXTENSION",
    },
    {
        label: "Allow CRID VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionCrid,
        key: "ALLOW_CRID_VAST_EXTENSION",
    },
    {
        label: "Allow Adomain VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdomain,
        key: "ALLOW_ADOMAIN_VAST_EXTENSION",
    },
    {
        label: "Allow Brand VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionBrand,
        key: "ALLOW_BRAND_VAST_EXTENSION",
    },
    {
        label: "Allow Bid Price VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdomain,
        key: "ALLOW_BID_PRICE_VAST_EXTENSION",
    },
    {
        label: "Allow Ad Source Type VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdsourceType,
        key: "ALLOW_AD_SOURCE_TYPE_VAST_EXTENSION",
    },
    {
        label: "Allow IAB Category VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionIABcat,
        key: "ALLOW_IAB_CATEGORY_VAST_EXTENSION",
    },
];
export const PUBLISHER_INTERNAL_HELP_FIELDS = [
    {
        label: "Billing Code",
        helpPath: SeatHelpKeys.PublisherBillingCode,
        key: "BILLING_CODE",
    },
    {
        label: "Lower Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "LOWER_CALCULON_USE_RATE_OVERRIDE",
    },
    {
        label: "Upper Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "UPPER_CALCULON_USE_RATE_OVERRIDE",
    },
];
export const PUBLISHER_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain List",
        helpPath: SeatHelpKeys.BlockedADomainLists,
        key: "BLOC_ADVERTISER_DOMAIN_LIST",
    },
    {
        label: "Blocked Advertiser Domains",
        helpPath: SeatHelpKeys.BlockADomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
];
export const SUPPLY_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain List",
        helpPath: SeatHelpKeys.BlockedADomainLists,
        key: "BLOC_ADVERTISER_DOMAIN_LIST",
    },
    {
        label: "Blocked Advertiser Domains",
        helpPath: SeatHelpKeys.BlockADomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
];
export const PUBLISHER_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.Description,
        key: "DESCRIPTION",
    },
    {
        label: "Publisher Name",
        helpPath: SeatHelpKeys.PublisherName,
        key: "PUBLISHER_NAME",
    },
    {
        label: "Account Type",
        helpPath: SeatHelpKeys.SeatAccountType,
        key: "ACCOUNT_TYPE",
    },
    {
        label: "Business Domain",
        helpPath: SeatHelpKeys.SeatBusinessDomain,
        key: "BUSINESS_DOMAIN",
    },
    {
        label: "External Comment",
        helpPath: SeatHelpKeys.PublisherExternalComment,
        key: "EXTERNAL_COMMENT",
    },
];
export const PUBLISHER_AFFILIATE_HELP_FIELDS = [
    {
        label: "Affiliate Cost Model",
        helpPath: SeatHelpKeys.PublisherAffiliateCostModel,
        key: "AFFILIATE_COST_MODEL",
    },
    {
        label: "Affiliate Cost Value",
        helpPath: SeatHelpKeys.PublisherAffiliateCostValue,
        key: "AFFILIATE_COST_VALUE",
    },
];

export const PUBLISHER_CHANNEL_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Code Type",
        helpPath: SeatHelpKeys.CodeType,
        key: "CODE_TYPE",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.Description,
        key: "DESCRIPTION",
    },
    {
        label: "Account Type",
        helpPath: SeatHelpKeys.SeatAccountType,
        key: "ACCOUNT_TYPE",
    },
    {
        label: "Billing Code",
        helpPath: SeatHelpKeys.PublisherBillingCode,
        key: "BILLING_CODE",
    },
];

export const PUBLISHER_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Fill Mode",
        helpPath: SeatHelpKeys.AdunitAdpodFillMode,
        key: "FILL_MODE",
    },
    {
        label: "Pod Enforcement",
        helpPath: SeatHelpKeys.AdunitPodEnforcement,
        key: "POD_ENFORCEMENT",
    },
    {
        label: "Competitive Separation Mode",
        helpPath: SeatHelpKeys.AdunitSeparationGroupMode,
        key: "COMPETITIVE_SEPARATION_MODE",
    },
    {
        label: "Competitive Separation Groups",
        helpPath: SeatHelpKeys.AdUnitCompetitiveSeparation,
        key: "COMPETITIVE_SEPARATION_GROUPS",
    },
    {
        label: "Max Ads per Advertiser",
        helpPath: SeatHelpKeys.AdunitMaxadsPerAdvertiser,
        key: "MAX_ADS_PER_ADVERTISER",
    },
    {
        label: "Playlist Definition Mode",
        helpPath: SeatHelpKeys.AdUnitPlaylistDefinitionMode,
        key: "PLAYLIST_DEFINITION_MODE",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.AdUnitPodDeduping,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];

export const PUBLISHER_CHANNEL_DETAIL_CONFIGURATION_FEATURES_HELP_FIELDS = [
    {
        label: "Publisher Name",
        helpPath: SeatHelpKeys.PublisherName,
        key: "PUBLISHER_NAME",
    },
    {
        label: "Supply Name",
        helpPath: SeatHelpKeys.SupplyNameoverride,
        key: "SUPPLY_NAME",
    },
    {
        label: "Business Domain",
        helpPath: SeatHelpKeys.SeatBusinessDomain,
        key: "BUSINESS_DOMAIN",
    },
    {
        label: "Inventory Partner Domain",
        helpPath: SeatHelpKeys.SupplyInventoryPartnerDomain,
        key: "INVENTORY_PARTNER_DOMAIN",
    },
    {
        label: "Allow VAST Wrappers",
        helpPath: SeatHelpKeys.SupplyAllowVastWrappers,
        key: "ALLOW_VAST_WRAPPERS",
    },
    {
        label: "Live Stream Acceleration",
        helpPath: SeatHelpKeys.SeatEnableLsa,
        key: "LIVE_STREAM_ACCELERATION",
    },
    {
        label: "Advertisers as Allow List",
        helpPath: SeatHelpKeys.SeatEnableAdvertisersAsWhitelist,
        key: "ADVERTISERS_AS_ALLOW_LISt",
    },
    {
        label: "Publisher Re-Auction",
        helpPath: SeatHelpKeys.AdUnitPublisherReauction,
        key: "PUBLISHER_RE-AUCTION",
    },
    {
        label: "Unwrap VAST",
        helpPath: SeatHelpKeys.AdunitUnwrapVast,
        key: "UNWRAP_VAST",
    },
    {
        label: "Extended Imp Wait Time",
        helpPath: SeatHelpKeys.DefaultExtendedImpressionWaitTime,
        key: "EXTENDED_IMPRESSION_WAIT_TIME",
    },
    {
        label: "Advertiser Frequency Capping",
        helpPath: SeatHelpKeys.AdunitAdvFreqCapping,
        key: "ADVERTISER_FREQUENCY_CAPPING",
    },
    {
        label: "UD Opt-Out",
        helpPath: SeatHelpKeys.AdUnitUdoptOut,
        key: "AD_UNIT_UDOPT_OUT",
    },
];

// Advanced Features Tabe
export const ADVANCED_FEATURES_ADMIN_TOOLS_HELP_FIELDS = [
    {
        label: "Alerts Enabled",
        helpPath: SeatHelpKeys.SeatAlertsEnabled,
        key: "ALERTS_ENABLED",
    },
    {
        label: "Allow Or Targeting",
        helpPath: SeatHelpKeys.SeatAllowOrTargeting,
        key: "ALLOW_OR_TARGETING",
    },
    {
        label: "Enable Creative Test Mode",
        helpPath: SeatHelpKeys.SeatEnableTestCreative,
        key: "TEST_CREATIVES_ENABLED",
    },
    {
        label: "Click Tracking",
        helpPath: SeatHelpKeys.SeatClickTracking,
        key: "CLICK_TRACKING",
    },
    {
        label: "Test Creative Id",
        helpPath: SeatHelpKeys.SeatTestCreativeId,
        key: "TEST_CREATIVE_ID",
    },
    {
        label: "Allow VAST Unwrapping",
        helpPath: SeatHelpKeys.SeatAllowVastUnwrapping,
        key: "ALLOW_VAST_UNWRAPPING",
    },
    {
        label: "Data Lock",
        helpPath: SeatHelpKeys.SeatDatalock,
        key: "DATA_LOCK",
    },
    {
        label: "Allowed Data Lock Domains",
        helpPath: SeatHelpKeys.SeatAllowedDataLockDomains,
        key: "ALLOWED_DATA_LOCK_DOMAINS",
    },
];
export const ADVANCED_FEATURES_UNIFIED_DECISIONING_HELP_FIELDS = [
    {
        label: "UD Enabled",
        helpPath: SeatHelpKeys.SeatTimezone,
        key: "UD_ENABLED",
    },
    {
        label: "UD LID Discovery Mode",
        helpPath: SeatHelpKeys.SeatUdlidMode,
        key: "UD_LID_DISCOVERY_MODE",
    },
    {
        label: "UD Fee",
        helpPath: SeatHelpKeys.SeatEnableUd,
        key: "UD_FEE",
    },

    {
        label: "Allow Blind Competition",
        helpPath: SeatHelpKeys.SeatUdBlindCompetition,
        key: "ALLOW_BLIND_COMPETITION",
    },
    {
        label: "Mediation Enabled",
        helpPath: SeatHelpKeys.SeatMediationEnabled,
        key: "MEDIATION_ENABLED",
    },
];
export const ADVANCED_FEATURES_DEMAND_MANAGEMENT_HELP_FIELDS = [
    {
        label: "Authorized Marketplaces",
        helpPath: SeatHelpKeys.SeatAuthorizedMarketplaces,
        key: "AUTHORIZED_MARKETPLACES",
    },
    {
        label: "Allow Forward Market Deal Blocks",
        helpPath: SeatHelpKeys.SeatAllowForwardMarketDealBlocks,
        key: "ALLOW_FORWARD_MARKET_DEAL_BLOCKS",
    },
    {
        label: "ASAP Feathering",
        helpPath: SeatHelpKeys.SeatAsapFeatheringEnabled,
        key: "ASAP_FEATHERING",
    },
    {
        label: "External Data Streams",
        helpPath: SeatHelpKeys.SeatAuthorizedExportStreamsList,
        key: "EXTERNAL_DATA_STREAMS",
    },
    {
        label: "Allow High CPM",
        helpPath: SeatHelpKeys.SeatAllowHighCpm,
        key: "ALLOW_HIGH_CPM",
    },
    {
        label: "Publisher Ad Serving",
        helpPath: SeatHelpKeys.SeatEnableAdServing,
        key: "PUBLISHER_AD_SERVING",
    },
    {
        label: "Allow Take Rate Transparency",
        helpPath: SeatHelpKeys.SeatAllowTakeRateTransparency,
        key: "ALLOW_TAKE_RATE_TRANSPARENCY",
    },
    {
        label: "Enable Demand Acquisition Cost",
        helpPath: SeatHelpKeys.seatDacEnabled,
        key: "ENABLE_DEMAND_ACQUISITION_COST",
    },
    {
        label: "Honor Supply Floor",
        helpPath: SeatHelpKeys.SeatHonorsupplyfloor,
        key: "HONOR_SUPPLY_FLOOR",
    },
];
export const ADVANCED_FEATURES_AD_RESPONSES_HELP_FIELDS = [
    {
        label: "Emit Pricing in VAST",
        helpPath: SeatHelpKeys.EmitPricingInVast,
        key: "EMIT_PRICING_IN_VAST",
    },
    {
        label: "Allow Adomain VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdomain,
        key: "ALLOW_ADOMAIN_VAST_EXTENSION",
    },
    {
        label: "Allow Ad Source Type VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdsourceType,
        key: "ALLOW_AD_SOURCE_TYPE_VAST_EXTENSION",
    },
    {
        label: "Allow DSP VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionDsp,
        key: "ALLOW_DSP_VAST_EXTENSION",
    },
    {
        label: "Allow Brand VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionBrand,
        key: "ALLOW_BRAND_VAST_EXTENSION",
    },
    {
        label: "Allow IAB Category VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionIABcat,
        key: "ALLOW_IAB_CATEGORY_VAST_EXTENSION",
    },
    {
        label: "Allow CRID VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtenstionCrid,
        key: "ALLOW_CRID_VAST_EXTENSION",
    },
    {
        label: "Allow Bid Price VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdomain,
        key: "ALLOW_BID_PRICE_VAST_EXTENSION",
    },
    {
        label: "Allowed FMT=JSON Options",
        helpPath: SeatHelpKeys.SeatAllowedFMTJsonOptions,
        key: "ALLOWED_FMT=JSON_OPTIONS",
    },
    {
        label: "Allow Ad Source Name VAST Extension",
        helpPath: SeatHelpKeys.SeatAllowVastExtensionAdSourceName,
        key: "ALLOW_AD_SOURCE_NAME_VAST_EXTENSION",
    },
];
export const ADVANCED_FEATURES_CREATIVE_CONTROL_HELP_FIELDS = [
    {
        label: "Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "CALCULON_USE_RATE_OVERRIDE",
    },
    {
        label: "Advertisers Allow List",
        helpPath: SeatHelpKeys.SeatAdvertisersAllowlist,
        key: "ADVERTISERS_ALLOW_LIST",
    },
    {
        label: "Upper Calculon Use Rate Override",
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: "UPPER_CALCULON_USE_RATE_OVERRIDE",
    },
];

export const ADVANCED_FEATURES_IDENTITY_AND_REGULATIONS_HELP_FIELDS = [
    {
        label: "DMP Creation Enabled",
        helpPath: SeatHelpKeys.SeatDmpCreationEnabled,
        key: "DMP_CREATION_ENABLED",
    },
    {
        label: "Enabled DMP List",
        helpPath: SeatHelpKeys.SeatEnabledDmpLists,
        key: "ENABLED_DMP_LIST",
    },
    {
        label: "IDL Enabled",
        helpPath: SeatHelpKeys.SeatIdlEnabled,
        key: "IDL_ENABLED",
    },
    {
        label: "BVOD Connect",
        helpPath: SeatHelpKeys.SeatBVODConnect,
        key: "BVOD_CONNECT",
    },
    {
        label: "Mask Device ID",
        helpPath: SeatHelpKeys.SeatMaskIfa,
        key: "MASK_DEVICE_ID",
    },

    {
        label: "Google GDPR Consent All",
        helpPath: SeatHelpKeys.SeatGdrpGoogleConsentAll,
        key: "GOOGLE_GDPR_CONSENT_ALL",
    },
    {
        label: "Enable Audience Targeting",
        helpPath: SeatHelpKeys.SeatEnableAudienceTargeting,
        key: "ENABLE_AUDIENCE_TARGETING",
    },
    {
        label: "Enable Magnite Match",
        helpPath: SeatHelpKeys.SeatEnableMagniteMatch,
        key: "ENABLE_MAGNITE_MATCH",
    },
    {
        label: "Enable Liveramp ATS",
        helpPath: SeatHelpKeys.SeatLiverampAtsEnabled,
        key: "LIVERAMP_ATS_ENABLED",
    },
];

export const ADVANCED_FEATURES_INVENTORY_MANAGEMENT_HELP_FIELDS = [
    {
        label: "SSP Connect",
        helpPath: SeatHelpKeys.SSPConnect,
        key: "SSP_CONNECT",
    },
    {
        label: "Channels Enable",
        helpPath: SeatHelpKeys.EnabledChannels,
        key: "CHANNELS",
    },
    {
        label: "Inventory Distribution Groups",
        helpPath: SeatHelpKeys.SeatDistributionGroupsEnabled,
        key: "INVENTORY_DISTRIBUTION_GROUPS",
    },
    {
        label: "Enabled External Code Namespaces",
        helpPath: SeatHelpKeys.EnabledExternalCodeNamespaces,
        key: "ENABLED_EXTERNAL_CODE_NAMESPACES",
    },
    {
        label: "Authorized PBS Servers",
        helpPath: SeatHelpKeys.seatAuthorizePbsServers,
        key: "AUTHORIZED_PBS_SERVERS",
    },
];
export const ADVANCED_FEATURES_CTV_TOOLS_HELP_FIELDS = [
    {
        label: "Content Metadata Enabled",
        helpPath: SeatHelpKeys.SeatEnableContentMetaData,
        key: "CONTENT_METADATA",
    },
    {
        label: "Content Metadata Keys",
        helpPath: SeatHelpKeys.SeatContentMetadataKeys,
        key: "CONTENT_METADATA_KEYS",
    },
    {
        label: "Show Ad Pods",
        helpPath: SeatHelpKeys.SeatEnableAdPods,
        key: "SHOW_AD_PODS",
    },
    {
        label: "Linear TV",
        helpPath: SeatHelpKeys.EnablelinearTv,
        key: "LINEAR_TV",
    },
    {
        label: "Live Stream Acceleration",
        helpPath: SeatHelpKeys.SeatEnableLsa,
        key: "LIVE_STREAM_ACCELERATION",
    },
    {
        label: "Pod Deduping Level Override",
        helpPath: SeatHelpKeys.SeatPodDedupeLevel,
        key: "POD_DEDUPE_LEVEL_OVERRIDE",
    },
];

// Exchange Protections Tab
export const EXCHANGE_PROTECTIONS_QUALITY_CONTROL_FIELDS = [
    {
        label: "Fraud Detection",
        helpPath: SeatHelpKeys.SeatFraudDetection,
        key: "FRAUD_DETECTION",
    },
    {
        label: "Supply Domain Filter Mode",
        helpPath: SeatHelpKeys.SeatSupplyDomainFilterMode,
        key: "SUPPLY_DOMAIN_FILTER_MODE",
    },
    {
        label: "Supply Domain List",
        helpPath: SeatHelpKeys.SeatSupplyDomainList,
        key: "SUPPLY_DOMAIN_LIST",
    },
    {
        label: "App Bundle ID Filter Mode",
        helpPath: SeatHelpKeys.SeatAppBundleIdFilterMode,
        key: "APP_BUNDLE_ID_FILTER_MODE",
    },
    {
        label: "App Bundle Id Lists",
        helpPath: SeatHelpKeys.SeatAppBundleIdLists,
        key: "APP_BUNDLE_ID_LISTS",
    },
];
export const EXCHANGE_PROTECTIONS_INVENTORY_LIMITS_HELP_FIELDS = [
    {
        label: "Ad Source Type Limits",
        helpPath: SeatHelpKeys.SeatAdsourceTypeLimits,
        key: "AD_SOURCE_TYPE_LIMITS",
    },
    {
        label: "QPS Limits",
        helpPath: SeatHelpKeys.SeatQPSLimit,
        key: "QPS_LIMITS",
    },
    {
        label: "Max Ads / User in 1 Minute",
        helpPath: SeatHelpKeys.SeatRequestGovernorLimitPerMin,
        key: "MAX_ADS_USER_IN_1_MINUTE",
    },
    {
        label: "Tier 2 Minimum QPS Limit %",
        helpPath: SeatHelpKeys.SeatSmartThrottleMinQps,
        key: "TIER_2_MINIMUM_QPS_LIMIT_%",
    },
    {
        label: "Tier 3 Minimum QPS Limit %",
        helpPath: SeatHelpKeys.SeatSmartThrottleMinQps,
        key: "TIER_3_MINIMUM_QPS_LIMIT_%",
    },
    {
        label: "Moka Integration",
        helpPath: SeatHelpKeys.SeatMokaEnabled,
        key: "MOKA_INTEGRATION",
    },
];
export const SEAT_PERFORMANCE_HELP_FIELDS = [
    {
        label: "Rejected Requests",
        helpPath: SeatHelpKeys.RejectedRequests,
        key: "REJECTED_REQUESTS",
    },
    {
        label: "Requests",
        helpPath: SeatHelpKeys.Requests,
        key: "REQUESTS",
    },
    {
        label: "Ad Pod Requests",
        helpPath: SeatHelpKeys.AdPodRequests,
        key: "AD_POD_REQUESTS",
    },
    {
        label: "Playlist Requests ",
        helpPath: SeatHelpKeys.PlaylistRequests,
        key: "PLAYLIST_REQUESTS ",
    },
    {
        label: "Pod Slot Requests ",
        helpPath: SeatHelpKeys.PodSlotRequests,
        key: "POD_SLOT_REQUESTS ",
    },
    {
        label: "Fills",
        helpPath: SeatHelpKeys.Fills,
        key: "FILLS",
    },
    {
        label: "Impressions",
        helpPath: SeatHelpKeys.Impressions,
        key: "IMPRESSIONS",
    },
    {
        label: "Gross Revenue",
        helpPath: SeatHelpKeys.GrossRevenue,
        key: "GROSS_REVENUE",
    },
    {
        label: "Net Revenue",
        helpPath: SeatHelpKeys.NetRevenue,
        key: "NET_REVENUE",
    },
    {
        label: "Fill Rate",
        helpPath: SeatHelpKeys.FillRate,
        key: "FILL_RATE",
    },
    {
        label: "Use Rate",
        helpPath: SeatHelpKeys.UseRate,
        key: "USE_RATE",
    },
    {
        label: "Avg. Fill Time",
        helpPath: SeatHelpKeys.AvgFillTime,
        key: "AVG_FILL_TIME",
    },
    {
        label: "Breaks Per Playlist",
        helpPath: SeatHelpKeys.BreaksPerPlaylist,
        key: "BREAKS_PER_PLAYLIST",
    },
    {
        label: "Slots Per Break",
        helpPath: SeatHelpKeys.SlotsPerBreak,
        key: "SLOTS_PER_BREAK",
    },
    {
        label: "Slots Per Playlist",
        helpPath: SeatHelpKeys.SlotsPerPlaylist,
        key: "SLOTS_PER_PLAYLIST",
    },
    {
        label: "LSA Pod Requests",
        helpPath: SeatHelpKeys.LSAPodRequests,
        key: "LSA_POD_REQUESTS",
    },
    {
        label: "LSA Slots Requests",
        helpPath: SeatHelpKeys.LSASlotsRequests,
        key: "LSA_SLOTS_REQUESTS",
    },
    {
        label: "LSA Potential Fills",
        helpPath: SeatHelpKeys.LSAPotentialFills,
        key: "LSA_POTENTIAL_FILLS",
    },
    {
        label: "LSA Fills",
        helpPath: SeatHelpKeys.LSAFills,
        key: "LSA_FILLS",
    },
    {
        label: "LSA Imps",
        helpPath: SeatHelpKeys.LSAImps,
        key: "LSA_IMPS",
    },
];

export const SEAT_GENERAL_HELP_FIELDS = [
    {
        label: "Name",
        helpPath: SeatHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Code",
        helpPath: SeatHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Date Created",
        helpPath: SeatHelpKeys.CreationDate,
        key: "DATE_CREATED",
    },
    {
        label: "Last Updated",
        helpPath: SeatHelpKeys.UpdatedDate,
        key: "LAST_UPDATED",
    },
    {
        label: "Last Indexed",
        helpPath: SeatHelpKeys.LastIndexed,
        key: "LAST_INDEXED",
    },
    {
        label: "Business Domain",
        helpPath: SeatHelpKeys.SeatBusinessDomain,
        key: "BUSINESS_DOMAIN",
    },
    {
        label: "Description",
        helpPath: SeatHelpKeys.SeatDescription,
        key: "DESCRIPTION",
    },
    {
        label: "Account Type",
        helpPath: SeatHelpKeys.SeatAccountType,
        key: "ACCOUNT_TYPE",
    },
    {
        label: "Client Type",
        helpPath: SeatHelpKeys.SeatClientType,
        key: "CLIENT_TYPE",
    },
    {
        label: "Region",
        helpPath: SeatHelpKeys.SeatRegion,
        key: "REGION",
    },
    {
        label: "Support Tier",
        helpPath: SeatHelpKeys.SeatSupportTier,
        key: "SUPPORT_TIER",
    },
    {
        label: "MVPD",
        helpPath: SeatHelpKeys.SeatIsMvpd,
        key: "MVPD",
    },
    {
        label: "Fill Guaranteed",
        helpPath: SeatHelpKeys.SeatFillGuaranteed,
        key: "FILL_GUARANTEED",
    },
];

export const SEAT_FINANCIAL_HELP_FIELDS = [
    {
        label: "Default Billing Code",
        helpPath: SeatHelpKeys.PublisherBillingCode,
        key: "DEFAULT_BILLING_CODE",
    },
    {
        label: "Affiliate Cost Enabled",
        helpPath: SeatHelpKeys.SeatAffiliateCostEnabled,
        key: "AFFILIATE_COST_ENABLED",
    },
    {
        label: "Cost Model",
        helpPath: SeatHelpKeys.SeatAffiliateCostModel,
        key: "COST_MODEL",
    },
    {
        label: "Cost Value",
        helpPath: SeatHelpKeys.SeatAffiliateCostValue,
        key: "COST_VALUE",
    },
    {
        label: "Curency",
        helpPath: SeatHelpKeys.SeatCurrency,
        key: "currency",
    },
    {
        label: "Fallback Floor",
        helpPath: SeatHelpKeys.SeatFallbackFloor,
        key: "FALLBACK_FLOOR",
    },
];

export const SEAT_DEFAULTS_HELP_FIELDS = [
    {
        label: "Time Zone",
        helpPath: SeatHelpKeys.SeatTimezone,
        key: "TIME_ZONE",
    },
    {
        label: "Ad Source Auction Type Default",
        helpPath: SeatHelpKeys.SeatAdSourceAuctionTypeDefault,
        key: "AD_SOURCE_AUCTION_TYPE_DEFAULT",
    },
    {
        label: "Ad Source Min Duration",
        helpPath: SeatHelpKeys.SeatAdSourceMinDefault,
        key: "AD_SOURCE_MIN_DEFAULT",
    },
    {
        label: "Ad Source Max Duration",
        helpPath: SeatHelpKeys.SeatAdSourceMaxDefault,
        key: "AD_SOURCE_MAX_DEFAULT",
    },
    {
        label: "Default Extended Impression Wait Time",
        helpPath: SeatHelpKeys.DefaultExtendedImpressionWaitTime,
        key: "DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME",
    },
];

export const SEAT_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Block Advertiser Domain Lists",
        helpPath: SeatHelpKeys.AdUnitAdomainFilterList,
        key: "BLOCK_ADVERTISER_DOMAIN_LISTS",
    },
    {
        label: "Blocked Advertiser Domains",
        helpPath: SeatHelpKeys.AdUnitBlockedDomains,
        key: "BLOCKED_ADVERTISER_DOMAINS",
    },
    {
        label: "Block Buyer Seat List",
        helpPath: SeatHelpKeys.AdUnitBuyerSeatList,
        key: "BLOCK_BUYER_SEAT_LIST",
    },
];

export const SEAT_CUSTOM_PIXELS_HELP_FIELDS = [
    {
        label: "Custom Pixels",
        helpPath: SeatHelpKeys.ThirdPartyPixels,
        key: "CUSTOM_PIXELS",
    },
];

export const SEAT_LABELS_HELP_FIELDS = [
    {
        label: "Seat Labels",
        helpPath: SeatHelpKeys.Labels,
        key: "SEAT_LABELS",
    },
    {
        label: "Global Labels",
        helpPath: SeatHelpKeys.GlobalLabels,
        key: "GLOBAL_LABELS",
    },
];

export const seatDetailsHelpKeys = {
    [SeatDetailsDrawerTitles.Performance]: SEAT_PERFORMANCE_HELP_FIELDS,
    [SeatDetailsDrawerTitles.General]: SEAT_GENERAL_HELP_FIELDS,
    [SeatDetailsDrawerTitles.Financial]: SEAT_FINANCIAL_HELP_FIELDS,
    [SeatDetailsDrawerTitles.SeatDefaults]: SEAT_DEFAULTS_HELP_FIELDS,
    [SeatDetailsDrawerTitles.BrandSafety]: SEAT_BRAND_SAFETY_HELP_FIELDS,
    [SeatDetailsDrawerTitles.CustomPixels]: SEAT_CUSTOM_PIXELS_HELP_FIELDS,
    [SeatDetailsDrawerTitles.Labels]: SEAT_LABELS_HELP_FIELDS,
};
export const seatExchangeProtectionsHelpKeys = {
    [ExchangeProtectionsDrawerTitles.Limits]: EXCHANGE_PROTECTIONS_INVENTORY_LIMITS_HELP_FIELDS,
    [ExchangeProtectionsDrawerTitles.Control]: EXCHANGE_PROTECTIONS_QUALITY_CONTROL_FIELDS,
};
export const publisherHelpKeys = {
    [PublisherDrawerTitles.General]: PUBLISHER_GENERAL_HELP_FIELDS,
    [PublisherDrawerTitles.Affiliate]: PUBLISHER_AFFILIATE_HELP_FIELDS,
    [PublisherDrawerTitles.BrandSafety]: PUBLISHER_BRAND_SAFETY_HELP_FIELDS,
    [PublisherDrawerTitles.Internal]: PUBLISHER_INTERNAL_HELP_FIELDS,
    [PublisherDrawerTitles.AdvancedFeatures]: PUBLISHER_ADVANCED_FEATURES_HELP_FIELDS,
    [PublisherDrawerTitles.CustomPixels]: PUBLISHER_CUSTOM_PIXELS_HELP_FIELDS,
    [PublisherDrawerTitles.Labels]: PUBLISHER_LABELS_HELP_FIELDS,
    [PublisherDrawerTitles.Floors]: PUBLISHER_FLOORS_PIXELS_HELP_FIELDS,
};
export const publisherChannelHelpKeys = {
    [BrandChannelTitles.General]: PUBLISHER_CHANNEL_GENERAL_HELP_FIELDS,
    [BrandChannelTitles.Defaults]: BRAND_CHANNEL_DEFAULTS_HELP_FIELDS,
    [BrandChannelTitles.PlaylistConfiguration]: PUBLISHER_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS,
    [BrandChannelTitles.DetailConfiguration]: PUBLISHER_CHANNEL_DETAIL_CONFIGURATION_FEATURES_HELP_FIELDS,
};
export const brandHelpKeys = {
    [BrandDrawerTitles.General]: BRAND_GENERAL_HELP_FIELDS,
    [BrandDrawerTitles.Affiliate]: BRAND_AFFILIATE_HELP_FIELDS,
    [BrandDrawerTitles.BrandSafety]: BRAND_BRAND_SAFETY_HELP_FIELDS,
    [BrandDrawerTitles.Internal]: BRAND_INTERNAL_HELP_FIELDS,
    [BrandDrawerTitles.SupplyDetails]: BRAND_SUPPLY_DETAILS_HELP_FIELDS,
    [BrandDrawerTitles.CustomPixels]: BRAND_CUSTOM_PIXELS_HELP_FIELDS,
    [BrandDrawerTitles.Labels]: BRAND_LABELS_HELP_FIELDS,
    [BrandDrawerTitles.Floors]: BRAND_FLOORS_PIXELS_HELP_FIELDS,
};
export const brandChannelHelpKeys = {
    [BrandChannelTitles.General]: BRAND_CHANNEL_GENERAL_HELP_FIELDS,
    [BrandChannelTitles.Defaults]: BRAND_CHANNEL_DEFAULTS_HELP_FIELDS,
    [BrandChannelTitles.AdPod]: BRAND_CHANNEL_AD_POD_HELP_FIELDS,
    [BrandChannelTitles.PlaylistConfiguration]: BRAND_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS,
    [BrandChannelTitles.BrandSafety]: BRAND_CHANNEL_BRAND_SAFETY_HELP_FIELDS,
    [BrandChannelTitles.DetailConfiguration]: BRAND_CHANNEL_DETAIL_CONFIGURATION_HELP_FIELDS,
    [BrandChannelTitles.CustomPixels]: BRAND_CHANNEL_CUSTOM_PIXELS_HELP_FIELDS,
    [BrandChannelTitles.Labels]: BRAND_CHANNEL_LABELS_HELP_FIELDS,
    [BrandChannelTitles.Floors]: BRAND_CHANNEL_PIXELS_HELP_FIELDS,
};
export const supplyHelpKeys = {
    [SupplyDrawerTitles.General]: SUPPLY_GENERAL_HELP_FIELDS,
    [SupplyDrawerTitles.Affiliate]: SUPPLY_AFFILIATE_HELP_FIELDS,
    [SupplyDrawerTitles.BrandSafety]: SUPPLY_BRAND_SAFETY_HELP_FIELDS,
    [SupplyDrawerTitles.SiteDetails]: SUPPLY_SITE_DETAILS_HELP_FIELDS,
    [SupplyDrawerTitles.AppDetails]: SUPPLY_APP_DETAILS_HELP_FIELDS,
    [SupplyDrawerTitles.ExchangeSafety]: SUPPLY_EXCHANGE_SAFETY_HELP_FIELDS,
    [SupplyDrawerTitles.Internal]: SUPPLY_INTERNAL_HELP_FIELDS,
    [SupplyDrawerTitles.SupplyDetails]: SUPPLY_SUPPLY_DETAILS_HELP_FIELDS,
    [SupplyDrawerTitles.CustomPixels]: SUPPLY_CUSTOM_PIXELS_HELP_FIELDS,
    [SupplyDrawerTitles.Labels]: SUPPLY_LABELS_HELP_FIELDS,
    [SupplyDrawerTitles.WaterfallPrefiltering]: SUPPLY_WATERFALL_PREFILTERING_HELP_FIELDS,
    [SupplyDrawerTitles.Floors]: SUPPLY_FLOORS_PIXELS_HELP_FIELDS,
};
export const adUnitHelpKeys = {
    [adUnitDrawerTitles.General]: AD_UNIT_GENERAL_HELP_FIELDS,
    [adUnitDrawerTitles.Affiliate]: AD_UNIT_AFFILIATE_HELP_FIELDS,
    [adUnitDrawerTitles.BrandSafety]: AD_UNIT_BRAND_SAFETY_HELP_FIELDS,
    [adUnitDrawerTitles.Internal]: AD_UNIT_INTERNAL_HELP_FILEDS,
    [adUnitDrawerTitles.DemandRequirements]: AD_UNIT_DEMAND_REQUIREMENTS,
    [adUnitDrawerTitles.SupplyDetails]: AD_UNIT_SUPPLY_DETAILS_HELP_FIELDS,
    [adUnitDrawerTitles.UD]: AD_UNIT_UD_CONFIGURATION_HELP_FIELDS,
    [adUnitDrawerTitles.AdPod]: AD_UNIT_AD_POD_HELP_FIELDS,
    [adUnitDrawerTitles.CustomPixels]: AD_UNIT_CUSTOM_PIXELS_HELP_FIELDS,
    [adUnitDrawerTitles.Floors]: AD_UNIT_FLOORS_PIXELS_HELP_FIELDS,
    [adUnitDrawerTitles.Labels]: AD_UNIT_LABELS_HELP_FIELDS,
    [adUnitDrawerTitles.PlaylistConfiguration]: AD_UNIT_PLAYLIST_CONFIGURATION_HELP_FIELDS,
};
export const seatAdvancedFeaturesHelpKeys = {
    [AdvancedFeaturesDrawerTitles.InventoryManagement]: ADVANCED_FEATURES_INVENTORY_MANAGEMENT_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.CTVTools]: ADVANCED_FEATURES_CTV_TOOLS_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.IdentityAndRegulations]: ADVANCED_FEATURES_IDENTITY_AND_REGULATIONS_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.CreativeControl]: ADVANCED_FEATURES_CREATIVE_CONTROL_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.AdResponses]: ADVANCED_FEATURES_AD_RESPONSES_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.DemandManagement]: ADVANCED_FEATURES_DEMAND_MANAGEMENT_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.UnifiedDecisioning]: ADVANCED_FEATURES_UNIFIED_DECISIONING_HELP_FIELDS,
    [AdvancedFeaturesDrawerTitles.AdminTools]: ADVANCED_FEATURES_ADMIN_TOOLS_HELP_FIELDS,
};

export const INVENTORY_SEAT_DEFAULT_CURRENCY = { value: 1, label: "USD" };
export const PREBID_AD_SOURCE_EXTERNAL_AD_UNIT_CODE_NAMESPACE = 9;
export const INVENTORY_SEAT_DEFAULT_UDE_CPM_FEE = 0;
export const COLUMN_KEY_COST = "cost";
export const COLUMN_KEY_COST_MODEL = "costModel";
export const COLUMN_KEY_TYPE = "type";
export const COLUMN_KEY_ACTIONS = "actions";
export const COLUMN_KEY_INVENTORY_NAME = "inventoryName";
export const COLUMN_KEY_SOURCE = "source";
export const COLUMN_KEY_COUNTRY = "country";
export const COLUMN_KEY_PRICE = "price";

export const DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY = {
    COST: { dataIndex: "", key: COLUMN_KEY_COST, title: "Price", fixed: "left" as const, width: "15%" },
    COST_MODEL: { dataIndex: "", key: COLUMN_KEY_COST_MODEL, title: "Price Model", width: 350 },
    TYPE: { dataIndex: "", key: COLUMN_KEY_TYPE, title: "Type" },
    ACTIONS: { title: "Actions", key: COLUMN_KEY_ACTIONS, dataIndex: "", fixed: "right" as const, width: 180 },
};
export const FLOORS_TABLE_COLUMNS = [
    {
        width: "20%",
        title: "Name",
        dataIndex: COLUMN_KEY_INVENTORY_NAME,
        fixed: "left" as const,
    },
    {
        title: "Inherited Source",
        dataIndex: COLUMN_KEY_SOURCE,
    },
    {
        title: "Country",
        dataIndex: COLUMN_KEY_COUNTRY,
        sorter: (a, b) =>
            String(a.country.props.record.country?.label || "All Geos").localeCompare(
                String(b.country.props.record.country?.label || "All Geos")
            ),
    },
    {
        title: "Floor Price",
        dataIndex: COLUMN_KEY_PRICE,
        sorter: (a, b) => a.price.props.record.price - b.price.props.record.price,
    },
    { title: "Actions", dataIndex: COLUMN_KEY_ACTIONS, width: 182, fixed: "right" as const },
];

export const INVENTORY_HEADER_COLLAPSE_CLASS_LIST = css`
    .ant-typography {
        margin-top: 0;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-item > .ant-collapse-header {
        padding: 8px 24px;
        align-items: center;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
        height: 32px;
    }

    .ant-card-body {
        padding: 16px 56px;
    }

    margin: 16px -24px;
`;
export const INVENTORY_NESTED_SECTION_CLASSLIST = css`
    .ant-collapse-item {
        border-bottom: none;
    }

    .ant-collapse-item > .ant-collapse-header {
        padding: 8px 24px;
    }

    .ant-collapse-header-text h5 {
        font-size: 14px;
    }
`;
export const INVENTORY_SECTION_HEADER_COLLAPSE_STYLE = {
    borderTop: `1px solid ${gray5}`,
};
export const INVENTORY_HEALTH_NESTED_TABLES_STYLES = css`
    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
            [colspan]
        )::before {
        width: 0;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        padding-left: 48px;
    }
`;

export const INVENTORY_HEALTH_TABLE_COLUMN_WIDTH = 140;
export const INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF = INVENTORY_HEALTH_TABLE_COLUMN_WIDTH / 2;

export const SEAT_DETAILS_SECTION_COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
