import {
    InventoryDistributionGroup,
    Label,
    useGetSeatLabelsQuery,
    useLazyGetLabelValuesQuery,
} from "@app/core/services";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { InventoryDistributionGroupsFieldName } from "@app/features/seatAdSources/constants";

export interface LabelWithLabelValue {
    id: number;
    label: Label;
    value: string;
}

export const useDistributionGroupsOptions = (
    values: InventoryDistributionGroup[],
    onChange?: (values: InventoryDistributionGroup[]) => void
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: allLabels, isFetching: isFetchingAllLabels } = useGetSeatLabelsQuery({ seatId: Number(seatId) });
    const [isFetchingLabelValues, setIsFetchingLabelValues] = useState(false);
    const [options, setOptions] = useState<LabelWithLabelValue[]>([]);
    const [getLabelValues] = useLazyGetLabelValuesQuery();

    const valueIdSet = useMemo(() => new Set(values.map((value) => value.labelValue?.label?.id)), [values]);

    useEffect(() => {
        setIsFetchingLabelValues(true);
        const distroGroupLabels = (allLabels || []).filter((label) => label.isDistributionGroup);
        Promise.all(distroGroupLabels.map((label) => getLabelValues(label.id).unwrap())).then((labelValues) => {
            const labelsWithValues = distroGroupLabels
                .map((label, index) => {
                    const value = labelValues[index][0] || null;

                    return {
                        id: value?.id || 0,
                        label,
                        value: value?.value || "",
                    };
                })
                .filter((labelWithValue) => labelWithValue.value);

            setOptions(labelsWithValues);
            setIsFetchingLabelValues(false);
        });
    }, [allLabels, getLabelValues]);

    const handleAddGroup = (
        addToForm: (defaultValue: InventoryDistributionGroup) => void,
        fieldName: InventoryDistributionGroupsFieldName
    ) => {
        const firstValidOption = options.find((option) => !valueIdSet.has(option.label.id));

        if (!firstValidOption) {
            return;
        }

        const newGroup: InventoryDistributionGroup =
            fieldName === InventoryDistributionGroupsFieldName.CAP
                ? { cap: "", labelValue: null }
                : { throttle: "", labelValue: null };

        addToForm(newGroup);

        if (onChange) {
            onChange([...values, newGroup]);
        }
    };

    const handleRemoveGroup = (removeFromForm: (key: number) => void, key: number, i) => {
        removeFromForm(key);

        if (onChange) {
            onChange(values.filter((_, idx) => i !== idx));
        }
    };

    const handleUpdateGroupImpressions = (i, nextImpressions, fieldName: InventoryDistributionGroupsFieldName) => {
        if (!onChange) {
            return;
        }
        onChange(
            values.map((group, idx) => {
                if (i !== idx) {
                    return group;
                }
                return {
                    ...group,
                    ...(fieldName === InventoryDistributionGroupsFieldName.CAP
                        ? { cap: nextImpressions }
                        : { throttle: nextImpressions }),
                };
            })
        );
    };

    const handleUpdateGroupLabel = (i, value) => {
        if (!onChange) {
            return;
        }
        onChange(
            values.map((group, idx) => {
                if (i !== idx) {
                    return group;
                }
                return {
                    ...group,
                    labelValue: value,
                };
            })
        );
    };

    return {
        handleAddGroup,
        handleRemoveGroup,
        handleUpdateGroupImpressions,
        handleUpdateGroupLabel,
        isFetching: isFetchingAllLabels || isFetchingLabelValues,
        options,
        valueIdSet,
    };
};
